/**
 * onCourse REST Api
 * An API for interacting with the backend of the onCourse server. \"Basic\" prefix for token authentication is not required. It uses the HTTP 2.0 protocol, so only those testing tools that support it can be used for testing
 *
 * OpenAPI spec version: 1.0.0
 * Contact: support@ish.com.au
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export const createStringEnum = <T extends string>(o: Array<T>): {[K in T]: K} => {
  return o.reduce((res, key) => {
    res[key] = key;
    return res;
  }, Object.create(null));
};

  /**
 * 
 * @export
 * @interface AbstractInvoiceLine
 */
export interface AbstractInvoiceLine {
    /**
     * 
     * @type {number}
     * @memberof AbstractInvoiceLine
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AbstractInvoiceLine
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof AbstractInvoiceLine
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof AbstractInvoiceLine
     */
    unit?: string;
    /**
     * 
     * @type {number}
     * @memberof AbstractInvoiceLine
     */
    incomeAccountId?: number;
    /**
     * 
     * @type {string}
     * @memberof AbstractInvoiceLine
     */
    incomeAccountName?: string;
    /**
     * 
     * @type {number}
     * @memberof AbstractInvoiceLine
     */
    priceEachExTax?: number;
    /**
     * 
     * @type {number}
     * @memberof AbstractInvoiceLine
     */
    discountEachExTax?: number;
    /**
     * 
     * @type {number}
     * @memberof AbstractInvoiceLine
     */
    discountId?: number;
    /**
     * 
     * @type {string}
     * @memberof AbstractInvoiceLine
     */
    discountName?: string;
    /**
     * 
     * @type {number}
     * @memberof AbstractInvoiceLine
     */
    taxEach?: number;
    /**
     * 
     * @type {number}
     * @memberof AbstractInvoiceLine
     */
    finalPriceToPayIncTax?: number;
    /**
     * 
     * @type {number}
     * @memberof AbstractInvoiceLine
     */
    taxId?: number;
    /**
     * 
     * @type {string}
     * @memberof AbstractInvoiceLine
     */
    taxName?: string;
    /**
     * 
     * @type {string}
     * @memberof AbstractInvoiceLine
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof AbstractInvoiceLine
     */
    courseClassId?: number;
    /**
     * 
     * @type {string}
     * @memberof AbstractInvoiceLine
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof AbstractInvoiceLine
     */
    courseCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AbstractInvoiceLine
     */
    classCode?: string;
    /**
     * 
     * @type {number}
     * @memberof AbstractInvoiceLine
     */
    enrolmentId?: number;
    /**
     * 
     * @type {string}
     * @memberof AbstractInvoiceLine
     */
    enrolledStudent?: string;
    /**
     * 
     * @type {number}
     * @memberof AbstractInvoiceLine
     */
    courseId?: number;
    /**
     * 
     * @type {CheckoutEnrolment}
     * @memberof AbstractInvoiceLine
     */
    enrolment?: CheckoutEnrolment;
    /**
     * 
     * @type {CheckoutVoucher}
     * @memberof AbstractInvoiceLine
     */
    voucher?: CheckoutVoucher;
    /**
     * 
     * @type {CheckoutArticle}
     * @memberof AbstractInvoiceLine
     */
    article?: CheckoutArticle;
    /**
     * 
     * @type {CheckoutMembership}
     * @memberof AbstractInvoiceLine
     */
    membership?: CheckoutMembership;
    /**
     * 
     * @type {number}
     * @memberof AbstractInvoiceLine
     */
    contactId?: number;
}

      /**
    * List of access statuses
    */


export const AccessStatus = createStringEnum([
  'Hide',
  'View',
  'Print',
  'Edit',
  'Create',
  'Delete',
]);

export type AccessStatus = keyof typeof AccessStatus;

  /**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * Unique account identifier
     * @type {number}
     * @memberof Account
     */
    id?: number;
    /**
     * Unique code of account
     * @type {string}
     * @memberof Account
     */
    accountCode?: string;
    /**
     * Description of account
     * @type {string}
     * @memberof Account
     */
    description?: string;
    /**
     * Is account enabled or disabled
     * @type {boolean}
     * @memberof Account
     */
    isEnabled?: boolean;
    /**
     * 
     * @type {AccountType}
     * @memberof Account
     */
    type?: AccountType;
    /**
     * 
     * @type {Tax}
     * @memberof Account
     */
    tax?: Tax;
    /**
     * Flag which means that this account is default. If true, we can't disable this account (set isEnabled to false)
     * @type {boolean}
     * @memberof Account
     */
    isDefaultAccount?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    modifiedOn?: string;
}

      /**
    * List of account types
    */


export const AccountType = createStringEnum([
  'asset',
  'liability',
  'equity',
  'income',
  'COS',
  'expense',
]);

export type AccountType = keyof typeof AccountType;

  /**
 * 
 * @export
 * @interface ApiToken
 */
export interface ApiToken {
    /**
     * Unique token identifier
     * @type {number}
     * @memberof ApiToken
     */
    id?: number;
    /**
     * Token name on UI
     * @type {string}
     * @memberof ApiToken
     */
    name?: string;
    /**
     * Token secret. Exposed on UI only when created
     * @type {string}
     * @memberof ApiToken
     */
    secret?: string;
    /**
     * Date and time when token was used last
     * @type {string}
     * @memberof ApiToken
     */
    lastAccess?: string;
    /**
     * Unique user identifier associated token
     * @type {number}
     * @memberof ApiToken
     */
    userId?: number;
}

  /**
 * 
 * @export
 * @interface Application
 */
export interface Application {
    /**
     * 
     * @type {number}
     * @memberof Application
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Application
     */
    contactId?: number;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    studentName?: string;
    /**
     * 
     * @type {number}
     * @memberof Application
     */
    courseId?: number;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    applicationDate?: string;
    /**
     * 
     * @type {ApplicationStatus}
     * @memberof Application
     */
    status?: ApplicationStatus;
    /**
     * 
     * @type {Source}
     * @memberof Application
     */
    source?: Source;
    /**
     * 
     * @type {number}
     * @memberof Application
     */
    feeOverride?: number;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    enrolBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    reason?: string;
    /**
     * 
     * @type {Array<Document>}
     * @memberof Application
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Application
     */
    tags?: Array<number>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Application
     */
    customFields?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    modifiedOn?: string;
}

      /**
    * List of account types
    */


export const ApplicationStatus = createStringEnum([
  'New',
  'Offered',
  'Accepted',
  'Rejected',
  'Withdrawn',
  'In progress',
]);

export type ApplicationStatus = keyof typeof ApplicationStatus;

  /**
 * 
 * @export
 * @interface ArticleProduct
 */
export interface ArticleProduct {
    /**
     * 
     * @type {number}
     * @memberof ArticleProduct
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticleProduct
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticleProduct
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof ArticleProduct
     */
    dataCollectionRuleId?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticleProduct
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof ArticleProduct
     */
    feeExTax?: number;
    /**
     * 
     * @type {number}
     * @memberof ArticleProduct
     */
    totalFee?: number;
    /**
     * 
     * @type {number}
     * @memberof ArticleProduct
     */
    taxId?: number;
    /**
     * 
     * @type {number}
     * @memberof ArticleProduct
     */
    incomeAccountId?: number;
    /**
     * 
     * @type {ProductStatus}
     * @memberof ArticleProduct
     */
    status?: ProductStatus;
    /**
     * 
     * @type {Array<ArticleProductCorporatePass>}
     * @memberof ArticleProduct
     */
    corporatePasses?: Array<ArticleProductCorporatePass>;
    /**
     * 
     * @type {Array<Sale>}
     * @memberof ArticleProduct
     */
    relatedSellables?: Array<Sale>;
    /**
     * 
     * @type {string}
     * @memberof ArticleProduct
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticleProduct
     */
    modifiedOn?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ArticleProduct
     */
    tags?: Array<number>;
    /**
     * 
     * @type {Array<Document>}
     * @memberof ArticleProduct
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ArticleProduct
     */
    customFields?: { [key: string]: string; };
}

  /**
 * 
 * @export
 * @interface ArticleProductCorporatePass
 */
export interface ArticleProductCorporatePass {
    /**
     * 
     * @type {number}
     * @memberof ArticleProductCorporatePass
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticleProductCorporatePass
     */
    contactFullName?: string;
}

  /**
 * 
 * @export
 * @interface Assessment
 */
export interface Assessment {
    /**
     * 
     * @type {number}
     * @memberof Assessment
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Assessment
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Assessment
     */
    modifiedOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Assessment
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof Assessment
     */
    name?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof Assessment
     */
    tags?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof Assessment
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Assessment
     */
    description?: string;
    /**
     * 
     * @type {Array<Document>}
     * @memberof Assessment
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {number}
     * @memberof Assessment
     */
    gradingTypeId?: number;
}

  /**
 * 
 * @export
 * @interface AssessmentClass
 */
export interface AssessmentClass {
    /**
     * 
     * @type {number}
     * @memberof AssessmentClass
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentClass
     */
    assessmentId?: number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentClass
     */
    courseClassId?: number;
    /**
     * 
     * @type {string}
     * @memberof AssessmentClass
     */
    assessmentCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentClass
     */
    assessmentName?: string;
    /**
     * 
     * @type {number}
     * @memberof AssessmentClass
     */
    gradingTypeId?: number;
    /**
     * Course class tutor's ids
     * @type {Array<number>}
     * @memberof AssessmentClass
     */
    contactIds?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AssessmentClass
     */
    moduleIds?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof AssessmentClass
     */
    releaseDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentClass
     */
    dueDate?: string;
    /**
     * 
     * @type {Array<AssessmentSubmission>}
     * @memberof AssessmentClass
     */
    submissions?: Array<AssessmentSubmission>;
}

  /**
 * 
 * @export
 * @interface AssessmentSubmission
 */
export interface AssessmentSubmission {
    /**
     * 
     * @type {number}
     * @memberof AssessmentSubmission
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AssessmentSubmission
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentSubmission
     */
    modifiedOn?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentSubmission
     */
    submittedOn?: string;
    /**
     * 
     * @type {number}
     * @memberof AssessmentSubmission
     */
    markedById?: number;
    /**
     * 
     * @type {string}
     * @memberof AssessmentSubmission
     */
    tutorName?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentSubmission
     */
    markedOn?: string;
    /**
     * 
     * @type {number}
     * @memberof AssessmentSubmission
     */
    enrolmentId?: number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentSubmission
     */
    studentId?: number;
    /**
     * 
     * @type {string}
     * @memberof AssessmentSubmission
     */
    studentName?: string;
    /**
     * 
     * @type {number}
     * @memberof AssessmentSubmission
     */
    classId?: number;
    /**
     * 
     * @type {string}
     * @memberof AssessmentSubmission
     */
    courseClassName?: string;
    /**
     * 
     * @type {number}
     * @memberof AssessmentSubmission
     */
    assessmentId?: number;
    /**
     * 
     * @type {string}
     * @memberof AssessmentSubmission
     */
    assessment?: string;
    /**
     * 
     * @type {number}
     * @memberof AssessmentSubmission
     */
    grade?: number;
    /**
     * 
     * @type {Array<Document>}
     * @memberof AssessmentSubmission
     */
    documents?: Array<Document>;
}

  

export const AttendanceType = createStringEnum([
  'Unmarked',
  'Attended',
  'Absent with reason',
  'Absent without reason',
  'Partial',
]);

export type AttendanceType = keyof typeof AttendanceType;

  /**
 * 
 * @export
 * @interface Audit
 */
export interface Audit {
    /**
     * Unique audit identifier
     * @type {number}
     * @memberof Audit
     */
    id?: number;
    /**
     * Message
     * @type {string}
     * @memberof Audit
     */
    message?: string;
    /**
     * Action
     * @type {string}
     * @memberof Audit
     */
    action?: string;
    /**
     * First name and Last name of system user
     * @type {string}
     * @memberof Audit
     */
    systemUser?: string;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    entityId?: string;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    entityIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof Audit
     */
    created?: string;
}

  /**
 * 
 * @export
 * @interface AutomationConfigs
 */
export interface AutomationConfigs {
    /**
     * Configs, formattted like into yaml file
     * @type {string}
     * @memberof AutomationConfigs
     */
    config?: string;
}

  

export const AutomationStatus = createStringEnum([
  'Not Installed',
  'Installed but Disabled',
  'Enabled',
]);

export type AutomationStatus = keyof typeof AutomationStatus;

  

export const AvetmissExportFee = createStringEnum([
  'Fee for service VET (non-funded)',
  'Queensland',
  'New South Wales',
  'Victoria',
  'Tasmania',
  'Australian Capital Territory',
  'Western Australia',
  'South Australia',
  'Northern Territory',
  'No Australian state defined',
  'Non VET',
]);

export type AvetmissExportFee = keyof typeof AvetmissExportFee;

  

export const AvetmissExportFlavour = createStringEnum([
  'NCVER (Standard AVETMISS)',
  'CSO (Community Colleges)',
  'STSOnline (NSW)',
  'DETConnect (Queensland)',
  'STELA (South Australia)',
  'Skills Tasmania',
  'Skills Victoria',
  'STARS (WA)',
  'WA RAPT',
  'Northern Territories VET Provider Portal',
  'AVETARS (ACT)',
]);

export type AvetmissExportFlavour = keyof typeof AvetmissExportFlavour;

  /**
 * 
 * @export
 * @interface AvetmissExportOutcome
 */
export interface AvetmissExportOutcome {
    /**
     * 
     * @type {Array<number>}
     * @memberof AvetmissExportOutcome
     */
    ids?: Array<number>;
    /**
     * 
     * @type {AvetmissExportType}
     * @memberof AvetmissExportOutcome
     */
    type?: AvetmissExportType;
    /**
     * 
     * @type {AvetmissExportOutcomeStatus}
     * @memberof AvetmissExportOutcome
     */
    status?: AvetmissExportOutcomeStatus;
    /**
     * 
     * @type {AvetmissExportOutcomeCategory}
     * @memberof AvetmissExportOutcome
     */
    category?: AvetmissExportOutcomeCategory;
}

  

export const AvetmissExportOutcomeCategory = createStringEnum([
  'Not yet started',
  'Started (not assessed)',
  'Commenced',
  'Delivered',
  'Final Status',
  'All outcomes final',
  'Issued',
  'Counting',
]);

export type AvetmissExportOutcomeCategory = keyof typeof AvetmissExportOutcomeCategory;

  

export const AvetmissExportOutcomeStatus = createStringEnum([
  'start in future',
  'commenced but absent',
  'in progress',
  'no final status',
  'USI verified',
  'USI not verified',
  'non-VET',
  'certificate/SOA issued',
  'Competency achieved/pass (20)',
  'Competency not achieved/fail (30)',
  'credit transfer (60)',
  'RPL granted (51)',
  'withdrawn (40)',
  'RPL not granted (52)',
  'RCC (53)',
  'RCC not granted (54)',
  'Incomplete due to RTO closure (41)',
  'Superseded Qualification (QLD:65)',
  'Did not start (NSW:66)',
  'Continuing enrolment (70)',
  'Satisfactorily completed (81)',
  'Withdrawn or not satisfactorily completed (82)',
  'Result not available (90)',
  'Participating but not finished with evidence(WA:5)',
  'Participating but not finished with no evidence(WA:55)',
  'RCC granted (WA:15)',
  'RCC not granted (WA:16)',
  'Provisionally competent off the job (WA:8)',
  'Discontinued - no formal withdrawal (WA:11)',
  'Not yet started (WA:105)',
  'Superseded subject (61)',
]);

export type AvetmissExportOutcomeStatus = keyof typeof AvetmissExportOutcomeStatus;

  /**
 * 
 * @export
 * @interface AvetmissExportRequest
 */
export interface AvetmissExportRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof AvetmissExportRequest
     */
    ids?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof AvetmissExportRequest
     */
    defaultStatus?: boolean;
    /**
     * 
     * @type {AvetmissExportSettings}
     * @memberof AvetmissExportRequest
     */
    settings?: AvetmissExportSettings;
}

  /**
 * 
 * @export
 * @interface AvetmissExportSettings
 */
export interface AvetmissExportSettings {
    /**
     * 
     * @type {AvetmissExportFlavour}
     * @memberof AvetmissExportSettings
     */
    flavour?: AvetmissExportFlavour;
    /**
     * 
     * @type {Array<AvetmissExportFee>}
     * @memberof AvetmissExportSettings
     */
    fee?: Array<AvetmissExportFee>;
    /**
     * 
     * @type {string}
     * @memberof AvetmissExportSettings
     */
    outcomesStart?: string;
    /**
     * 
     * @type {string}
     * @memberof AvetmissExportSettings
     */
    outcomesEnd?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AvetmissExportSettings
     */
    includeLinkedOutcomes?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof AvetmissExportSettings
     */
    fundingContracts?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AvetmissExportSettings
     */
    classIds?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AvetmissExportSettings
     */
    enrolmentIds?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof AvetmissExportSettings
     */
    noAssessment?: boolean;
}

      /**
    * List of outcomes types
    */


export const AvetmissExportType = createStringEnum([
  'outcome',
  'enrolment',
]);

export type AvetmissExportType = keyof typeof AvetmissExportType;

      /**
    * List of disabilities.
    */


export const AvetmissStudentDisabilityType = createStringEnum([
  'Not stated',
  'None',
  'Hearing/Deaf',
  'Physical',
  'Intellectual',
  'Learning',
  'Mental illness',
  'Acquired brain impairment',
  'Vision',
  'Medical condition',
  'Other',
]);

export type AvetmissStudentDisabilityType = keyof typeof AvetmissStudentDisabilityType;

      /**
    * A set of values for AVETMISS reporting describing how well student speaks English
    */


export const AvetmissStudentEnglishProficiency = createStringEnum([
  'Not stated',
  'Very Well',
  'Well',
  'Not Well',
  'Not at all',
]);

export type AvetmissStudentEnglishProficiency = keyof typeof AvetmissStudentEnglishProficiency;

      /**
    * List of student's indigenous statuses.
    */


export const AvetmissStudentIndigenousStatus = createStringEnum([
  'Not stated',
  'Aboriginal',
  'Torres Strait Islander',
  'Aboriginal and Torres Strait Islander',
  'Neither',
]);

export type AvetmissStudentIndigenousStatus = keyof typeof AvetmissStudentIndigenousStatus;

  

export const AvetmissStudentLabourStatus = createStringEnum([
  'Not stated',
  'Full time',
  'Part time',
  'Self employed',
  'Employer',
  'Unpaid family worker',
  'Unemployed seeking full time',
  'Unemployed seeking part time',
  'Unemployed non seeking',
]);

export type AvetmissStudentLabourStatus = keyof typeof AvetmissStudentLabourStatus;

      /**
    * List of student's education levels.
    */


export const AvetmissStudentPriorEducation = createStringEnum([
  'Not stated',
  'Bachelor degree or higher degree level',
  'Advanced diploma or associate degree level',
  'Diploma level',
  'Certificate IV',
  'Certificate III',
  'Certificate II',
  'Certificate I',
  'Miscellaneous education',
  'None',
]);

export type AvetmissStudentPriorEducation = keyof typeof AvetmissStudentPriorEducation;

      /**
    * A set of values for describing student's school level.
    */


export const AvetmissStudentSchoolLevel = createStringEnum([
  'Not stated',
  'Did not go to school',
  'Year 8 or below',
  'Year 9',
  'Year 10',
  'Year 11',
  'Year 12',
]);

export type AvetmissStudentSchoolLevel = keyof typeof AvetmissStudentSchoolLevel;

  /**
 * 
 * @export
 * @interface Banking
 */
export interface Banking {
    /**
     * 
     * @type {number}
     * @memberof Banking
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Banking
     */
    settlementDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Banking
     */
    adminSite?: string;
    /**
     * 
     * @type {string}
     * @memberof Banking
     */
    createdBy?: string;
    /**
     * 
     * @type {ReconciledStatus}
     * @memberof Banking
     */
    reconciledStatus?: ReconciledStatus;
    /**
     * 
     * @type {Array<Payment>}
     * @memberof Banking
     */
    payments?: Array<Payment>;
    /**
     * 
     * @type {number}
     * @memberof Banking
     */
    total?: number;
    /**
     * 
     * @type {string}
     * @memberof Banking
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Banking
     */
    modifiedOn?: string;
    /**
     * 
     * @type {number}
     * @memberof Banking
     */
    administrationCenterId?: number;
}

  /**
 * 
 * @export
 * @interface BankingParam
 */
export interface BankingParam {
    /**
     * 
     * @type {string}
     * @memberof BankingParam
     */
    dateBanked?: string;
    /**
     * 
     * @type {number}
     * @memberof BankingParam
     */
    administrationCenterId?: number;
}

  /**
 * 
 * @export
 * @interface Binding
 */
export interface Binding {
    /**
     * variable name in script/export body
     * @type {string}
     * @memberof Binding
     */
    name?: string;
    /**
     * Display label on user dialog on share menu
     * @type {string}
     * @memberof Binding
     */
    label?: string;
    /**
     * data type for variable
     * @type {DataType}
     * @memberof Binding
     */
    type?: DataType;
    /**
     * value for binding, used on setting up for Options only
     * @type {string}
     * @memberof Binding
     */
    value?: string;
    /**
     * internal automation bindings
     * @type {boolean}
     * @memberof Binding
     */
    system?: boolean;
    /**
     * defaul value for system binding
     * @type {string}
     * @memberof Binding
     */
    valueDefault?: string;
}

  /**
 * 
 * @export
 * @interface CancelCourseClass
 */
export interface CancelCourseClass {
    /**
     * 
     * @type {Array<number>}
     * @memberof CancelCourseClass
     */
    classIds?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof CancelCourseClass
     */
    refundManualInvoices?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CancelCourseClass
     */
    sendEmail?: boolean;
}

  /**
 * 
 * @export
 * @interface CancelEnrolment
 */
export interface CancelEnrolment {
    /**
     * 
     * @type {number}
     * @memberof CancelEnrolment
     */
    enrolmentIds?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CancelEnrolment
     */
    deleteNotSetOutcomes?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CancelEnrolment
     */
    transfer?: boolean;
    /**
     * 
     * @type {Array<RefundInvoice>}
     * @memberof CancelEnrolment
     */
    invoiceLineParam?: Array<RefundInvoice>;
}

  /**
 * 
 * @export
 * @interface Cart
 */
export interface Cart {
    /**
     * Unique contact identifier in onCourse
     * @type {number}
     * @memberof Cart
     */
    id?: number;
    /**
     * Total amount of cart
     * @type {number}
     * @memberof Cart
     */
    totalValue?: number;
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    createdOn?: string;
}

  /**
 * 
 * @export
 * @interface CartContactIds
 */
export interface CartContactIds {
    /**
     * 
     * @type {number}
     * @memberof CartContactIds
     */
    contactId?: number;
    /**
     * array of classes ids related to this cart
     * @type {Array<CartObjectData>}
     * @memberof CartContactIds
     */
    classIds?: Array<CartObjectData>;
    /**
     * array of waiting course ids related to this cart
     * @type {Array<CartObjectData>}
     * @memberof CartContactIds
     */
    waitingCoursesIds?: Array<CartObjectData>;
    /**
     * array of product ids related to this cart
     * @type {Array<CartObjectData>}
     * @memberof CartContactIds
     */
    productIds?: Array<CartObjectData>;
}

  /**
 * 
 * @export
 * @interface CartIds
 */
export interface CartIds {
    /**
     * 
     * @type {Array<CartContactIds>}
     * @memberof CartIds
     */
    contacts?: Array<CartContactIds>;
    /**
     * Id of payer for this cart
     * @type {number}
     * @memberof CartIds
     */
    payerId?: number;
}

  /**
 * 
 * @export
 * @interface CartObjectData
 */
export interface CartObjectData {
    /**
     * Id of record
     * @type {number}
     * @memberof CartObjectData
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CartObjectData
     */
    selected?: boolean;
    /**
     * 
     * @type {ProductType}
     * @memberof CartObjectData
     */
    productType?: ProductType;
    /**
     * 
     * @type {number}
     * @memberof CartObjectData
     */
    quantity?: number;
}

  /**
 * 
 * @export
 * @interface Certificate
 */
export interface Certificate {
    /**
     * 
     * @type {number}
     * @memberof Certificate
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Certificate
     */
    studentContactId?: number;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    studentName?: string;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    studentSuburb?: string;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    studentDateOfBirth?: string;
    /**
     * 
     * @type {number}
     * @memberof Certificate
     */
    qualificationId?: number;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    nationalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    level?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Certificate
     */
    isQualification?: boolean;
    /**
     * 
     * @type {Array<CertificateOutcome>}
     * @memberof Certificate
     */
    outcomes?: Array<CertificateOutcome>;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    privateNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    publicNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    awardedOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    expiryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    issuedOn?: string;
    /**
     * 
     * @type {number}
     * @memberof Certificate
     */
    number?: number;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    printedOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    revokedOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    modifiedOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    portalLink?: string;
}

  /**
 * 
 * @export
 * @interface CertificateCreateForEnrolmentsRequest
 */
export interface CertificateCreateForEnrolmentsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof CertificateCreateForEnrolmentsRequest
     */
    enrolmentIds?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof CertificateCreateForEnrolmentsRequest
     */
    createStatementOfAtteiment?: boolean;
}

  /**
 * 
 * @export
 * @interface CertificateOutcome
 */
export interface CertificateOutcome {
    /**
     * 
     * @type {number}
     * @memberof CertificateOutcome
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CertificateOutcome
     */
    issueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateOutcome
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateOutcome
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateOutcome
     */
    status?: string;
}

  /**
 * 
 * @export
 * @interface CertificateRevokeRequest
 */
export interface CertificateRevokeRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof CertificateRevokeRequest
     */
    ids?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CertificateRevokeRequest
     */
    revokeReason?: string;
}

  /**
 * 
 * @export
 * @interface CertificateValidationRequest
 */
export interface CertificateValidationRequest {
    /**
     * 
     * @type {string}
     * @memberof CertificateValidationRequest
     */
    search?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateValidationRequest
     */
    filter?: string;
    /**
     * 
     * @type {Array<TagGroup>}
     * @memberof CertificateValidationRequest
     */
    tagGroups?: Array<TagGroup>;
    /**
     * 
     * @type {Array<Sorting>}
     * @memberof CertificateValidationRequest
     */
    sorting?: Array<Sorting>;
}

  /**
 * 
 * @export
 * @interface CheckoutArticle
 */
export interface CheckoutArticle {
    /**
     * empty array for not persisted record. Number of articles creates if quantity > 1
     * @type {Array<number>}
     * @memberof CheckoutArticle
     */
    ids?: Array<number>;
    /**
     * Unique articke product identifier in onCourse
     * @type {number}
     * @memberof CheckoutArticle
     */
    productId?: number;
    /**
     * product purchase quantity
     * @type {number}
     * @memberof CheckoutArticle
     */
    quantity?: number;
}

  /**
 * 
 * @export
 * @interface CheckoutEnrolment
 */
export interface CheckoutEnrolment {
    /**
     * null for not persisded records
     * @type {number}
     * @memberof CheckoutEnrolment
     */
    id?: number;
    /**
     * Unique class identifier in onCourse
     * @type {number}
     * @memberof CheckoutEnrolment
     */
    classId?: number;
    /**
     * Unique discount identifier in onCourse
     * @type {number}
     * @memberof CheckoutEnrolment
     */
    appliedDiscountId?: number;
    /**
     * custom enrolment price
     * @type {number}
     * @memberof CheckoutEnrolment
     */
    totalOverride?: number;
    /**
     * Unique voucher identifier in onCourse
     * @type {number}
     * @memberof CheckoutEnrolment
     */
    appliedVoucherId?: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutEnrolment
     */
    relatedFundingSourceId?: number;
    /**
     * 
     * @type {EnrolmentStudyReason}
     * @memberof CheckoutEnrolment
     */
    studyReason?: EnrolmentStudyReason;
}

  /**
 * 
 * @export
 * @interface CheckoutMembership
 */
export interface CheckoutMembership {
    /**
     * null for not persisded records
     * @type {number}
     * @memberof CheckoutMembership
     */
    id?: number;
    /**
     * Unique membership product identifier in onCourse
     * @type {number}
     * @memberof CheckoutMembership
     */
    productId?: number;
    /**
     * optional for not forever memberships
     * @type {string}
     * @memberof CheckoutMembership
     */
    validTo?: string;
}

  /**
 * 
 * @export
 * @interface CheckoutModel
 */
export interface CheckoutModel {
    /**
     * 
     * @type {number}
     * @memberof CheckoutModel
     */
    payerId?: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutModel
     */
    paymentMethodId?: number;
    /**
     * payment amount
     * @type {number}
     * @memberof CheckoutModel
     */
    payNow?: number;
    /**
     * custom payment date
     * @type {string}
     * @memberof CheckoutModel
     */
    paymentDate?: string;
    /**
     * server side generated reference on x-validate step. Required for complte request.
     * @type {string}
     * @memberof CheckoutModel
     */
    merchantReference?: string;
    /**
     * 
     * @type {Array<ContactNode>}
     * @memberof CheckoutModel
     */
    contactNodes?: Array<ContactNode>;
    /**
     * send invoice receipt
     * @type {boolean}
     * @memberof CheckoutModel
     */
    sendInvoice?: boolean;
    /**
     * payment amount
     * @type {{ [key: string]: number; }}
     * @memberof CheckoutModel
     */
    previousInvoices?: { [key: string]: number; };
    /**
     * 
     * @type {Array<CheckoutPaymentPlan>}
     * @memberof CheckoutModel
     */
    paymentPlans?: Array<CheckoutPaymentPlan>;
    /**
     * redeemed vouchers ids and applied amount
     * @type {{ [key: string]: number; }}
     * @memberof CheckoutModel
     */
    redeemedVouchers?: { [key: string]: number; };
    /**
     * allow to use the card in next payments automatically
     * @type {boolean}
     * @memberof CheckoutModel
     */
    allowAutoPay?: boolean;
    /**
     * pay with saved credit card
     * @type {boolean}
     * @memberof CheckoutModel
     */
    payWithSavedCard?: boolean;
    /**
     * amount allocated to current invoice
     * @type {number}
     * @memberof CheckoutModel
     */
    payForThisInvoice?: number;
    /**
     * should be set for not payment plan invoices
     * @type {string}
     * @memberof CheckoutModel
     */
    invoiceDueDate?: string;
    /**
     * invoice public notes
     * @type {string}
     * @memberof CheckoutModel
     */
    invoicePublicNotes?: string;
    /**
     * invoice customer reference
     * @type {string}
     * @memberof CheckoutModel
     */
    invoiceCustomerReference?: string;
}

  /**
 * 
 * @export
 * @interface CheckoutPaymentPlan
 */
export interface CheckoutPaymentPlan {
    /**
     * 
     * @type {string}
     * @memberof CheckoutPaymentPlan
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof CheckoutPaymentPlan
     */
    amount?: number;
}

  /**
 * 
 * @export
 * @interface CheckoutResponse
 */
export interface CheckoutResponse {
    /**
     * Windcave session id
     * @type {string}
     * @memberof CheckoutResponse
     */
    sessionId?: string;
    /**
     * Windcave iframe url
     * @type {string}
     * @memberof CheckoutResponse
     */
    ccFormUrl?: string;
    /**
     * server side generated UUID
     * @type {string}
     * @memberof CheckoutResponse
     */
    merchantReference?: string;
    /**
     * 
     * @type {number}
     * @memberof CheckoutResponse
     */
    paymentId?: number;
    /**
     * 
     * @type {Invoice}
     * @memberof CheckoutResponse
     */
    invoice?: Invoice;
}

  /**
 * 
 * @export
 * @interface CheckoutSaleRelation
 */
export interface CheckoutSaleRelation {
    /**
     * 
     * @type {Sale}
     * @memberof CheckoutSaleRelation
     */
    fromItem?: Sale;
    /**
     * 
     * @type {Sale}
     * @memberof CheckoutSaleRelation
     */
    toItem?: Sale;
    /**
     * 
     * @type {EntityRelationCartAction}
     * @memberof CheckoutSaleRelation
     */
    cartAction?: EntityRelationCartAction;
    /**
     * 
     * @type {Discount}
     * @memberof CheckoutSaleRelation
     */
    discount?: Discount;
}

  /**
 * 
 * @export
 * @interface CheckoutValidationError
 */
export interface CheckoutValidationError {
    /**
     * Unique contact id
     * @type {number}
     * @memberof CheckoutValidationError
     */
    nodeId?: number;
    /**
     * Unique product id
     * @type {number}
     * @memberof CheckoutValidationError
     */
    itemId?: number;
    /**
     * 
     * @type {SaleType}
     * @memberof CheckoutValidationError
     */
    itemType?: SaleType;
    /**
     * 
     * @type {string}
     * @memberof CheckoutValidationError
     */
    propertyName?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutValidationError
     */
    error?: string;
}

  /**
 * 
 * @export
 * @interface CheckoutVoucher
 */
export interface CheckoutVoucher {
    /**
     * null for not persisded records
     * @type {number}
     * @memberof CheckoutVoucher
     */
    id?: number;
    /**
     * null for not persisded records
     * @type {string}
     * @memberof CheckoutVoucher
     */
    code?: string;
    /**
     * Unique voucher product identifier in onCourse
     * @type {number}
     * @memberof CheckoutVoucher
     */
    productId?: number;
    /**
     * valid to date
     * @type {string}
     * @memberof CheckoutVoucher
     */
    validTo?: string;
    /**
     * applicable for wouchers without defined price
     * @type {number}
     * @memberof CheckoutVoucher
     */
    value?: number;
    /**
     * resrtict using the voucher to payer only
     * @type {boolean}
     * @memberof CheckoutVoucher
     */
    restrictToPayer?: boolean;
}

      /**
    * List of access statuses
    */


export const ClashType = createStringEnum([
  'Tutor',
  'Room',
  'Site',
  'Session',
  'UnavailableRule',
]);

export type ClashType = keyof typeof ClashType;

  /**
 * 
 * @export
 * @interface ClassCost
 */
export interface ClassCost {
    /**
     * 
     * @type {number}
     * @memberof ClassCost
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassCost
     */
    courseClassid?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassCost
     */
    taxId?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassCost
     */
    accountId?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassCost
     */
    invoiceId?: number;
    /**
     * 
     * @type {string}
     * @memberof ClassCost
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClassCost
     */
    invoiceToStudent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClassCost
     */
    payableOnEnrolment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClassCost
     */
    isSunk?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClassCost
     */
    maximumCost?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassCost
     */
    minimumCost?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassCost
     */
    onCostRate?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassCost
     */
    perUnitAmountExTax?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassCost
     */
    perUnitAmountIncTax?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassCost
     */
    actualAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassCost
     */
    actualUsePercent?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassCost
     */
    unitCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassCost
     */
    contactId?: number;
    /**
     * 
     * @type {string}
     * @memberof ClassCost
     */
    contactName?: string;
    /**
     * 
     * @type {ClassCostFlowType}
     * @memberof ClassCost
     */
    flowType?: ClassCostFlowType;
    /**
     * 
     * @type {ClassCostRepetitionType}
     * @memberof ClassCost
     */
    repetitionType?: ClassCostRepetitionType;
    /**
     * 
     * @type {boolean}
     * @memberof ClassCost
     */
    isOverriden?: boolean;
    /**
     * 
     * @type {CourseClassDiscount}
     * @memberof ClassCost
     */
    courseClassDiscount?: CourseClassDiscount;
    /**
     * 
     * @type {Array<CourseClassPaymentPlan>}
     * @memberof ClassCost
     */
    paymentPlan?: Array<CourseClassPaymentPlan>;
    /**
     * 
     * @type {number}
     * @memberof ClassCost
     */
    courseClassTutorId?: number;
    /**
     * 
     * @type {string}
     * @memberof ClassCost
     */
    tutorRole?: string;
}

  

export const ClassCostFlowType = createStringEnum([
  'Expense',
  'Income',
  'Wages',
  'Discount',
  'Custom invoice',
]);

export type ClassCostFlowType = keyof typeof ClassCostFlowType;

  

export const ClassCostRepetitionType = createStringEnum([
  'Fixed',
  'Per session',
  'Per enrolment',
  'Per unit',
  'Discount',
  'Per timetabled hour',
  'Per student contact hour',
]);

export type ClassCostRepetitionType = keyof typeof ClassCostRepetitionType;

      /**
    * Types of course class funding sources
    */


export const ClassFundingSource = createStringEnum([
  'Commonwealth and state - general recurrent',
  'Commonwealth - specific',
  'State - specific',
  'Domestic full fee paying student',
  'International full fee paying student',
  'International onshore client',
  'International offshore client',
  'Revenue from other RTO',
]);

export type ClassFundingSource = keyof typeof ClassFundingSource;

      /**
    * A set of values for describing client employment type.
    */


export const ClientIndustryEmploymentType = createStringEnum([
  'Not Stated',
  'Agriculture, Forestry and Fishing (A)',
  'Mining (B)',
  'Manufacturing (C)',
  'Electricity, Gas, Water and Waste Services (D)',
  'Construction (E)',
  'Wholesale Trade (F)',
  'Retail Trade (G)',
  'Accommodation and Food Services (H)',
  'Transport, Postal and Warehousing (I)',
  'Information Media and Telecommunications (J)',
  'Financial and Insurance Services (K)',
  'Rental, Hiring and Real Estate Services (L)',
  'Professional, Scientific and Technical Services - Administrative and Support Services (M)',
  'Public Administration and Safety (N)',
  'Education and Training (O)',
  'Health Care and Social Assistance (P)',
  'Arts and Recreation Services (Q)',
  'Other Services (R)',
]);

export type ClientIndustryEmploymentType = keyof typeof ClientIndustryEmploymentType;

      /**
    * A set of values for describing client's occupation.
    */


export const ClientOccupationIdentifierType = createStringEnum([
  'Not Stated',
  'Manager (1)',
  'Professionals (2)',
  'Technicians and Trades Workers (3)',
  'Community and personal Service Workers (4)',
  'Clerical and Administrative Workers (5)',
  'Sales Workers (6)',
  'Machinery Operators and Drivers (7)',
  'Labourers (8)',
]);

export type ClientOccupationIdentifierType = keyof typeof ClientOccupationIdentifierType;

  /**
 * 
 * @export
 * @interface Column
 */
export interface Column {
    /**
     * column display name
     * @type {string}
     * @memberof Column
     */
    title?: string;
    /**
     * corresponded entity attribute
     * @type {string}
     * @memberof Column
     */
    attribute?: string;
    /**
     * Type of column records
     * @type {ColumnType}
     * @memberof Column
     */
    type?: ColumnType;
    /**
     * is sorting alloved by this column
     * @type {boolean}
     * @memberof Column
     */
    sortable?: boolean;
    /**
     * is show column on entity table
     * @type {boolean}
     * @memberof Column
     */
    visible?: boolean;
    /**
     * always in data response even if not visible
     * @type {boolean}
     * @memberof Column
     */
    system?: boolean;
    /**
     * width column in table in px
     * @type {number}
     * @memberof Column
     */
    width?: number;
    /**
     * fields which will be used for sorting
     * @type {Array<string>}
     * @memberof Column
     */
    sortFields?: Array<string>;
    /**
     * path to fields which will be prefetched
     * @type {Array<string>}
     * @memberof Column
     */
    prefetches?: Array<string>;
}

      /**
    * Type of column records
    */


export const ColumnType = createStringEnum([
  'Date',
  'Datetime',
  'Boolean',
  'Money',
  'Percent',
  'Tags',
]);

export type ColumnType = keyof typeof ColumnType;

  /**
 * 
 * @export
 * @interface ColumnWidth
 */
export interface ColumnWidth {
    /**
     * pixel width for left column in Preferences list
     * @type {number}
     * @memberof ColumnWidth
     */
    preferenceLeftColumnWidth?: number;
    /**
     * pixel width for left column in Tag list
     * @type {number}
     * @memberof ColumnWidth
     */
    tagLeftColumnWidth?: number;
    /**
     * pixel width for left column in Security list
     * @type {number}
     * @memberof ColumnWidth
     */
    securityLeftColumnWidth?: number;
    /**
     * pixel width for left column in Automation list
     * @type {number}
     * @memberof ColumnWidth
     */
    automationLeftColumnWidth?: number;
}

  /**
 * 
 * @export
 * @interface ConcessionType
 */
export interface ConcessionType {
    /**
     * Unique Concession type identifier
     * @type {number}
     * @memberof ConcessionType
     */
    id?: number;
    /**
     * Concession type name
     * @type {string}
     * @memberof ConcessionType
     */
    name?: string;
    /**
     * Require expary date
     * @type {boolean}
     * @memberof ConcessionType
     */
    requireExpary?: boolean;
    /**
     * Require concession card number
     * @type {boolean}
     * @memberof ConcessionType
     */
    requireNumber?: boolean;
    /**
     * Allow students to self-select on website
     * @type {boolean}
     * @memberof ConcessionType
     */
    allowOnWeb?: boolean;
    /**
     * date and time when record was created
     * @type {string}
     * @memberof ConcessionType
     */
    created?: string;
    /**
     * date and time when record was modified
     * @type {string}
     * @memberof ConcessionType
     */
    modified?: string;
}

      /**
    * List of confirmation statuses.
    */


export const ConfirmationStatus = createStringEnum([
  'Not sent',
  'Sent',
  'Do not send',
]);

export type ConfirmationStatus = keyof typeof ConfirmationStatus;

  /**
 * 
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * Unique contact identifier in onCourse
     * @type {number}
     * @memberof Contact
     */
    id?: number;
    /**
     * Student who owns contact
     * @type {Student}
     * @memberof Contact
     */
    student?: Student;
    /**
     * Tutor who owns contact
     * @type {Tutor}
     * @memberof Contact
     */
    tutor?: Tutor;
    /**
     * Australian business number, for company only
     * @type {string}
     * @memberof Contact
     */
    abn?: string;
    /**
     * Contact birth date
     * @type {string}
     * @memberof Contact
     */
    birthDate?: string;
    /**
     * Contact country in onCourse
     * @type {Country}
     * @memberof Contact
     */
    country?: Country;
    /**
     * Fax number
     * @type {string}
     * @memberof Contact
     */
    fax?: string;
    /**
     * If contact is company
     * @type {boolean}
     * @memberof Contact
     */
    isCompany?: boolean;
    /**
     * contact gender
     * @type {ContactGender}
     * @memberof Contact
     */
    gender?: ContactGender;
    /**
     * Allert for staff
     * @type {string}
     * @memberof Contact
     */
    message?: string;
    /**
     * Home phone number
     * @type {string}
     * @memberof Contact
     */
    homePhone?: string;
    /**
     * Mobile phone number
     * @type {string}
     * @memberof Contact
     */
    mobilePhone?: string;
    /**
     * Work phone number
     * @type {string}
     * @memberof Contact
     */
    workPhone?: string;
    /**
     * Contact postcode
     * @type {string}
     * @memberof Contact
     */
    postcode?: string;
    /**
     * Contact state
     * @type {string}
     * @memberof Contact
     */
    state?: string;
    /**
     * Contact street address
     * @type {string}
     * @memberof Contact
     */
    street?: string;
    /**
     * Contact suburb address
     * @type {string}
     * @memberof Contact
     */
    suburb?: string;
    /**
     * Contact tax file number
     * @type {string}
     * @memberof Contact
     */
    tfn?: string;
    /**
     * number of failed consecutive attempts to deliver email message to this contact, when this value reaches 6 onCourse considers messages to this contact undeliverable
     * @type {number}
     * @memberof Contact
     */
    deliveryStatusEmail?: number;
    /**
     * number of failed consecutive attempts to deliver SMS message to this contact, when this value reaches 6 onCourse considers messages to this contact undeliverable
     * @type {number}
     * @memberof Contact
     */
    deliveryStatusSms?: number;
    /**
     * number of failed consecutive attempts to deliver postal message to this contact, when this value reaches 6 onCourse considers messages to this contact undeliverable
     * @type {number}
     * @memberof Contact
     */
    deliveryStatusPost?: number;
    /**
     * whether the contact opted into postal (mail) marketing
     * @type {boolean}
     * @memberof Contact
     */
    allowPost?: boolean;
    /**
     * whether the contact opted into SMS marketing
     * @type {boolean}
     * @memberof Contact
     */
    allowSms?: boolean;
    /**
     * whether the contact opted into email marketing
     * @type {boolean}
     * @memberof Contact
     */
    allowEmail?: boolean;
    /**
     * alphanumeric 16 character unique code associated with this contact
     * @type {string}
     * @memberof Contact
     */
    uniqueCode?: string;
    /**
     * the honorific is the bit before the name
     * @type {string}
     * @memberof Contact
     */
    honorific?: string;
    /**
     * contact's title
     * @type {string}
     * @memberof Contact
     */
    title?: string;
    /**
     * contact's email
     * @type {string}
     * @memberof Contact
     */
    email?: string;
    /**
     * contact's given name
     * @type {string}
     * @memberof Contact
     */
    firstName?: string;
    /**
     * contact's family name
     * @type {string}
     * @memberof Contact
     */
    lastName?: string;
    /**
     * contact's middle name
     * @type {string}
     * @memberof Contact
     */
    middleName?: string;
    /**
     * Use value to set the due date for contacts invoices
     * @type {number}
     * @memberof Contact
     */
    invoiceTerms?: number;
    /**
     * Unique tax identifier in onCourse
     * @type {number}
     * @memberof Contact
     */
    taxId?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Contact
     */
    customFields?: { [key: string]: string; };
    /**
     * 
     * @type {Array<Document>}
     * @memberof Contact
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Contact
     */
    tags?: Array<number>;
    /**
     * 
     * @type {Array<ProductItem>}
     * @memberof Contact
     */
    memberships?: Array<ProductItem>;
    /**
     * 
     * @type {Document}
     * @memberof Contact
     */
    profilePicture?: Document;
    /**
     * 
     * @type {Array<ContactRelation>}
     * @memberof Contact
     */
    relations?: Array<ContactRelation>;
    /**
     * 
     * @type {Array<FinancialLine>}
     * @memberof Contact
     */
    financialData?: Array<FinancialLine>;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    modifiedOn?: string;
    /**
     * 
     * @type {Array<MessagePerson>}
     * @memberof Contact
     */
    messages?: Array<MessagePerson>;
    /**
     * 
     * @type {Array<Holiday>}
     * @memberof Contact
     */
    rules?: Array<Holiday>;
    /**
     * 
     * @type {Array<Cart>}
     * @memberof Contact
     */
    abandonedCarts?: Array<Cart>;
    /**
     * 
     * @type {boolean}
     * @memberof Contact
     */
    removeCChistory?: boolean;
}

      /**
    * List of contact genders.
    */


export const ContactGender = createStringEnum([
  'Male',
  'Female',
  'Other',
]);

export type ContactGender = keyof typeof ContactGender;

  /**
 * 
 * @export
 * @interface ContactInsight
 */
export interface ContactInsight {
    /**
     * 
     * @type {string}
     * @memberof ContactInsight
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInsight
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInsight
     */
    fax?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInsight
     */
    homePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInsight
     */
    mobilePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInsight
     */
    workPhone?: string;
    /**
     * 
     * @type {Document}
     * @memberof ContactInsight
     */
    profilePicture?: Document;
    /**
     * 
     * @type {ContactOverview}
     * @memberof ContactInsight
     */
    overview?: ContactOverview;
    /**
     * 
     * @type {Array<ContactInteraction>}
     * @memberof ContactInsight
     */
    interactions?: Array<ContactInteraction>;
}

  /**
 * 
 * @export
 * @interface ContactInteraction
 */
export interface ContactInteraction {
    /**
     * 
     * @type {number}
     * @memberof ContactInteraction
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactInteraction
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInteraction
     */
    entity?: string;
    /**
     * Contact interaction date
     * @type {string}
     * @memberof ContactInteraction
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInteraction
     */
    description?: string;
}

  /**
 * 
 * @export
 * @interface ContactNode
 */
export interface ContactNode {
    /**
     * 
     * @type {number}
     * @memberof ContactNode
     */
    contactId?: number;
    /**
     * 
     * @type {Array<CheckoutEnrolment>}
     * @memberof ContactNode
     */
    enrolments?: Array<CheckoutEnrolment>;
    /**
     * 
     * @type {Array<CheckoutMembership>}
     * @memberof ContactNode
     */
    memberships?: Array<CheckoutMembership>;
    /**
     * 
     * @type {Array<CheckoutVoucher>}
     * @memberof ContactNode
     */
    vouchers?: Array<CheckoutVoucher>;
    /**
     * 
     * @type {Array<CheckoutArticle>}
     * @memberof ContactNode
     */
    products?: Array<CheckoutArticle>;
    /**
     * 
     * @type {Array<Invoice>}
     * @memberof ContactNode
     */
    fundingInvoices?: Array<Invoice>;
    /**
     * send confirmation email
     * @type {boolean}
     * @memberof ContactNode
     */
    sendConfirmation?: boolean;
}

  /**
 * 
 * @export
 * @interface ContactOverview
 */
export interface ContactOverview {
    /**
     * 
     * @type {string}
     * @memberof ContactOverview
     */
    firstSeen?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactOverview
     */
    spent?: number;
    /**
     * 
     * @type {number}
     * @memberof ContactOverview
     */
    owing?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ContactOverview
     */
    enrolments?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ContactOverview
     */
    closeApplications?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ContactOverview
     */
    openApplications?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ContactOverview
     */
    openLeads?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ContactOverview
     */
    closeLeads?: Array<number>;
}

  /**
 * 
 * @export
 * @interface ContactRelation
 */
export interface ContactRelation {
    /**
     * 
     * @type {number}
     * @memberof ContactRelation
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ContactRelation
     */
    contactFromId?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactRelation
     */
    contactFromName?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactRelation
     */
    contactToId?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactRelation
     */
    contactToName?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactRelation
     */
    relationId?: number;
}

  /**
 * 
 * @export
 * @interface ContactRelationType
 */
export interface ContactRelationType {
    /**
     * Unique contact Relation type identifier
     * @type {string}
     * @memberof ContactRelationType
     */
    id?: string;
    /**
     * Relationship name
     * @type {string}
     * @memberof ContactRelationType
     */
    relationName?: string;
    /**
     * Revers relationship name
     * @type {string}
     * @memberof ContactRelationType
     */
    reverseRelationName?: string;
    /**
     * Allow to access all information in skillOnCourse portal
     * @type {boolean}
     * @memberof ContactRelationType
     */
    portalAccess?: boolean;
    /**
     * Is internal Contact Relation Type
     * @type {boolean}
     * @memberof ContactRelationType
     */
    systemType?: boolean;
    /**
     * date and time when record was created
     * @type {string}
     * @memberof ContactRelationType
     */
    created?: string;
    /**
     * date and time when record was modified
     * @type {string}
     * @memberof ContactRelationType
     */
    modified?: string;
}

  /**
 * 
 * @export
 * @interface CorporatePass
 */
export interface CorporatePass {
    /**
     * 
     * @type {number}
     * @memberof CorporatePass
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CorporatePass
     */
    contactId?: number;
    /**
     * 
     * @type {string}
     * @memberof CorporatePass
     */
    contactFullName?: string;
    /**
     * 
     * @type {string}
     * @memberof CorporatePass
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof CorporatePass
     */
    expiryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CorporatePass
     */
    invoiceEmail?: string;
    /**
     * 
     * @type {Array<Discount>}
     * @memberof CorporatePass
     */
    linkedDiscounts?: Array<Discount>;
    /**
     * 
     * @type {Array<Sale>}
     * @memberof CorporatePass
     */
    linkedSalables?: Array<Sale>;
    /**
     * 
     * @type {string}
     * @memberof CorporatePass
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof CorporatePass
     */
    modifiedOn?: string;
}

  /**
 * 
 * @export
 * @interface Country
 */
export interface Country {
    /**
     * 
     * @type {number}
     * @memberof Country
     */
    id?: number;
    /**
     * International Organization for Standardization three letter country code
     * @type {string}
     * @memberof Country
     */
    isoCodeAlpha3?: string;
    /**
     * International Organization for Standardization three number country code
     * @type {number}
     * @memberof Country
     */
    isoCodeNumeric?: number;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    name?: string;
    /**
     * Standard Australian Classification of Countries code
     * @type {number}
     * @memberof Country
     */
    saccCode?: number;
}

  /**
 * 
 * @export
 * @interface Course
 */
export interface Course {
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    modifiedOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    code?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof Course
     */
    tags?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    specialTagId?: number;
    /**
     * 
     * @type {CourseEnrolmentType}
     * @memberof Course
     */
    enrolmentType?: CourseEnrolmentType;
    /**
     * 
     * @type {boolean}
     * @memberof Course
     */
    allowWaitingLists?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    dataCollectionRuleId?: number;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    dataCollectionRuleName?: string;
    /**
     * 
     * @type {CourseStatus}
     * @memberof Course
     */
    status?: CourseStatus;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    brochureDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    currentClassesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    futureClasseCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    selfPacedclassesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    unscheduledClasseCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    passedClasseCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    cancelledClassesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    studentWaitingListCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Course
     */
    hasEnrolments?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    webDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    shortWebDescription?: string;
    /**
     * 
     * @type {Array<Document>}
     * @memberof Course
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {Array<Sale>}
     * @memberof Course
     */
    relatedSellables?: Array<Sale>;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    qualificationId?: number;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    qualNationalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    qualTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    qualLevel?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Course
     */
    isSufficientForQualification?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Course
     */
    isVET?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    fieldOfEducation?: string;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    reportableHours?: number;
    /**
     * 
     * @type {Array<Module>}
     * @memberof Course
     */
    modules?: Array<Module>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Course
     */
    customFields?: { [key: string]: string; };
    /**
     * 
     * @type {Array<Holiday>}
     * @memberof Course
     */
    rules?: Array<Holiday>;
    /**
     * 
     * @type {boolean}
     * @memberof Course
     */
    isTraineeship?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Course
     */
    currentlyOffered?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Course
     */
    feeHelpClass?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    fullTimeLoad?: string;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    facultyId?: number;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    attainmentText?: string;
}

  /**
 * 
 * @export
 * @interface CourseClass
 */
export interface CourseClass {
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseClass
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    courseId?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseClass
     */
    courseCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseClass
     */
    courseName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseClass
     */
    endDateTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseClass
     */
    startDateTime?: string;
    /**
     * 
     * @type {CourseClassAttendanceType}
     * @memberof CourseClass
     */
    attendanceType?: CourseClassAttendanceType;
    /**
     * 
     * @type {DeliveryMode}
     * @memberof CourseClass
     */
    deliveryMode?: DeliveryMode;
    /**
     * 
     * @type {ClassFundingSource}
     * @memberof CourseClass
     */
    fundingSource?: ClassFundingSource;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    budgetedPlaces?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseClass
     */
    censusDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseClass
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseClass
     */
    modifiedOn?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    deposit?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseClass
     */
    detBookingId?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    expectedHours?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    feeExcludeGST?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseClass
     */
    finalDetExport?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseClass
     */
    initialDetExport?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseClass
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseClass
     */
    isCancelled?: boolean;
    /**
     * 
     * @type {CourseClassType}
     * @memberof CourseClass
     */
    type?: CourseClassType;
    /**
     * 
     * @type {boolean}
     * @memberof CourseClass
     */
    isShownOnWeb?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    maxStudentAge?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    maximumDays?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    maximumPlaces?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseClass
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseClass
     */
    midwayDetExport?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    minStudentAge?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    minimumPlaces?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CourseClass
     */
    suppressAvetmissExport?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    vetCourseSiteID?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseClass
     */
    vetFundingSourceStateID?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseClass
     */
    vetPurchasingContractID?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseClass
     */
    vetPurchasingContractScheduleID?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseClass
     */
    webDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    relatedFundingSourceId?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    qualificationHours?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    nominalHours?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    classroomHours?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    studentContactHours?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    reportableHours?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    roomId?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    virtualSiteId?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    taxId?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    summaryFee?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    summaryDiscounts?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    enrolmentsToProfitLeftCount?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    allEnrolmentsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    allOutcomesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    inProgressOutcomesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    passOutcomesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    failedOutcomesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    withdrawnOutcomesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    otherOutcomesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    successAndQueuedEnrolmentsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    canceledEnrolmentsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    failedEnrolmentsCount?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CourseClass
     */
    tags?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    specialTagId?: number;
    /**
     * 
     * @type {Array<Document>}
     * @memberof CourseClass
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {boolean}
     * @memberof CourseClass
     */
    isTraineeship?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CourseClass
     */
    customFields?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof CourseClass
     */
    feeHelpClass?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CourseClass
     */
    minimumSessionsToComplete?: number;
}

      /**
    * List of course attendance types
    */


export const CourseClassAttendanceType = createStringEnum([
  'OUA and non-higher degree research student use only',
  'Full-time attendance',
  'Part-time attendance',
  'No information',
]);

export type CourseClassAttendanceType = keyof typeof CourseClassAttendanceType;

  /**
 * 
 * @export
 * @interface CourseClassDiscount
 */
export interface CourseClassDiscount {
    /**
     * 
     * @type {Discount}
     * @memberof CourseClassDiscount
     */
    discount?: Discount;
    /**
     * 
     * @type {number}
     * @memberof CourseClassDiscount
     */
    forecast?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClassDiscount
     */
    discountOverride?: number;
}

  /**
 * 
 * @export
 * @interface CourseClassDuplicate
 */
export interface CourseClassDuplicate {
    /**
     * 
     * @type {Array<number>}
     * @memberof CourseClassDuplicate
     */
    classIds?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof CourseClassDuplicate
     */
    courseId?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClassDuplicate
     */
    daysTo?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CourseClassDuplicate
     */
    copyTutors?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseClassDuplicate
     */
    copyTrainingPlans?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseClassDuplicate
     */
    applyDiscounts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseClassDuplicate
     */
    copyCosts?: boolean;
    /**
     * 
     * @type {ClassCost}
     * @memberof CourseClassDuplicate
     */
    classCost?: ClassCost;
    /**
     * 
     * @type {boolean}
     * @memberof CourseClassDuplicate
     */
    copySitesAndRooms?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseClassDuplicate
     */
    tutorRosterOverrides?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseClassDuplicate
     */
    copyVetData?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseClassDuplicate
     */
    copyNotes?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseClassDuplicate
     */
    copyAssessments?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseClassDuplicate
     */
    copyOnlyMandatoryTags?: boolean;
}

  /**
 * 
 * @export
 * @interface CourseClassPaymentPlan
 */
export interface CourseClassPaymentPlan {
    /**
     * days after class start, null means pay on enrolment
     * @type {number}
     * @memberof CourseClassPaymentPlan
     */
    dayOffset?: number;
    /**
     * payment due amount
     * @type {number}
     * @memberof CourseClassPaymentPlan
     */
    amount?: number;
}

  /**
 * 
 * @export
 * @interface CourseClassTutor
 */
export interface CourseClassTutor {
    /**
     * 
     * @type {number}
     * @memberof CourseClassTutor
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClassTutor
     */
    classId?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClassTutor
     */
    contactId?: number;
    /**
     * 
     * @type {number}
     * @memberof CourseClassTutor
     */
    roleId?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseClassTutor
     */
    tutorName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseClassTutor
     */
    roleName?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseClassTutor
     */
    confirmedOn?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseClassTutor
     */
    isInPublicity?: boolean;
}

      /**
    * CourseClass types.
    */


export const CourseClassType = createStringEnum([
  'With Sessions',
  'Distant Learning',
  'Hybrid',
]);

export type CourseClassType = keyof typeof CourseClassType;

  

export const CourseEnrolmentType = createStringEnum([
  'Open enrolment',
  'Enrolment by application',
]);

export type CourseEnrolmentType = keyof typeof CourseEnrolmentType;

  

export const CourseStatus = createStringEnum([
  'Course disabled',
  'Enabled',
  'Enabled and visible online',
]);

export type CourseStatus = keyof typeof CourseStatus;

  /**
 * 
 * @export
 * @interface Currency
 */
export interface Currency {
    /**
     * Full name of currency
     * @type {string}
     * @memberof Currency
     */
    name?: string;
    /**
     * Short currency symbol representation which will display for user
     * @type {string}
     * @memberof Currency
     */
    shortCurrencySymbol?: string;
    /**
     * Currency represented by chars
     * @type {string}
     * @memberof Currency
     */
    currencySymbol?: string;
}

  /**
 * 
 * @export
 * @interface CustomFieldType
 */
export interface CustomFieldType {
    /**
     * Unique custom field type identifier
     * @type {string}
     * @memberof CustomFieldType
     */
    id?: string;
    /**
     * Custom field type name
     * @type {string}
     * @memberof CustomFieldType
     */
    name?: string;
    /**
     * 
     * @type {DataType}
     * @memberof CustomFieldType
     */
    dataType?: DataType;
    /**
     * Regex pattern that field value should match ( for Pattern text type )
     * @type {string}
     * @memberof CustomFieldType
     */
    pattern?: string;
    /**
     * Default value for custom field type
     * @type {string}
     * @memberof CustomFieldType
     */
    defaultValue?: string;
    /**
     * Default value for custom field type
     * @type {string}
     * @memberof CustomFieldType
     */
    fieldKey?: string;
    /**
     * Is custom field mandatory
     * @type {boolean}
     * @memberof CustomFieldType
     */
    mandatory?: boolean;
    /**
     * sort order in list
     * @type {number}
     * @memberof CustomFieldType
     */
    sortOrder?: number;
    /**
     * 
     * @type {EntityType}
     * @memberof CustomFieldType
     */
    entityType?: EntityType;
    /**
     * date and time when record was created
     * @type {string}
     * @memberof CustomFieldType
     */
    created?: string;
    /**
     * date and time when record was modified
     * @type {string}
     * @memberof CustomFieldType
     */
    modified?: string;
}

  /**
 * 
 * @export
 * @interface DataCollectionForm
 */
export interface DataCollectionForm {
    /**
     * Unique Data Collection Form identifier
     * @type {string}
     * @memberof DataCollectionForm
     */
    id?: string;
    /**
     * Data Collection Form name
     * @type {string}
     * @memberof DataCollectionForm
     */
    name?: string;
    /**
     * Data collection types
     * @type {DataCollectionType}
     * @memberof DataCollectionForm
     */
    type?: DataCollectionType;
    /**
     * 
     * @type {Array<Field>}
     * @memberof DataCollectionForm
     */
    fields?: Array<Field>;
    /**
     * 
     * @type {Array<Heading>}
     * @memberof DataCollectionForm
     */
    headings?: Array<Heading>;
    /**
     * 
     * @type {DeliveryScheduleType}
     * @memberof DataCollectionForm
     */
    deliverySchedule?: DeliveryScheduleType;
    /**
     * date and time when data collection form was created
     * @type {string}
     * @memberof DataCollectionForm
     */
    created?: string;
    /**
     * date and time when data collection form was modified
     * @type {string}
     * @memberof DataCollectionForm
     */
    modified?: string;
}

  /**
 * 
 * @export
 * @interface DataCollectionRule
 */
export interface DataCollectionRule {
    /**
     * Unique Data Collection Rule identifier
     * @type {string}
     * @memberof DataCollectionRule
     */
    id?: string;
    /**
     * Data Collection Rule name
     * @type {string}
     * @memberof DataCollectionRule
     */
    name?: string;
    /**
     * Unique record identifier
     * @type {string}
     * @memberof DataCollectionRule
     */
    enrolmentFormName?: string;
    /**
     * Unique record identifier
     * @type {string}
     * @memberof DataCollectionRule
     */
    applicationFormName?: string;
    /**
     * Unique record identifier
     * @type {string}
     * @memberof DataCollectionRule
     */
    waitingListFormName?: string;
    /**
     * Unique record identifier
     * @type {string}
     * @memberof DataCollectionRule
     */
    payerFormName?: string;
    /**
     * Unique record identifier
     * @type {string}
     * @memberof DataCollectionRule
     */
    parentFormName?: string;
    /**
     * Unique record identifier
     * @type {string}
     * @memberof DataCollectionRule
     */
    productFormName?: string;
    /**
     * Unique record identifier
     * @type {string}
     * @memberof DataCollectionRule
     */
    voucherFormName?: string;
    /**
     * Unique record identifier
     * @type {string}
     * @memberof DataCollectionRule
     */
    membershipFormName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataCollectionRule
     */
    surveyForms?: Array<string>;
    /**
     * date and time when record was created
     * @type {string}
     * @memberof DataCollectionRule
     */
    created?: string;
    /**
     * date and time when record was modified
     * @type {string}
     * @memberof DataCollectionRule
     */
    modified?: string;
}

      /**
    * Avalible data collection types
    */


export const DataCollectionType = createStringEnum([
  'Application',
  'Enrolment',
  'Membership',
  'Parent',
  'Payer',
  'Product',
  'Survey',
  'Voucher',
  'WaitingList',
]);

export type DataCollectionType = keyof typeof DataCollectionType;

  /**
 * 
 * @export
 * @interface DataResponse
 */
export interface DataResponse {
    /**
     * onCourse entity name
     * @type {string}
     * @memberof DataResponse
     */
    entity?: string;
    /**
     * search filter
     * @type {string}
     * @memberof DataResponse
     */
    search?: string;
    /**
     * amount of fetched records
     * @type {number}
     * @memberof DataResponse
     */
    pageSize?: number;
    /**
     * fetch offset
     * @type {number}
     * @memberof DataResponse
     */
    offset?: number;
    /**
     * array of sortings
     * @type {Array<Sorting>}
     * @memberof DataResponse
     */
    sort?: Array<Sorting>;
    /**
     * table columns model
     * @type {Array<Column>}
     * @memberof DataResponse
     */
    columns?: Array<Column>;
    /**
     * array of data rows
     * @type {Array<DataRow>}
     * @memberof DataResponse
     */
    rows?: Array<DataRow>;
    /**
     * count of filtered recods
     * @type {number}
     * @memberof DataResponse
     */
    filteredCount?: number;
    /**
     * type of table layout
     * @type {LayoutType}
     * @memberof DataResponse
     */
    layout?: LayoutType;
    /**
     * width of filter column in view in px
     * @type {number}
     * @memberof DataResponse
     */
    filterColumnWidth?: number;
    /**
     * order of tags
     * @type {Array<number>}
     * @memberof DataResponse
     */
    tagsOrder?: Array<number>;
}

  /**
 * 
 * @export
 * @interface DataRow
 */
export interface DataRow {
    /**
     * unique record identifier
     * @type {string}
     * @memberof DataRow
     */
    id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataRow
     */
    values?: Array<string>;
}

  

export const DataType = createStringEnum([
  'Text',
  'Long text',
  'Pattern text',
  'List',
  'Map',
  'Date',
  'Date time',
  'Checkbox',
  'Record',
  'File',
  'Money',
  'Message template',
  'URL',
  'Email',
  'Object',
  'Number',
  'Portal subdomain',
]);

export type DataType = keyof typeof DataType;

  /**
 * 
 * @export
 * @interface DatesInterval
 */
export interface DatesInterval {
    /**
     * 
     * @type {string}
     * @memberof DatesInterval
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof DatesInterval
     */
    to?: string;
}

  /**
 * 
 * @export
 * @interface DefinedTutorRole
 */
export interface DefinedTutorRole {
    /**
     * 
     * @type {number}
     * @memberof DefinedTutorRole
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DefinedTutorRole
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DefinedTutorRole
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DefinedTutorRole
     */
    active?: boolean;
    /**
     * 
     * @type {Array<TutorRolePayRate>}
     * @memberof DefinedTutorRole
     */
    payRates?: Array<TutorRolePayRate>;
}

  

export const DeliveryMode = createStringEnum([
  'Not Set',
  'Classroom',
  'Online',
  'Workplace',
  'Other delivery',
  'Not applicable - recognition of prior learning/credit transfer',
  'WA: Local Class (1)',
  'WA: Remote Class - Live Conferencing (2)',
  'WA: Self Paced - Scheduled (3)',
  'WA: Self Paced - Unscheduled (4)',
  'WA: External - Correspondence (5)',
  'WA: Workplace (6)',
  'WA: Video/Television Learning (8)',
  'WA: Internet Site - Online Learning (9)',
  'Classroom and online',
  'Classroom and workplace',
  'Online and workplace',
  'Classroom, online & workplace',
]);

export type DeliveryMode = keyof typeof DeliveryMode;

      /**
    * Delivery schedule types, avalible for Survey forms only.
    */


export const DeliveryScheduleType = createStringEnum([
  'On enrol',
  'On start',
  'Midway',
  'At completion',
  'On demand',
]);

export type DeliveryScheduleType = keyof typeof DeliveryScheduleType;

  /**
 * 
 * @export
 * @interface Diff
 */
export interface Diff {
    /**
     * 
     * @type {Array<number>}
     * @memberof Diff
     */
    ids?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof Diff
     */
    search?: string;
    /**
     * 
     * @type {string}
     * @memberof Diff
     */
    filter?: string;
    /**
     * 
     * @type {Array<TagGroup>}
     * @memberof Diff
     */
    tagGroups?: Array<TagGroup>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Diff
     */
    diff?: { [key: string]: string; };
}

  /**
 * 
 * @export
 * @interface Discount
 */
export interface Discount {
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    name?: string;
    /**
     * 
     * @type {DiscountType}
     * @memberof Discount
     */
    discountType?: DiscountType;
    /**
     * 
     * @type {MoneyRounding}
     * @memberof Discount
     */
    rounding?: MoneyRounding;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    discountValue?: number;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    discountPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    discountMin?: number;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    discountMax?: number;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    cosAccount?: number;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    predictedStudentsPercentage?: number;
    /**
     * 
     * @type {DiscountAvailabilityType}
     * @memberof Discount
     */
    availableFor?: DiscountAvailabilityType;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    validFrom?: string;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    validFromOffset?: number;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    validTo?: string;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    validToOffset?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Discount
     */
    hideOnWeb?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    studentEnrolledWithinDays?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Discount
     */
    studentAgeUnder?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    studentAge?: number;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    studentPostcode?: string;
    /**
     * 
     * @type {Array<ConcessionType>}
     * @memberof Discount
     */
    discountConcessionTypes?: Array<ConcessionType>;
    /**
     * 
     * @type {Array<DiscountMembership>}
     * @memberof Discount
     */
    discountMemberships?: Array<DiscountMembership>;
    /**
     * 
     * @type {Array<Sale>}
     * @memberof Discount
     */
    discountCourseClasses?: Array<Sale>;
    /**
     * 
     * @type {boolean}
     * @memberof Discount
     */
    addByDefault?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    minEnrolments?: number;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    minValue?: number;
    /**
     * 
     * @type {Array<DiscountCorporatePass>}
     * @memberof Discount
     */
    corporatePassDiscounts?: Array<DiscountCorporatePass>;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    modifiedOn?: string;
    /**
     * Apply discount if user already has success enrolments on the same course
     * @type {boolean}
     * @memberof Discount
     */
    limitPreviousEnrolment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Discount
     */
    relationDiscount?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    courseIdMustEnrol?: number;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    courseNameMustEnrol?: string;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    minEnrolmentsForAnyCourses?: number;
}

  

export const DiscountAvailabilityType = createStringEnum([
  'Office only',
  'Online only',
  'Online and office',
]);

export type DiscountAvailabilityType = keyof typeof DiscountAvailabilityType;

  /**
 * 
 * @export
 * @interface DiscountCorporatePass
 */
export interface DiscountCorporatePass {
    /**
     * 
     * @type {number}
     * @memberof DiscountCorporatePass
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountCorporatePass
     */
    contactFullName?: string;
}

  /**
 * 
 * @export
 * @interface DiscountMembership
 */
export interface DiscountMembership {
    /**
     * 
     * @type {number}
     * @memberof DiscountMembership
     */
    productId?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountMembership
     */
    productName?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountMembership
     */
    productSku?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof DiscountMembership
     */
    contactRelations?: Array<number>;
}

  

export const DiscountType = createStringEnum([
  'Percent',
  'Dollar',
  'Fee override',
]);

export type DiscountType = keyof typeof DiscountType;

  /**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    added?: string;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    attachedRecordsCount?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof Document
     */
    tags?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    thumbnail?: string;
    /**
     * 
     * @type {Array<DocumentVersion>}
     * @memberof Document
     */
    versions?: Array<DocumentVersion>;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    description?: string;
    /**
     * 
     * @type {DocumentVisibility}
     * @memberof Document
     */
    access?: DocumentVisibility;
    /**
     * 
     * @type {boolean}
     * @memberof Document
     */
    shared?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Document
     */
    removed?: boolean;
    /**
     * 
     * @type {Array<DocumentAttachmentRelation>}
     * @memberof Document
     */
    attachmentRelations?: Array<DocumentAttachmentRelation>;
    /**
     * date and time when record was created
     * @type {string}
     * @memberof Document
     */
    createdOn?: string;
    /**
     * date and time when record was modified
     * @type {string}
     * @memberof Document
     */
    modifiedOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    urlWithoutVersionId?: string;
}

  /**
 * 
 * @export
 * @interface DocumentAttachmentRelation
 */
export interface DocumentAttachmentRelation {
    /**
     * 
     * @type {string}
     * @memberof DocumentAttachmentRelation
     */
    entity?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentAttachmentRelation
     */
    label?: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentAttachmentRelation
     */
    entityId?: number;
    /**
     * 
     * @type {Array<SearchItem>}
     * @memberof DocumentAttachmentRelation
     */
    relatedContacts?: Array<SearchItem>;
}

  /**
 * 
 * @export
 * @interface DocumentVersion
 */
export interface DocumentVersion {
    /**
     * 
     * @type {number}
     * @memberof DocumentVersion
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentVersion
     */
    added?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentVersion
     */
    current?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentVersion
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentVersion
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentVersion
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentVersion
     */
    mimeType?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentVersion
     */
    size?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentVersion
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentVersion
     */
    thumbnail?: string;
}

      /**
    * Delivery schedule types, avalible for Survey forms only.
    */


export const DocumentVisibility = createStringEnum([
  'Private',
  'Public',
  'Tutors and enrolled students',
  'Tutors only',
  'Link',
]);

export type DocumentVisibility = keyof typeof DocumentVisibility;

  /**
 * some email template parameters encapsulated in one object to use when sending email based on template
 * @export
 * @interface EmailTemplate
 */
export interface EmailTemplate {
    /**
     * Unique identifier
     * @type {number}
     * @memberof EmailTemplate
     */
    id?: number;
    /**
     * Message template type
     * @type {MessageType}
     * @memberof EmailTemplate
     */
    type?: MessageType;
    /**
     * email unique identifier, all built-in template codes start with 'ish.oncourse'
     * @type {string}
     * @memberof EmailTemplate
     */
    keyCode?: string;
    /**
     * Message template name
     * @type {string}
     * @memberof EmailTemplate
     */
    name?: string;
    /**
     * Message template entity
     * @type {string}
     * @memberof EmailTemplate
     */
    entity?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplate
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplate
     */
    plainBody?: string;
    /**
     * htmlBody
     * @type {string}
     * @memberof EmailTemplate
     */
    body?: string;
    /**
     * Automation status
     * @type {AutomationStatus}
     * @memberof EmailTemplate
     */
    status?: AutomationStatus;
    /**
     * Message variables list
     * @type {Array<Binding>}
     * @memberof EmailTemplate
     */
    variables?: Array<Binding>;
    /**
     * Message options list
     * @type {Array<Binding>}
     * @memberof EmailTemplate
     */
    options?: Array<Binding>;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplate
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailTemplate
     */
    modifiedOn?: string;
    /**
     * Message template description
     * @type {string}
     * @memberof EmailTemplate
     */
    description?: string;
    /**
     * Message template short description
     * @type {string}
     * @memberof EmailTemplate
     */
    shortDescription?: string;
    /**
     * automation tags separated by commas
     * @type {string}
     * @memberof EmailTemplate
     */
    automationTags?: string;
    /**
     * automation category
     * @type {string}
     * @memberof EmailTemplate
     */
    category?: string;
}

  /**
 * 
 * @export
 * @interface Enrolment
 */
export interface Enrolment {
    /**
     * 
     * @type {number}
     * @memberof Enrolment
     */
    id?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof Enrolment
     */
    tags?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof Enrolment
     */
    studentContactId?: number;
    /**
     * 
     * @type {string}
     * @memberof Enrolment
     */
    studentName?: string;
    /**
     * 
     * @type {number}
     * @memberof Enrolment
     */
    courseClassId?: number;
    /**
     * 
     * @type {string}
     * @memberof Enrolment
     */
    courseClassName?: string;
    /**
     * 
     * @type {ConfirmationStatus}
     * @memberof Enrolment
     */
    confirmationStatus?: ConfirmationStatus;
    /**
     * 
     * @type {boolean}
     * @memberof Enrolment
     */
    eligibilityExemptionIndicator?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Enrolment
     */
    outcomeIdTrainingOrg?: string;
    /**
     * 
     * @type {number}
     * @memberof Enrolment
     */
    studentIndustryANZSICCode?: number;
    /**
     * 
     * @type {string}
     * @memberof Enrolment
     */
    vetClientID?: string;
    /**
     * 
     * @type {string}
     * @memberof Enrolment
     */
    vetFundingSourceStateID?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Enrolment
     */
    vetIsFullTime?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Enrolment
     */
    vetTrainingContractID?: string;
    /**
     * 
     * @type {EnrolmentStatus}
     * @memberof Enrolment
     */
    status?: EnrolmentStatus;
    /**
     * 
     * @type {string}
     * @memberof Enrolment
     */
    displayStatus?: string;
    /**
     * 
     * @type {PaymentSource}
     * @memberof Enrolment
     */
    source?: PaymentSource;
    /**
     * 
     * @type {number}
     * @memberof Enrolment
     */
    relatedFundingSourceId?: number;
    /**
     * 
     * @type {EnrolmentStudyReason}
     * @memberof Enrolment
     */
    studyReason?: EnrolmentStudyReason;
    /**
     * 
     * @type {EnrolmentExemptionType}
     * @memberof Enrolment
     */
    vetFeeExemptionType?: EnrolmentExemptionType;
    /**
     * 
     * @type {ClassFundingSource}
     * @memberof Enrolment
     */
    fundingSource?: ClassFundingSource;
    /**
     * 
     * @type {string}
     * @memberof Enrolment
     */
    associatedCourseIdentifier?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Enrolment
     */
    vetInSchools?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Enrolment
     */
    suppressAvetmissExport?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Enrolment
     */
    vetPurchasingContractID?: string;
    /**
     * 
     * @type {string}
     * @memberof Enrolment
     */
    cricosConfirmation?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Enrolment
     */
    vetFeeIndicator?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Enrolment
     */
    trainingPlanDeveloped?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Enrolment
     */
    feeHelpAmount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Enrolment
     */
    feeHelpClass?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Enrolment
     */
    invoicesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Enrolment
     */
    outcomesCount?: number;
    /**
     * 
     * @type {EnrolmentFeeStatus}
     * @memberof Enrolment
     */
    feeStatus?: EnrolmentFeeStatus;
    /**
     * 
     * @type {CourseClassAttendanceType}
     * @memberof Enrolment
     */
    attendanceType?: CourseClassAttendanceType;
    /**
     * 
     * @type {string}
     * @memberof Enrolment
     */
    creditOfferedValue?: string;
    /**
     * 
     * @type {string}
     * @memberof Enrolment
     */
    creditUsedValue?: string;
    /**
     * 
     * @type {string}
     * @memberof Enrolment
     */
    creditFOEId?: string;
    /**
     * 
     * @type {string}
     * @memberof Enrolment
     */
    creditProvider?: string;
    /**
     * 
     * @type {EnrolmentCreditProviderType}
     * @memberof Enrolment
     */
    creditProviderType?: EnrolmentCreditProviderType;
    /**
     * 
     * @type {EnrolmentCreditTotal}
     * @memberof Enrolment
     */
    creditTotal?: EnrolmentCreditTotal;
    /**
     * 
     * @type {EnrolmentReportingStatus}
     * @memberof Enrolment
     */
    studentLoanStatus?: EnrolmentReportingStatus;
    /**
     * 
     * @type {EnrolmentCreditType}
     * @memberof Enrolment
     */
    creditType?: EnrolmentCreditType;
    /**
     * 
     * @type {EnrolmentCreditLevel}
     * @memberof Enrolment
     */
    creditLevel?: EnrolmentCreditLevel;
    /**
     * 
     * @type {Array<Document>}
     * @memberof Enrolment
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Enrolment
     */
    customFields?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof Enrolment
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Enrolment
     */
    modifiedOn?: string;
    /**
     * 
     * @type {Array<Assessment>}
     * @memberof Enrolment
     */
    assessments?: Array<Assessment>;
    /**
     * 
     * @type {Array<AssessmentSubmission>}
     * @memberof Enrolment
     */
    submissions?: Array<AssessmentSubmission>;
    /**
     * 
     * @type {string}
     * @memberof Enrolment
     */
    vetPurchasingContractScheduleID?: string;
}

  

export const EnrolmentCreditLevel = createStringEnum([
  'No credit/RPL was offered for VET',
  'Vocational Graduate Certificate',
  'Vocational Graduate Diploma',
  'Advanced Diploma',
  'Statement of Attainment at Advanced Diploma level',
  'Bridging and Enabling Course at Advanced Diploma',
  'Diploma',
  'Statement of Attainment at Diploma level',
  'Bridging and Enabling Course at Diploma',
  'Certificate IV',
  'Statement of Attainment at Certificate IV level',
  'Bridging and Enabling Course at Certificate IV level',
  'Certificate III',
  'Statement of Attainment at Certificate III level',
  'Bridging and Enabling Course at Certificate III level',
  'Certificate II',
  'Statement of Attainment at Certificate II level',
  'Bridging and Enabling Course at Certificate II level',
  'Certificate I',
  'Statement of Attainment at Certificate I level',
  'Other qualification',
]);

export type EnrolmentCreditLevel = keyof typeof EnrolmentCreditLevel;

  

export const EnrolmentCreditProviderType = createStringEnum([
  'No credit/RPL was offered for VET',
  'University',
  'Other Higher Education Provider',
  'TAFE',
  'Secondary Schools/Colleges',
  'Other Registered Training Organisations',
  'Not elsewhere categorised',
]);

export type EnrolmentCreditProviderType = keyof typeof EnrolmentCreditProviderType;

  

export const EnrolmentCreditTotal = createStringEnum([
  'Unit of study is NOT an RPL unit of study',
  'Unit of study consists wholly of RPL',
  'Unit of study has a component of RPL',
]);

export type EnrolmentCreditTotal = keyof typeof EnrolmentCreditTotal;

  

export const EnrolmentCreditType = createStringEnum([
  'No credit/RPL was offered',
  'Credit/RPL was offered for prior higher education study only',
  'Credit/RPL was offered for prior VET study only',
  'Credit/RPL was offered for a combination of prior higher education and VET study',
  'Credit/RPL was offered for study undertaken at an education provider outside Australia',
  'Credit/RPL was offered for work experience undertaken inside or outside Australia',
  'Other',
]);

export type EnrolmentCreditType = keyof typeof EnrolmentCreditType;

  

export const EnrolmentExemptionType = createStringEnum([
  'Not set',
  'No (N)',
  'Yes (Y)',
  'QLD only - Concessional Participant (C)',
  'WA only - Pensioner Concession Card (D)',
  'WA only - Repatriation Health Benefits Cards (E)',
  'WA only - Fee Exempt (F)',
  'VIC - VCE Scholarship, WA - AUSTUDY/ABSTUDY (G)',
  'VIC only - Health Care Card (H)',
  'WA only - Health Care Card Youth Allowance - Job seeker (Fee Free) (I)',
  'WA only - Job Network Card (Fee Free) (J)',
  'WA only - Under 18 Years of Age (L)',
  'VIC only - Prisoner (M)',
  'QLD - Non-concessional participant, WA - Health Care Card (N)',
  'VIC - Other, WA - Youth Allowance (O)',
  'VIC only - Pensioner concession card (P)',
  'WA only - Custodial Institution Inmates (Prison Inmates) (Q)',
  'WA only - Health Care Card - New Start (Fee Free) (S)',
  'VIC - Veteran gold card concession, WA - Fees Waived (due to severe financial hardship) (V)',
  'VIC - None, WA - No Concession  (Z)',
  'Outreach Support (OS)',
]);

export type EnrolmentExemptionType = keyof typeof EnrolmentExemptionType;

  

export const EnrolmentFeeStatus = createStringEnum([
  'non State Government subsidised',
  'Restricted Access Arrangement',
  'Victorian State Government subsidised',
  'New South Wales State Government subsidised',
  'Queensland State Government subsidised',
  'South Australian State Government subsidised',
  'Western Australian State Government subsidised',
  'Tasmania State Government subsidised',
  'Northern Territory Government subsidised',
  'Australian Capital Territory Government subsidised',
]);

export type EnrolmentFeeStatus = keyof typeof EnrolmentFeeStatus;

  

export const EnrolmentReportingStatus = createStringEnum([
  'Eligible',
  'Not eligible',
  'Ongoing',
  'Finalized',
]);

export type EnrolmentReportingStatus = keyof typeof EnrolmentReportingStatus;

      /**
    * List of enrolment's statuses.
    */


export const EnrolmentStatus = createStringEnum([
  '',
  'Not processed',
  'Awaiting confirmation',
  'In transaction',
  'Active',
  'Failed',
  'Card declined',
  'Failed - no places',
  'Cancelled',
  'Credited',
]);

export type EnrolmentStatus = keyof typeof EnrolmentStatus;

  

export const EnrolmentStudyReason = createStringEnum([
  'Not stated',
  'To get a job',
  'To develop my existing business',
  'To start my own business',
  'To try for a different career',
  'To get a better job or promotion',
  'To get skills for community/voluntary work',
  'It was a requirement of my job',
  'I wanted extra skills for my job',
  'To get into another course of study',
  'Other reasons',
  'For personal interest or self-development',
]);

export type EnrolmentStudyReason = keyof typeof EnrolmentStudyReason;

      /**
    * Cart action for related courses in checkout
    */


export const EntityRelationCartAction = createStringEnum([
  'No action',
  'Suggestion',
  'Add but do not allow removal',
  'Add and allow removal',
]);

export type EntityRelationCartAction = keyof typeof EntityRelationCartAction;

  /**
 * 
 * @export
 * @interface EntityRelationType
 */
export interface EntityRelationType {
    /**
     * 
     * @type {number}
     * @memberof EntityRelationType
     */
    id?: number;
    /**
     * date and time when record was created
     * @type {string}
     * @memberof EntityRelationType
     */
    created?: string;
    /**
     * date and time when record was modified
     * @type {string}
     * @memberof EntityRelationType
     */
    modified?: string;
    /**
     * Relation type name
     * @type {string}
     * @memberof EntityRelationType
     */
    name?: string;
    /**
     * Relation type short description
     * @type {string}
     * @memberof EntityRelationType
     */
    description?: string;
    /**
     * stright direction relation name
     * @type {string}
     * @memberof EntityRelationType
     */
    fromName?: string;
    /**
     * revers direction relation name
     * @type {string}
     * @memberof EntityRelationType
     */
    toName?: string;
    /**
     * is show related course on web site
     * @type {boolean}
     * @memberof EntityRelationType
     */
    isShownOnWeb?: boolean;
    /**
     * Applicable discount for related course
     * @type {number}
     * @memberof EntityRelationType
     */
    discountId?: number;
    /**
     * 
     * @type {EntityRelationCartAction}
     * @memberof EntityRelationType
     */
    shoppingCart?: EntityRelationCartAction;
    /**
     * consider enrolment history for student
     * @type {boolean}
     * @memberof EntityRelationType
     */
    considerHistory?: boolean;
}

      /**
    * Avalible Custom field entity Type
    */


export const EntityType = createStringEnum([
  'Enrolment',
  'Application',
  'Contact',
  'Course',
  'CourseClass',
  'WaitingList',
  'Survey',
  'Article',
  'Membership',
  'Voucher',
  'ArticleProduct',
  'VoucherProduct',
  'MembershipProduct',
  'Lead',
  'Invoice',
  'Quote',
  'Tutor',
  'Student',
  'Site',
  'Room',
]);

export type EntityType = keyof typeof EntityType;

  /**
 * 
 * @export
 * @interface EnumItem
 */
export interface EnumItem {
    /**
     * 
     * @type {string}
     * @memberof EnumItem
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof EnumItem
     */
    label?: string;
}

      /**
    * List of integration types
    */


export const EnumName = createStringEnum([
  'DeliveryMode',
  'ClassFundingSource',
  'ExportJurisdiction',
  'TrainingOrg_Types',
  'AddressStates',
  'MaintenanceTimes',
  'TwoFactorAuthStatus',
]);

export type EnumName = keyof typeof EnumName;

  /**
 * 
 * @export
 * @interface ExecuteImportRequest
 */
export interface ExecuteImportRequest {
    /**
     * 
     * @type {number}
     * @memberof ExecuteImportRequest
     */
    importScriptId?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ExecuteImportRequest
     */
    variables?: { [key: string]: string; };
}

  /**
 * 
 * @export
 * @interface ExecuteScriptRequest
 */
export interface ExecuteScriptRequest {
    /**
     * 
     * @type {number}
     * @memberof ExecuteScriptRequest
     */
    scriptId?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ExecuteScriptRequest
     */
    variables?: { [key: string]: string; };
    /**
     * 
     * @type {SearchQuery}
     * @memberof ExecuteScriptRequest
     */
    searchQuery?: SearchQuery;
}

  

export const ExpiryType = createStringEnum([
  'Days',
  '1st January',
  '1st July',
  'Never (Lifetime)',
]);

export type ExpiryType = keyof typeof ExpiryType;

  /**
 * 
 * @export
 * @interface ExportRequest
 */
export interface ExportRequest {
    /**
     * 
     * @type {string}
     * @memberof ExportRequest
     */
    entityName?: string;
    /**
     * 
     * @type {number}
     * @memberof ExportRequest
     */
    template?: number;
    /**
     * 
     * @type {string}
     * @memberof ExportRequest
     */
    search?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportRequest
     */
    filter?: string;
    /**
     * 
     * @type {Array<TagGroup>}
     * @memberof ExportRequest
     */
    tagGroups?: Array<TagGroup>;
    /**
     * 
     * @type {Array<Sorting>}
     * @memberof ExportRequest
     */
    sorting?: Array<Sorting>;
    /**
     * export variables values
     * @type {{ [key: string]: string; }}
     * @memberof ExportRequest
     */
    variables?: { [key: string]: string; };
    /**
     * affect on CSV only, use tab separators instead of comma character
     * @type {boolean}
     * @memberof ExportRequest
     */
    exportToClipboard?: boolean;
    /**
     * generate and save preview for report
     * @type {boolean}
     * @memberof ExportRequest
     */
    createPreview?: boolean;
}

  /**
 * 
 * @export
 * @interface ExportTemplate
 */
export interface ExportTemplate {
    /**
     * UUID of export template in onCourse
     * @type {number}
     * @memberof ExportTemplate
     */
    id?: number;
    /**
     * export display name
     * @type {string}
     * @memberof ExportTemplate
     */
    name?: string;
    /**
     * export unique identifier, all built-in template codes start with 'ish.oncourse'
     * @type {string}
     * @memberof ExportTemplate
     */
    keyCode?: string;
    /**
     * export entity
     * @type {string}
     * @memberof ExportTemplate
     */
    entity?: string;
    /**
     * export body
     * @type {string}
     * @memberof ExportTemplate
     */
    body?: string;
    /**
     * Automation status
     * @type {AutomationStatus}
     * @memberof ExportTemplate
     */
    status?: AutomationStatus;
    /**
     * image byte array
     * @type {string}
     * @memberof ExportTemplate
     */
    preview?: string;
    /**
     * export variables list
     * @type {Array<Binding>}
     * @memberof ExportTemplate
     */
    variables?: Array<Binding>;
    /**
     * export options list
     * @type {Array<Binding>}
     * @memberof ExportTemplate
     */
    options?: Array<Binding>;
    /**
     * export output file type
     * @type {OutputType}
     * @memberof ExportTemplate
     */
    outputType?: OutputType;
    /**
     * 
     * @type {string}
     * @memberof ExportTemplate
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportTemplate
     */
    modifiedOn?: string;
    /**
     * export description
     * @type {string}
     * @memberof ExportTemplate
     */
    description?: string;
    /**
     * Message template short description
     * @type {string}
     * @memberof ExportTemplate
     */
    shortDescription?: string;
    /**
     * automation tags separated by commas
     * @type {string}
     * @memberof ExportTemplate
     */
    automationTags?: string;
    /**
     * automation category
     * @type {string}
     * @memberof ExportTemplate
     */
    category?: string;
}

  /**
 * 
 * @export
 * @interface Faculty
 */
export interface Faculty {
    /**
     * 
     * @type {number}
     * @memberof Faculty
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Faculty
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Faculty
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof Faculty
     */
    webDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof Faculty
     */
    shortWebDescription?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Faculty
     */
    isShownOnWeb?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Faculty
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Faculty
     */
    modifiedOn?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof Faculty
     */
    tags?: Array<number>;
    /**
     * 
     * @type {Array<Document>}
     * @memberof Faculty
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {Array<Course>}
     * @memberof Faculty
     */
    relatedCourses?: Array<Course>;
}

  /**
 * 
 * @export
 * @interface Field
 */
export interface Field {
    /**
     * 
     * @type {FieldType}
     * @memberof Field
     */
    type?: FieldType;
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    helpText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Field
     */
    mandatory?: boolean;
    /**
     * key of related field, that affects this type display
     * @type {string}
     * @memberof Field
     */
    relatedFieldKey?: string;
    /**
     * Related field value, that allows this type to be shown
     * @type {string}
     * @memberof Field
     */
    relatedFieldValue?: string;
}

  /**
 * 
 * @export
 * @interface FieldType
 */
export interface FieldType {
    /**
     * 
     * @type {string}
     * @memberof FieldType
     */
    uniqueKey?: string;
    /**
     * 
     * @type {string}
     * @memberof FieldType
     */
    label?: string;
}

  /**
 * List of account types
 * @export
 * @interface Filter
 */
export interface Filter {
    /**
     * filter name
     * @type {string}
     * @memberof Filter
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Filter
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    entity?: string;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    expression?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Filter
     */
    showForCurrentOnly?: boolean;
}

  /**
 * 
 * @export
 * @interface FinalisePeriodInfo
 */
export interface FinalisePeriodInfo {
    /**
     * 
     * @type {string}
     * @memberof FinalisePeriodInfo
     */
    lastDate?: string;
    /**
     * 
     * @type {string}
     * @memberof FinalisePeriodInfo
     */
    targetDate?: string;
    /**
     * 
     * @type {number}
     * @memberof FinalisePeriodInfo
     */
    unreconciledPaymentsCount?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof FinalisePeriodInfo
     */
    unreconciledPaymentsBankingIds?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof FinalisePeriodInfo
     */
    unbankedPaymentInCount?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof FinalisePeriodInfo
     */
    unbankedPaymentInIds?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof FinalisePeriodInfo
     */
    unbankedPaymentOutCount?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof FinalisePeriodInfo
     */
    unbankedPaymentOutIds?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof FinalisePeriodInfo
     */
    depositBankingCount?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof FinalisePeriodInfo
     */
    depositBankingIds?: Array<number>;
}

  /**
 * 
 * @export
 * @interface FinancialLine
 */
export interface FinancialLine {
    /**
     * 
     * @type {number}
     * @memberof FinancialLine
     */
    relatedEntityId?: number;
    /**
     * 
     * @type {FinancialType}
     * @memberof FinancialLine
     */
    type?: FinancialType;
    /**
     * 
     * @type {string}
     * @memberof FinancialLine
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialLine
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialLine
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof FinancialLine
     */
    referenceNumber?: string;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof FinancialLine
     */
    status?: PaymentStatus;
    /**
     * 
     * @type {number}
     * @memberof FinancialLine
     */
    owing?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialLine
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialLine
     */
    balance?: number;
}

      /**
    * Set of USI statuses.
    */


export const FinancialType = createStringEnum([
  'Invoice',
  'PaymentIn',
  'PaymentOut',
]);

export type FinancialType = keyof typeof FinancialType;

      /**
    * List of forbidden tag names
    */


export const ForbiddenTagNames = createStringEnum([
  'access',
  'admin',
  'ajax',
  'attachment',
  'attachmentdata',
  'attachmentinfo',
  'attachmentinforelation',
  'attachmentinforelations',
  'attachmentinfos',
  'binary',
  'binarydata',
  'binaryinfo',
  'binaryinforelation',
  'binaryinforelations',
  'binaryinfos',
  'cgi-bin',
  'class',
  'classes',
  'code',
  'college',
  'collegedomain',
  'collegedomains',
  'colleges',
  'concession',
  'contact',
  'contacts',
  'countries',
  'country',
  'course',
  'courseclass',
  'courseclasses',
  'coursemodule',
  'coursemodules',
  'courses',
  'detail',
  'directaction',
  'directactions',
  'discount',
  'discountcourseclass',
  'discountcourseclasses',
  'discountenrolment',
  'discountenrolments',
  'discounts',
  'enrol',
  'enrolment',
  'enrolments',
  'enrolnow',
  'head',
  'language',
  'languages',
  'layout-content',
  'layout-footer',
  'layout-header',
  'layout-leftnav',
  'layout-rightnav',
  'list',
  'log',
  'login',
  'logs',
  'message',
  'messageperson',
  'messagepersons',
  'messagetemplate',
  'messagetemplates',
  'messages',
  'module',
  'modules',
  'oncourse',
  'payment',
  'paymenttransaction',
  'paymenttransactions',
  'payments',
  'preference',
  'preferences',
  'promo',
  'promotion',
  'qualification',
  'qualifications',
  'queuedrecord',
  'queuedrecords',
  'refresh',
  'room',
  'rooms',
  's',
  'session',
  'sessiontutor',
  'sessiontutors',
  'sessions',
  'site',
  'sites',
  'stats',
  'student',
  'students',
  'tag',
  'taggrouprequirement',
  'taggrouprequirements',
  'taggable',
  'taggabletag',
  'taggabletags',
  'taggables',
  'tags',
  'timeline',
  'timetable',
  'trainingpackage',
  'trainingpackages',
  'tutor',
  'tutorrole',
  'tutorroles',
  'tutors',
  'waitinglist',
  'waitinglistsite',
  'waitinglistsites',
  'waitinglists',
  'willowuser',
  'willowusers',
]);

export type ForbiddenTagNames = keyof typeof ForbiddenTagNames;

  /**
 * 
 * @export
 * @interface FundingSource
 */
export interface FundingSource {
    /**
     * 
     * @type {number}
     * @memberof FundingSource
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FundingSource
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FundingSource
     */
    name?: string;
    /**
     * 
     * @type {AvetmissExportFlavour}
     * @memberof FundingSource
     */
    flavour?: AvetmissExportFlavour;
    /**
     * date and time when record was created
     * @type {string}
     * @memberof FundingSource
     */
    created?: string;
    /**
     * date and time when record was modified
     * @type {string}
     * @memberof FundingSource
     */
    modified?: string;
}

  

export const FundingStatus = createStringEnum([
  'unknown',
  'success',
  'fail',
]);

export type FundingStatus = keyof typeof FundingStatus;

  /**
 * 
 * @export
 * @interface FundingUpload
 */
export interface FundingUpload {
    /**
     * 
     * @type {number}
     * @memberof FundingUpload
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FundingUpload
     */
    created?: string;
    /**
     * 
     * @type {FundingStatus}
     * @memberof FundingUpload
     */
    status?: FundingStatus;
    /**
     * 
     * @type {string}
     * @memberof FundingUpload
     */
    systemUser?: string;
    /**
     * 
     * @type {number}
     * @memberof FundingUpload
     */
    outcomesCount?: number;
    /**
     * 
     * @type {AvetmissExportSettings}
     * @memberof FundingUpload
     */
    settings?: AvetmissExportSettings;
    /**
     * 
     * @type {boolean}
     * @memberof FundingUpload
     */
    lastSettings?: boolean;
}

  

export const GradingEntryType = createStringEnum([
  'number',
  'choice list',
]);

export type GradingEntryType = keyof typeof GradingEntryType;

  /**
 * 
 * @export
 * @interface GradingItem
 */
export interface GradingItem {
    /**
     * 
     * @type {number}
     * @memberof GradingItem
     */
    id?: number;
    /**
     * date and time when record was created
     * @type {string}
     * @memberof GradingItem
     */
    created?: string;
    /**
     * date and time when record was modified
     * @type {string}
     * @memberof GradingItem
     */
    modified?: string;
    /**
     * Grading item name
     * @type {string}
     * @memberof GradingItem
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof GradingItem
     */
    lowerBound?: number;
}

  /**
 * 
 * @export
 * @interface GradingType
 */
export interface GradingType {
    /**
     * 
     * @type {number}
     * @memberof GradingType
     */
    id?: number;
    /**
     * date and time when record was created
     * @type {string}
     * @memberof GradingType
     */
    created?: string;
    /**
     * date and time when record was modified
     * @type {string}
     * @memberof GradingType
     */
    modified?: string;
    /**
     * Grading type name
     * @type {string}
     * @memberof GradingType
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof GradingType
     */
    minValue?: number;
    /**
     * 
     * @type {number}
     * @memberof GradingType
     */
    maxValue?: number;
    /**
     * 
     * @type {GradingEntryType}
     * @memberof GradingType
     */
    entryType?: GradingEntryType;
    /**
     * 
     * @type {Array<GradingItem>}
     * @memberof GradingType
     */
    gradingItems?: Array<GradingItem>;
}

  /**
 * 
 * @export
 * @interface Heading
 */
export interface Heading {
    /**
     * 
     * @type {string}
     * @memberof Heading
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Heading
     */
    description?: string;
    /**
     * 
     * @type {Array<Field>}
     * @memberof Heading
     */
    fields?: Array<Field>;
}

  /**
 * 
 * @export
 * @interface Holiday
 */
export interface Holiday {
    /**
     * Unique holiday identifier
     * @type {string}
     * @memberof Holiday
     */
    id?: string;
    /**
     * Holiday description
     * @type {string}
     * @memberof Holiday
     */
    description?: string;
    /**
     * ISO date string with start date of holidays
     * @type {string}
     * @memberof Holiday
     */
    startDate?: string;
    /**
     * ISO date string with end of holidays
     * @type {string}
     * @memberof Holiday
     */
    endDate?: string;
    /**
     * ISO date string with start date of holidays
     * @type {string}
     * @memberof Holiday
     */
    startDateTime?: string;
    /**
     * ISO datetime string with end of holidays
     * @type {string}
     * @memberof Holiday
     */
    endDateTime?: string;
    /**
     * 
     * @type {RepeatEnum}
     * @memberof Holiday
     */
    repeat?: RepeatEnum;
    /**
     * 
     * @type {RepeatEndEnum}
     * @memberof Holiday
     */
    repeatEnd?: RepeatEndEnum;
    /**
     * count of repetitions
     * @type {number}
     * @memberof Holiday
     */
    repeatEndAfter?: number;
    /**
     * ISO date string with date when repetitions ends
     * @type {string}
     * @memberof Holiday
     */
    repeatOn?: string;
    /**
     * date and time when record was created
     * @type {string}
     * @memberof Holiday
     */
    created?: string;
    /**
     * date and time when record was modified
     * @type {string}
     * @memberof Holiday
     */
    modified?: string;
}

  /**
 * 
 * @export
 * @interface ImportModel
 */
export interface ImportModel {
    /**
     * UUID of import script in onCourse
     * @type {number}
     * @memberof ImportModel
     */
    id?: number;
    /**
     * import display name
     * @type {string}
     * @memberof ImportModel
     */
    name?: string;
    /**
     * import unique identifier, all built-in script codes start with 'ish.oncourse'
     * @type {string}
     * @memberof ImportModel
     */
    keyCode?: string;
    /**
     * import entity
     * @type {string}
     * @memberof ImportModel
     */
    entity?: string;
    /**
     * import script
     * @type {string}
     * @memberof ImportModel
     */
    body?: string;
    /**
     * Automation status
     * @type {AutomationStatus}
     * @memberof ImportModel
     */
    status?: AutomationStatus;
    /**
     * import variables list
     * @type {Array<Binding>}
     * @memberof ImportModel
     */
    variables?: Array<Binding>;
    /**
     * import options list
     * @type {Array<Binding>}
     * @memberof ImportModel
     */
    options?: Array<Binding>;
    /**
     * 
     * @type {string}
     * @memberof ImportModel
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportModel
     */
    modifiedOn?: string;
    /**
     * import description
     * @type {string}
     * @memberof ImportModel
     */
    description?: string;
    /**
     * Message template short description
     * @type {string}
     * @memberof ImportModel
     */
    shortDescription?: string;
    /**
     * automation tags separated by commas
     * @type {string}
     * @memberof ImportModel
     */
    automationTags?: string;
    /**
     * automation category
     * @type {string}
     * @memberof ImportModel
     */
    category?: string;
}

  /**
 * 
 * @export
 * @interface InfoLine
 */
export interface InfoLine {
    /**
     * displayable name of attribute
     * @type {string}
     * @memberof InfoLine
     */
    label?: string;
    /**
     * attribute value for first contact
     * @type {string}
     * @memberof InfoLine
     */
    a?: string;
    /**
     * attribute value for second contact
     * @type {string}
     * @memberof InfoLine
     */
    b?: string;
}

  /**
 * 
 * @export
 * @interface Integration
 */
export interface Integration {
    /**
     * Unique integration identifier
     * @type {string}
     * @memberof Integration
     */
    id?: string;
    /**
     * integration type
     * @type {number}
     * @memberof Integration
     */
    type?: number;
    /**
     * Integration name
     * @type {string}
     * @memberof Integration
     */
    name?: string;
    /**
     * verification code
     * @type {string}
     * @memberof Integration
     */
    verificationCode?: string;
    /**
     * 
     * @type {Array<IntegrationProp>}
     * @memberof Integration
     */
    props?: Array<IntegrationProp>;
    /**
     * date and time when record was created
     * @type {string}
     * @memberof Integration
     */
    created?: string;
    /**
     * date and time when record was modified
     * @type {string}
     * @memberof Integration
     */
    modified?: string;
}

  /**
 * 
 * @export
 * @interface IntegrationProp
 */
export interface IntegrationProp {
    /**
     * field key
     * @type {string}
     * @memberof IntegrationProp
     */
    key?: string;
    /**
     * field value
     * @type {string}
     * @memberof IntegrationProp
     */
    value?: string;
}

  /**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    id?: number;
    /**
     * 
     * @type {InvoiceType}
     * @memberof Invoice
     */
    type?: InvoiceType;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    contactId?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    leadId?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    leadCustomerName?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    contactName?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    customerReference?: string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    invoiceNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    quoteNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    relatedFundingSourceId?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    billToAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    shippingAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    invoiceDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    dateDue?: string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    overdue?: number;
    /**
     * 
     * @type {Array<AbstractInvoiceLine>}
     * @memberof Invoice
     */
    invoiceLines?: Array<AbstractInvoiceLine>;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    amountOwing?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    publicNotes?: string;
    /**
     * 
     * @type {Array<InvoicePaymentPlan>}
     * @memberof Invoice
     */
    paymentPlans?: Array<InvoicePaymentPlan>;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    createdByUser?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    sendEmail?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof Invoice
     */
    tags?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    modifiedOn?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Invoice
     */
    customFields?: { [key: string]: string; };
}

  /**
 * 
 * @export
 * @interface InvoicePaymentPlan
 */
export interface InvoicePaymentPlan {
    /**
     * 
     * @type {number}
     * @memberof InvoicePaymentPlan
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoicePaymentPlan
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoicePaymentPlan
     */
    type?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InvoicePaymentPlan
     */
    successful?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InvoicePaymentPlan
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoicePaymentPlan
     */
    entityName?: string;
}

      /**
    * quote status
    */


export const InvoiceType = createStringEnum([
  'Invoice',
  'Quote',
]);

export type InvoiceType = keyof typeof InvoiceType;

      /**
    * Permission key codes
    */


export const KeyCode = createStringEnum([
  'VET_COURSE',
  'ENROLMENT_CREATE',
  'SPECIAL_EMAIL_50',
  'SPECIAL_EMAIL_MASS',
  'PAYMENT_IN_CREATE',
  'ADMIN',
]);

export type KeyCode = keyof typeof KeyCode;

  /**
 * 
 * @export
 * @interface Language
 */
export interface Language {
    /**
     * 
     * @type {number}
     * @memberof Language
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    absCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Language
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    name?: string;
}

  /**
 * 
 * @export
 * @interface LastRun
 */
export interface LastRun {
    /**
     * 
     * @type {string}
     * @memberof LastRun
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof LastRun
     */
    status?: string;
}

      /**
    * Avalible views for Entity Api
    */


export const LayoutType = createStringEnum([
  'Two column',
  'Three column',
]);

export type LayoutType = keyof typeof LayoutType;

  /**
 * 
 * @export
 * @interface Lead
 */
export interface Lead {
    /**
     * 
     * @type {number}
     * @memberof Lead
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    modifiedOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    studentNotes?: string;
    /**
     * 
     * @type {number}
     * @memberof Lead
     */
    studentCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Lead
     */
    contactId?: number;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    contactName?: string;
    /**
     * 
     * @type {number}
     * @memberof Lead
     */
    estimatedValue?: number;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    nextActionOn?: string;
    /**
     * 
     * @type {LeadStatus}
     * @memberof Lead
     */
    status?: LeadStatus;
    /**
     * 
     * @type {number}
     * @memberof Lead
     */
    assignToId?: number;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    assignTo?: string;
    /**
     * 
     * @type {Array<Sale>}
     * @memberof Lead
     */
    relatedSellables?: Array<Sale>;
    /**
     * 
     * @type {Array<LeadInvoice>}
     * @memberof Lead
     */
    invoices?: Array<LeadInvoice>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Lead
     */
    tags?: Array<number>;
    /**
     * 
     * @type {Array<Site>}
     * @memberof Lead
     */
    sites?: Array<Site>;
    /**
     * 
     * @type {Array<Document>}
     * @memberof Lead
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Lead
     */
    customFields?: { [key: string]: string; };
}

  /**
 * 
 * @export
 * @interface LeadInvoice
 */
export interface LeadInvoice {
    /**
     * 
     * @type {number}
     * @memberof LeadInvoice
     */
    id?: number;
    /**
     * 
     * @type {InvoiceType}
     * @memberof LeadInvoice
     */
    invoiceType?: InvoiceType;
    /**
     * 
     * @type {string}
     * @memberof LeadInvoice
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadInvoice
     */
    invoiceNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadInvoice
     */
    quoteNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadInvoice
     */
    total?: number;
}

  

export const LeadStatus = createStringEnum([
  'Open',
  'Closed',
]);

export type LeadStatus = keyof typeof LeadStatus;

  /**
 * 
 * @export
 * @interface LockedDate
 */
export interface LockedDate {
    /**
     * 
     * @type {string}
     * @memberof LockedDate
     */
    lockedDate?: string;
}

  /**
 * 
 * @export
 * @interface LogFile
 */
export interface LogFile {
    /**
     * 
     * @type {string}
     * @memberof LogFile
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof LogFile
     */
    content?: string;
}

  /**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * User login or email used for authorization
     * @type {string}
     * @memberof LoginRequest
     */
    login?: string;
    /**
     * User password
     * @type {string}
     * @memberof LoginRequest
     */
    password?: string;
    /**
     * New password. Send in case when user password was forced to be changed by admin
     * @type {string}
     * @memberof LoginRequest
     */
    newPassword?: string;
    /**
     * Flag to skip TFA on login
     * @type {boolean}
     * @memberof LoginRequest
     */
    skipTfa?: boolean;
    /**
     * Flag to invalidate other's user active session and login
     * @type {boolean}
     * @memberof LoginRequest
     */
    kickOut?: boolean;
    /**
     * 6 digit token (validationCode). Send when authenticating with TFA
     * @type {number}
     * @memberof LoginRequest
     */
    token?: number;
    /**
     * GA secret code. Send when enabling TFA first time
     * @type {string}
     * @memberof LoginRequest
     */
    secretCode?: string;
    /**
     * License agreement. User cannot authorize into system without it.
     * @type {boolean}
     * @memberof LoginRequest
     */
    eulaAccess?: boolean;
}

  /**
 * Authentication Api response
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * User login status
     * @type {LoginStatus}
     * @memberof LoginResponse
     */
    loginStatus?: LoginStatus;
    /**
     * Auth error description
     * @type {string}
     * @memberof LoginResponse
     */
    errorMessage?: string;
    /**
     * Complexity of passowrd. Send when password complexety validation fails
     * @type {boolean}
     * @memberof LoginResponse
     */
    passwordComlexity?: boolean;
    /**
     * Url for QR code. Send when TFA is enabled first time. Used to render QR code on UI
     * @type {string}
     * @memberof LoginResponse
     */
    totpUrl?: string;
    /**
     * Eula license Url
     * @type {string}
     * @memberof LoginResponse
     */
    eulaUrl?: string;
    /**
     * last user login date/time in UTC ISO-8601 format
     * @type {string}
     * @memberof LoginResponse
     */
    lastLoginOn?: string;
}

      /**
    * List of possible user status responses. Can has one of the following values: Invalid credentials, Too many users, Token required, Invalid or expired token, Concurrent sessions found, Weak password, Password outdated, Forced password update, TFA optional, TFA required, Eula required, Login successful 
    */


export const LoginStatus = createStringEnum([
  'Invalid credentials',
  'Too many users',
  'Token required',
  'Invalid or expired token',
  'Concurrent sessions found',
  'Weak password',
  'Password outdated',
  'Forced password update',
  'TFA optional',
  'TFA required',
  'Eula required',
  'Login successful',
]);

export type LoginStatus = keyof typeof LoginStatus;

  /**
 * 
 * @export
 * @interface MembershipCorporatePass
 */
export interface MembershipCorporatePass {
    /**
     * 
     * @type {number}
     * @memberof MembershipCorporatePass
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MembershipCorporatePass
     */
    contactFullName?: string;
}

  /**
 * 
 * @export
 * @interface MembershipDiscount
 */
export interface MembershipDiscount {
    /**
     * 
     * @type {number}
     * @memberof MembershipDiscount
     */
    discountId?: number;
    /**
     * 
     * @type {string}
     * @memberof MembershipDiscount
     */
    discountName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MembershipDiscount
     */
    applyToMemberOnly?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof MembershipDiscount
     */
    contactRelationTypes?: Array<number>;
}

  /**
 * 
 * @export
 * @interface MembershipProduct
 */
export interface MembershipProduct {
    /**
     * 
     * @type {number}
     * @memberof MembershipProduct
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MembershipProduct
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MembershipProduct
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof MembershipProduct
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof MembershipProduct
     */
    dataCollectionRuleId?: number;
    /**
     * 
     * @type {number}
     * @memberof MembershipProduct
     */
    feeExTax?: number;
    /**
     * 
     * @type {number}
     * @memberof MembershipProduct
     */
    totalFee?: number;
    /**
     * 
     * @type {number}
     * @memberof MembershipProduct
     */
    taxId?: number;
    /**
     * 
     * @type {ExpiryType}
     * @memberof MembershipProduct
     */
    expiryType?: ExpiryType;
    /**
     * 
     * @type {number}
     * @memberof MembershipProduct
     */
    expiryDays?: number;
    /**
     * 
     * @type {number}
     * @memberof MembershipProduct
     */
    incomeAccountId?: number;
    /**
     * 
     * @type {ProductStatus}
     * @memberof MembershipProduct
     */
    status?: ProductStatus;
    /**
     * 
     * @type {Array<MembershipCorporatePass>}
     * @memberof MembershipProduct
     */
    corporatePasses?: Array<MembershipCorporatePass>;
    /**
     * 
     * @type {Array<MembershipDiscount>}
     * @memberof MembershipProduct
     */
    membershipDiscounts?: Array<MembershipDiscount>;
    /**
     * 
     * @type {Array<Sale>}
     * @memberof MembershipProduct
     */
    relatedSellables?: Array<Sale>;
    /**
     * 
     * @type {string}
     * @memberof MembershipProduct
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof MembershipProduct
     */
    modifiedOn?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof MembershipProduct
     */
    tags?: Array<number>;
    /**
     * 
     * @type {Array<Document>}
     * @memberof MembershipProduct
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MembershipProduct
     */
    customFields?: { [key: string]: string; };
}

  /**
 * 
 * @export
 * @interface MergeData
 */
export interface MergeData {
    /**
     * set of attributes with differences between contacts
     * @type {Array<MergeLine>}
     * @memberof MergeData
     */
    mergeLines?: Array<MergeLine>;
    /**
     * set of information attributes for both contacts
     * @type {Array<InfoLine>}
     * @memberof MergeData
     */
    infoLines?: Array<InfoLine>;
}

  /**
 * 
 * @export
 * @interface MergeLine
 */
export interface MergeLine {
    /**
     * key for access to field in Contact, Student, Tutor entities or contact custom fields, should be used as a key for POST map parameter
     * @type {string}
     * @memberof MergeLine
     */
    key?: string;
    /**
     * displayable name of attribute
     * @type {string}
     * @memberof MergeLine
     */
    label?: string;
    /**
     * attribute value for first contact
     * @type {string}
     * @memberof MergeLine
     */
    a?: string;
    /**
     * attribute value for second contact
     * @type {string}
     * @memberof MergeLine
     */
    b?: string;
}

  /**
 * 
 * @export
 * @interface MergeRequest
 */
export interface MergeRequest {
    /**
     * contact A id for merge
     * @type {number}
     * @memberof MergeRequest
     */
    contactA?: number;
    /**
     * contact B id for merge
     * @type {number}
     * @memberof MergeRequest
     */
    contactB?: number;
    /**
     * map with property keys and choosed values, use symbols 'A' (contact A field choosed) or 'B' (contact B) for value
     * @type {{ [key: string]: string; }}
     * @memberof MergeRequest
     */
    data?: { [key: string]: string; };
}

  /**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {number}
     * @memberof Message
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Message
     */
    contactId?: number;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    htmlMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    sms?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    postDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    creatorKey?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    modifiedOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    sentToContactFullname?: string;
}

  /**
 * 
 * @export
 * @interface MessagePerson
 */
export interface MessagePerson {
    /**
     * 
     * @type {number}
     * @memberof MessagePerson
     */
    messageId?: number;
    /**
     * 
     * @type {string}
     * @memberof MessagePerson
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof MessagePerson
     */
    sentOn?: string;
    /**
     * 
     * @type {string}
     * @memberof MessagePerson
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof MessagePerson
     */
    creatorKey?: string;
    /**
     * 
     * @type {MessageStatus}
     * @memberof MessagePerson
     */
    status?: MessageStatus;
    /**
     * 
     * @type {MessageType}
     * @memberof MessagePerson
     */
    type?: MessageType;
}

  

export const MessageStatus = createStringEnum([
  'Queued',
  'Sent',
  'Failed',
]);

export type MessageStatus = keyof typeof MessageStatus;

  

export const MessageType = createStringEnum([
  'Email',
  'Sms',
  'Post',
]);

export type MessageType = keyof typeof MessageType;

  /**
 * 
 * @export
 * @interface Module
 */
export interface Module {
    /**
     * 
     * @type {number}
     * @memberof Module
     */
    creditPoints?: number;
    /**
     * 
     * @type {ModuleCreditPointsStatus}
     * @memberof Module
     */
    creditPointsStatus?: ModuleCreditPointsStatus;
    /**
     * 
     * @type {number}
     * @memberof Module
     */
    expiryDays?: number;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    fieldOfEducation?: string;
    /**
     * 
     * @type {number}
     * @memberof Module
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Module
     */
    isCustom?: boolean;
    /**
     * 
     * @type {ModuleType}
     * @memberof Module
     */
    type?: ModuleType;
    /**
     * 
     * @type {boolean}
     * @memberof Module
     */
    isOffered?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    nationalCode?: string;
    /**
     * 
     * @type {number}
     * @memberof Module
     */
    nominalHours?: number;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    specialization?: string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    modifiedOn?: string;
}

  

export const ModuleCreditPointsStatus = createStringEnum([
  'Active',
  'Active but not visible online',
  'Disabled',
]);

export type ModuleCreditPointsStatus = keyof typeof ModuleCreditPointsStatus;

  

export const ModuleType = createStringEnum([
  'UNIT OF COMPETENCY',
  'MODULE',
  'UNIT OF STUDY',
  'OTHER',
]);

export type ModuleType = keyof typeof ModuleType;

  

export const MoneyRounding = createStringEnum([
  'No Rounding',
  'Nearest 10 cents',
  'Nearest 50 cents',
  'Nearest dollar',
]);

export type MoneyRounding = keyof typeof MoneyRounding;

  /**
 * 
 * @export
 * @interface Note
 */
export interface Note {
    /**
     * 
     * @type {number}
     * @memberof Note
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    modified?: string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    interactionDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    modifiedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    entityName?: string;
    /**
     * 
     * @type {number}
     * @memberof Note
     */
    entityId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Note
     */
    readonly?: boolean;
}

  /**
 * 
 * @export
 * @interface Outcome
 */
export interface Outcome {
    /**
     * 
     * @type {number}
     * @memberof Outcome
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Outcome
     */
    contactId?: number;
    /**
     * 
     * @type {number}
     * @memberof Outcome
     */
    enrolmentId?: number;
    /**
     * 
     * @type {string}
     * @memberof Outcome
     */
    studentName?: string;
    /**
     * 
     * @type {number}
     * @memberof Outcome
     */
    moduleId?: number;
    /**
     * 
     * @type {string}
     * @memberof Outcome
     */
    moduleCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Outcome
     */
    moduleName?: string;
    /**
     * 
     * @type {string}
     * @memberof Outcome
     */
    trainingPlanStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Outcome
     */
    actualStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Outcome
     */
    startDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Outcome
     */
    startDateOverridden?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Outcome
     */
    trainingPlanEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Outcome
     */
    actualEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Outcome
     */
    endDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Outcome
     */
    endDateOverridden?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Outcome
     */
    reportableHours?: number;
    /**
     * 
     * @type {DeliveryMode}
     * @memberof Outcome
     */
    deliveryMode?: DeliveryMode;
    /**
     * 
     * @type {ClassFundingSource}
     * @memberof Outcome
     */
    fundingSource?: ClassFundingSource;
    /**
     * 
     * @type {OutcomeStatus}
     * @memberof Outcome
     */
    status?: OutcomeStatus;
    /**
     * 
     * @type {OutcomeProgression}
     * @memberof Outcome
     */
    progression?: OutcomeProgression;
    /**
     * 
     * @type {number}
     * @memberof Outcome
     */
    hoursAttended?: number;
    /**
     * 
     * @type {string}
     * @memberof Outcome
     */
    vetPurchasingContractID?: string;
    /**
     * 
     * @type {string}
     * @memberof Outcome
     */
    vetPurchasingContractScheduleID?: string;
    /**
     * 
     * @type {string}
     * @memberof Outcome
     */
    vetFundingSourceStateID?: string;
    /**
     * 
     * @type {string}
     * @memberof Outcome
     */
    specificProgramIdentifier?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Outcome
     */
    isPriorLearning?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Outcome
     */
    hasCertificate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Outcome
     */
    printed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Outcome
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Outcome
     */
    modifiedOn?: string;
}

  /**
 * 
 * @export
 * @interface OutcomeProgression
 */
export interface OutcomeProgression {
    /**
     * attendance future timetable hours
     * @type {number}
     * @memberof OutcomeProgression
     */
    futureTimetable?: number;
    /**
     * attendance not marked hours
     * @type {number}
     * @memberof OutcomeProgression
     */
    notMarked?: number;
    /**
     * attendance absent hours
     * @type {number}
     * @memberof OutcomeProgression
     */
    absent?: number;
    /**
     * attendance attended hours
     * @type {number}
     * @memberof OutcomeProgression
     */
    attended?: number;
    /**
     * not released assessments number
     * @type {number}
     * @memberof OutcomeProgression
     */
    notReleased?: number;
    /**
     * released assessments number
     * @type {number}
     * @memberof OutcomeProgression
     */
    released?: number;
    /**
     * submitted assessments number
     * @type {number}
     * @memberof OutcomeProgression
     */
    submitted?: number;
    /**
     * marked assessments number
     * @type {number}
     * @memberof OutcomeProgression
     */
    marked?: number;
}

  

export const OutcomeStatus = createStringEnum([
  'Not set',
  'Competency achieved/pass (20)',
  'Competency not achieved/fail (30)',
  'Withdrawn (40)',
  'Incomplete due to RTO closure (41)',
  'RPL granted (51)',
  'RPL not granted (52)',
  'Credit Transfer (60)',
  'Superseded Qualification (QLD 65)',
  'Did not start (NSW 66, SA @@)',
  'Continuing enrolment (70)',
  'Satisfactorily completed (81)',
  'Withdrawn or not satisfactorily completed (82)',
  'Result not available (90)',
  'Participating, but studies not finished (with evidence of Training) (WA 5)',
  'Participating, but studies not finished (With NO evidence of Training) (WA 55)',
  'Recognition of Current Competencies (RCC) granted (WA:15)',
  'Recognition of Current Competencies (RCC) not granted (WA:16)',
  'Provisionally competent off the job (apprentices only) (WA:8)',
  'Discontinued - no formal withdrawal (after some participation) (WA:11)',
  'Not yet started (WA:105)',
  'Superseded subject (61)',
]);

export type OutcomeStatus = keyof typeof OutcomeStatus;

  

export const OutputType = createStringEnum([
  'csv',
  'json',
  'xml',
  'ics',
  'txt',
  'pdf',
]);

export type OutputType = keyof typeof OutputType;

  /**
 * password complexity
 * @export
 * @interface PasswordComplexity
 */
export interface PasswordComplexity {
    /**
     * password complexity score
     * @type {number}
     * @memberof PasswordComplexity
     */
    score?: number;
    /**
     * feedback
     * @type {string}
     * @memberof PasswordComplexity
     */
    feedback?: string;
}

  /**
 * 
 * @export
 * @interface PayLine
 */
export interface PayLine {
    /**
     * 
     * @type {number}
     * @memberof PayLine
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PayLine
     */
    dateFor?: string;
    /**
     * 
     * @type {string}
     * @memberof PayLine
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PayLine
     */
    className?: string;
    /**
     * 
     * @type {ClassCostRepetitionType}
     * @memberof PayLine
     */
    type?: ClassCostRepetitionType;
    /**
     * 
     * @type {number}
     * @memberof PayLine
     */
    budgetedQuantity?: number;
    /**
     * 
     * @type {number}
     * @memberof PayLine
     */
    budgetedValue?: number;
    /**
     * 
     * @type {number}
     * @memberof PayLine
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof PayLine
     */
    value?: number;
}

      /**
    * Payment method defenition
    */


export const PayType = createStringEnum([
  'Other',
  'Cheque',
  'Credit card',
]);

export type PayType = keyof typeof PayType;

  /**
 * View's model entity to represent ish.oncourse.cayenne.PaymentInterface on client side
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    paymentId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Payment
     */
    reconciled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    paymentTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    paymentMethodName?: string;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    contactId?: number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    contactName?: string;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    paymentDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Payment
     */
    reconcilable?: boolean;
}

  /**
 * 
 * @export
 * @interface PaymentIn
 */
export interface PaymentIn {
    /**
     * 
     * @type {number}
     * @memberof PaymentIn
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentIn
     */
    payerId?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentIn
     */
    payerName?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentIn
     */
    paymentInType?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentIn
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentIn
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentIn
     */
    accountInName?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentIn
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentIn
     */
    ccTransaction?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentIn
     */
    emailConfirmation?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentIn
     */
    datePayed?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentIn
     */
    dateBanked?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaymentIn
     */
    ccSummary?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PaymentIn
     */
    chequeSummary?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof PaymentIn
     */
    createdBy?: string;
    /**
     * 
     * @type {Array<PaymentInvoice>}
     * @memberof PaymentIn
     */
    invoices?: Array<PaymentInvoice>;
    /**
     * 
     * @type {string}
     * @memberof PaymentIn
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentIn
     */
    modifiedOn?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentIn
     */
    administrationCenterId?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentIn
     */
    administrationCenterName?: string;
}

  /**
 * 
 * @export
 * @interface PaymentInvoice
 */
export interface PaymentInvoice {
    /**
     * 
     * @type {number}
     * @memberof PaymentInvoice
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentInvoice
     */
    dateDue?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentInvoice
     */
    invoiceNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentInvoice
     */
    amountOwing?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentInvoice
     */
    amount?: number;
}

  /**
 * 
 * @export
 * @interface PaymentMethod
 */
export interface PaymentMethod {
    /**
     * Unique Payment Type identifier
     * @type {number}
     * @memberof PaymentMethod
     */
    id?: number;
    /**
     * Payment type name
     * @type {string}
     * @memberof PaymentMethod
     */
    name?: string;
    /**
     * Is payment type internal
     * @type {boolean}
     * @memberof PaymentMethod
     */
    systemType?: boolean;
    /**
     * Is payment type active
     * @type {boolean}
     * @memberof PaymentMethod
     */
    active?: boolean;
    /**
     * Is payment type reconcilable
     * @type {boolean}
     * @memberof PaymentMethod
     */
    reconcilable?: boolean;
    /**
     * Is payment type banked automatically
     * @type {boolean}
     * @memberof PaymentMethod
     */
    bankedAuto?: boolean;
    /**
     * Account identifier
     * @type {number}
     * @memberof PaymentMethod
     */
    accountId?: number;
    /**
     * Undeposited funds account identifier
     * @type {number}
     * @memberof PaymentMethod
     */
    undepositAccountId?: number;
    /**
     * 
     * @type {PayType}
     * @memberof PaymentMethod
     */
    type?: PayType;
    /**
     * date and time when record was created
     * @type {string}
     * @memberof PaymentMethod
     */
    created?: string;
    /**
     * date and time when record was modified
     * @type {string}
     * @memberof PaymentMethod
     */
    modified?: string;
}

  /**
 * 
 * @export
 * @interface PaymentOut
 */
export interface PaymentOut {
    /**
     * 
     * @type {number}
     * @memberof PaymentOut
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentOut
     */
    payeeId?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentOut
     */
    payeeName?: string;
    /**
     * 
     * @type {PaymentType}
     * @memberof PaymentOut
     */
    type?: PaymentType;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PaymentOut
     */
    chequeSummary?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof PaymentOut
     */
    paymentMethodId?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentOut
     */
    refundableId?: number;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof PaymentOut
     */
    status?: PaymentStatus;
    /**
     * 
     * @type {number}
     * @memberof PaymentOut
     */
    accountOut?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentOut
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentOut
     */
    datePayed?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentOut
     */
    dateBanked?: string;
    /**
     * 
     * @type {Array<PaymentInvoice>}
     * @memberof PaymentOut
     */
    invoices?: Array<PaymentInvoice>;
    /**
     * 
     * @type {string}
     * @memberof PaymentOut
     */
    privateNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentOut
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentOut
     */
    modifiedOn?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentOut
     */
    createdBy?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentOut
     */
    administrationCenterId?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentOut
     */
    administrationCenterName?: string;
}

      /**
    * List of payment's sources.
    */


export const PaymentSource = createStringEnum([
  'office',
  'web',
]);

export type PaymentSource = keyof typeof PaymentSource;

  

export const PaymentStatus = createStringEnum([
  'Not processed',
  'Queued',
  'In transaction',
  'Success',
  'Failed',
  'Card declined',
  'Rejected - no places available',
  'Credit card details required',
]);

export type PaymentStatus = keyof typeof PaymentStatus;

  

export const PaymentType = createStringEnum([
  'Cash',
  'Cheque',
  'Credit card',
  'EFT',
  'B-Pay',
  'Zero',
  'Other',
  'Contra',
  'Voucher',
  'PayPal',
  'Reverse',
  'EFTPOS',
]);

export type PaymentType = keyof typeof PaymentType;

  /**
 * 
 * @export
 * @interface PayrollRequest
 */
export interface PayrollRequest {
    /**
     * 
     * @type {string}
     * @memberof PayrollRequest
     */
    untilDate?: string;
    /**
     * 
     * @type {string}
     * @memberof PayrollRequest
     */
    entityName?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof PayrollRequest
     */
    recordIds?: Array<number>;
}

  /**
 * 
 * @export
 * @interface Payslip
 */
export interface Payslip {
    /**
     * 
     * @type {number}
     * @memberof Payslip
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Payslip
     */
    publicNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof Payslip
     */
    privateNotes?: string;
    /**
     * 
     * @type {PayslipStatus}
     * @memberof Payslip
     */
    status?: PayslipStatus;
    /**
     * 
     * @type {PayslipPayType}
     * @memberof Payslip
     */
    payType?: PayslipPayType;
    /**
     * 
     * @type {number}
     * @memberof Payslip
     */
    tutorId?: number;
    /**
     * 
     * @type {string}
     * @memberof Payslip
     */
    tutorFullName?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof Payslip
     */
    tags?: Array<number>;
    /**
     * 
     * @type {Array<PayLine>}
     * @memberof Payslip
     */
    paylines?: Array<PayLine>;
    /**
     * 
     * @type {string}
     * @memberof Payslip
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Payslip
     */
    modifiedOn?: string;
}

  

export const PayslipPayType = createStringEnum([
  'Employee',
  'Contractor',
]);

export type PayslipPayType = keyof typeof PayslipPayType;

  /**
 * 
 * @export
 * @interface PayslipRequest
 */
export interface PayslipRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof PayslipRequest
     */
    ids?: Array<number>;
    /**
     * 
     * @type {PayslipStatus}
     * @memberof PayslipRequest
     */
    status?: PayslipStatus;
}

  

export const PayslipStatus = createStringEnum([
  'New',
  'Completed',
  'Approved',
  'Paid/Exported',
]);

export type PayslipStatus = keyof typeof PayslipStatus;

  /**
 * 
 * @export
 * @interface PermissionRequest
 */
export interface PermissionRequest {
    /**
     * Path to resource
     * @type {string}
     * @memberof PermissionRequest
     */
    path?: string;
    /**
     * Method name
     * @type {string}
     * @memberof PermissionRequest
     */
    method?: string;
    /**
     * Special action code
     * @type {KeyCode}
     * @memberof PermissionRequest
     */
    keyCode?: KeyCode;
}

  /**
 * 
 * @export
 * @interface PermissionResponse
 */
export interface PermissionResponse {
    /**
     * Result of access checking
     * @type {boolean}
     * @memberof PermissionResponse
     */
    hasAccess?: boolean;
    /**
     * Special field which contains additional info about permission checking
     * @type {string}
     * @memberof PermissionResponse
     */
    cause?: string;
}

      /**
    * List of user preferences
    */


export const PreferenceEnum = createStringEnum([
  'html.dashboard.category.width',
  'html.dashboard.favorite',
  'dashboard.scripts.favorite.id',
  'news.read',
  'html.global.theme',
  'google.analytics.cid',
  'google.analytics.ci',
  'google.analytics.cn',
  'google.analytics.uid',
  'timezone.default',
  'systemUser.defaultAdministrationCentre.name',
  'license.scripting',
  'license.access_control',
  'eula.last_access_date',
  'account.default.studentEnrolments.id',
  'account.default.voucherLiability.id',
  'account.default.voucherUnderpayment.id',
  'account.invoice.terms',
  'replication.enabled',
  'avetmiss.identifier',
  'email.from',
  'courseclass_default_deliveryMode',
  'courseclass_default_fundingSource',
  'courseclass_default_maximumPlaces',
  'courseclass_default_minimumPlaces',
  'listview.main.content.width',
  'oncourse.server.timezone.default',
  'tutorial.skip.systemuser',
  'background.quality.scale',
  'account.default.invoiceline.id',
  'payment.gateway.type',
  'ish.display.extendedSearchTypes',
]);

export type PreferenceEnum = keyof typeof PreferenceEnum;

  /**
 * 
 * @export
 * @interface PrintRequest
 */
export interface PrintRequest {
    /**
     * 
     * @type {string}
     * @memberof PrintRequest
     */
    search?: string;
    /**
     * 
     * @type {string}
     * @memberof PrintRequest
     */
    filter?: string;
    /**
     * 
     * @type {Array<TagGroup>}
     * @memberof PrintRequest
     */
    tagGroups?: Array<TagGroup>;
    /**
     * 
     * @type {Array<Sorting>}
     * @memberof PrintRequest
     */
    sorting?: Array<Sorting>;
    /**
     * 
     * @type {number}
     * @memberof PrintRequest
     */
    report?: number;
    /**
     * 
     * @type {number}
     * @memberof PrintRequest
     */
    overlay?: number;
    /**
     * report variables values
     * @type {{ [key: string]: string; }}
     * @memberof PrintRequest
     */
    variables?: { [key: string]: string; };
    /**
     * generate and save preview for report
     * @type {boolean}
     * @memberof PrintRequest
     */
    createPreview?: boolean;
    /**
     * for daemon pdf requests this parameter must be specified to sent the result to this address
     * @type {string}
     * @memberof PrintRequest
     */
    emailToSent?: string;
}

  /**
 * 
 * @export
 * @interface PriorLearning
 */
export interface PriorLearning {
    /**
     * 
     * @type {number}
     * @memberof PriorLearning
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PriorLearning
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof PriorLearning
     */
    modifiedOn?: string;
    /**
     * 
     * @type {string}
     * @memberof PriorLearning
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof PriorLearning
     */
    externalReference?: string;
    /**
     * 
     * @type {number}
     * @memberof PriorLearning
     */
    qualificationId?: number;
    /**
     * 
     * @type {string}
     * @memberof PriorLearning
     */
    qualificationNationalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PriorLearning
     */
    qualificationLevel?: string;
    /**
     * 
     * @type {string}
     * @memberof PriorLearning
     */
    qualificationName?: string;
    /**
     * 
     * @type {Array<Outcome>}
     * @memberof PriorLearning
     */
    outcomes?: Array<Outcome>;
    /**
     * 
     * @type {Array<Document>}
     * @memberof PriorLearning
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {string}
     * @memberof PriorLearning
     */
    notes?: string;
    /**
     * 
     * @type {number}
     * @memberof PriorLearning
     */
    contactId?: number;
    /**
     * 
     * @type {string}
     * @memberof PriorLearning
     */
    contactName?: string;
    /**
     * 
     * @type {string}
     * @memberof PriorLearning
     */
    outcomeIdTrainingOrg?: string;
}

  /**
 * 
 * @export
 * @interface ProcessResult
 */
export interface ProcessResult {
    /**
     * 
     * @type {ProcessStatus}
     * @memberof ProcessResult
     */
    status?: ProcessStatus;
    /**
     * 
     * @type {string}
     * @memberof ProcessResult
     */
    message?: string;
}

      /**
    * Status of the process
    */


export const ProcessStatus = createStringEnum([
  'Not found',
  'In progress',
  'Finished',
  'Failed',
]);

export type ProcessStatus = keyof typeof ProcessStatus;

  /**
 * 
 * @export
 * @interface ProductItem
 */
export interface ProductItem {
    /**
     * 
     * @type {number}
     * @memberof ProductItem
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductItem
     */
    productId?: number;
    /**
     * 
     * @type {ProductType}
     * @memberof ProductItem
     */
    productType?: ProductType;
    /**
     * 
     * @type {string}
     * @memberof ProductItem
     */
    productName?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductItem
     */
    purchasedById?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductItem
     */
    purchasedByName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductItem
     */
    purchasedOn?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductItem
     */
    expiresOn?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductItem
     */
    purchasePrice?: number;
    /**
     * 
     * @type {ProductItemStatus}
     * @memberof ProductItem
     */
    status?: ProductItemStatus;
    /**
     * 
     * @type {Array<ProductItemPayment>}
     * @memberof ProductItem
     */
    payments?: Array<ProductItemPayment>;
    /**
     * membership property
     * @type {string}
     * @memberof ProductItem
     */
    validFrom?: string;
    /**
     * voucher property
     * @type {string}
     * @memberof ProductItem
     */
    valueRemaining?: string;
    /**
     * voucher property
     * @type {string}
     * @memberof ProductItem
     */
    voucherCode?: string;
    /**
     * voucher property
     * @type {number}
     * @memberof ProductItem
     */
    redeemableById?: number;
    /**
     * voucher property
     * @type {string}
     * @memberof ProductItem
     */
    redeemableByName?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ProductItem
     */
    customFields?: { [key: string]: string; };
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductItem
     */
    tags?: Array<number>;
    /**
     * 
     * @type {Array<Document>}
     * @memberof ProductItem
     */
    documents?: Array<Document>;
}

  /**
 * 
 * @export
 * @interface ProductItemCancel
 */
export interface ProductItemCancel {
    /**
     * 
     * @type {number}
     * @memberof ProductItemCancel
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductItemCancel
     */
    createCrediNote?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductItemCancel
     */
    retainAdministrativeFee?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductItemCancel
     */
    feeAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductItemCancel
     */
    feeTaxId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductItemCancel
     */
    retainAccountId?: number;
}

  /**
 * View's model entity to represent payments for ProductItem
 * @export
 * @interface ProductItemPayment
 */
export interface ProductItemPayment {
    /**
     * 
     * @type {string}
     * @memberof ProductItemPayment
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductItemPayment
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductItemPayment
     */
    source?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductItemPayment
     */
    invoiceNo?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductItemPayment
     */
    amount?: number;
}

      /**
    * Status of the ProductItem
    */


export const ProductItemStatus = createStringEnum([
  'Active',
  'Cancelled',
  'Credited',
  'Redeemed',
  'New',
  'Expired',
  'Delivered',
]);

export type ProductItemStatus = keyof typeof ProductItemStatus;

      /**
    * Status of the process
    */


export const ProductStatus = createStringEnum([
  'Disabled',
  'Can be purchased in office',
  'Can be purchased in office & online',
]);

export type ProductStatus = keyof typeof ProductStatus;

      /**
    * Status of the process
    */


export const ProductType = createStringEnum([
  'Product',
  'Membership',
  'Voucher',
]);

export type ProductType = keyof typeof ProductType;

  /**
 * 
 * @export
 * @interface Qualification
 */
export interface Qualification {
    /**
     * 
     * @type {number}
     * @memberof Qualification
     */
    id?: number;
    /**
     * 
     * @type {QualificationType}
     * @memberof Qualification
     */
    type?: QualificationType;
    /**
     * 
     * @type {string}
     * @memberof Qualification
     */
    nationalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Qualification
     */
    qualLevel?: string;
    /**
     * 
     * @type {string}
     * @memberof Qualification
     */
    title?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Qualification
     */
    isOffered?: boolean;
    /**
     * the NCVER 'Subject field of education' identifer code
     * @type {string}
     * @memberof Qualification
     */
    fieldOfEducation?: string;
    /**
     * 
     * @type {string}
     * @memberof Qualification
     */
    anzsco?: string;
    /**
     * 
     * @type {string}
     * @memberof Qualification
     */
    newApprenticeship?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Qualification
     */
    isCustom?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Qualification
     */
    nominalHours?: number;
    /**
     * 
     * @type {string}
     * @memberof Qualification
     */
    reviewDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Qualification
     */
    specialization?: string;
    /**
     * 
     * @type {string}
     * @memberof Qualification
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Qualification
     */
    modifiedOn?: string;
}

      /**
    * qualification type
    */


export const QualificationType = createStringEnum([
  'Qualification',
  'Accredited course',
  'Skill set',
  'Local skill set',
  'Higher education',
]);

export type QualificationType = keyof typeof QualificationType;

  /**
 * array size of contacts to send, suppressed to messaging, without destination
 * @export
 * @interface RecipientType
 */
export interface RecipientType {
    /**
     * 
     * @type {Array<number>}
     * @memberof RecipientType
     */
    sendIds?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof RecipientType
     */
    suppressToSendIds?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof RecipientType
     */
    withoutDestinationIds?: Array<number>;
}

  /**
 * arrays size of recipient types
 * @export
 * @interface Recipients
 */
export interface Recipients {
    /**
     * 
     * @type {RecipientType}
     * @memberof Recipients
     */
    students?: RecipientType;
    /**
     * 
     * @type {RecipientType}
     * @memberof Recipients
     */
    activeStudents?: RecipientType;
    /**
     * 
     * @type {RecipientType}
     * @memberof Recipients
     */
    withdrawnStudents?: RecipientType;
    /**
     * 
     * @type {RecipientType}
     * @memberof Recipients
     */
    tutors?: RecipientType;
    /**
     * 
     * @type {RecipientType}
     * @memberof Recipients
     */
    other?: RecipientType;
}

      /**
    * Reconciled status
    */


export const ReconciledStatus = createStringEnum([
  'Yes',
  'No',
  'Partially',
  'N/A',
]);

export type ReconciledStatus = keyof typeof ReconciledStatus;

  /**
 * 
 * @export
 * @interface RefundInvoice
 */
export interface RefundInvoice {
    /**
     * 
     * @type {number}
     * @memberof RefundInvoice
     */
    invoiceLineId?: number;
    /**
     * 
     * @type {number}
     * @memberof RefundInvoice
     */
    accountId?: number;
    /**
     * 
     * @type {number}
     * @memberof RefundInvoice
     */
    taxId?: number;
    /**
     * 
     * @type {number}
     * @memberof RefundInvoice
     */
    cancellationFee?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RefundInvoice
     */
    sendInvoice?: boolean;
}

      /**
    * List of repeat end types
    */


export const RepeatEndEnum = createStringEnum([
  'never',
  'after',
  'onDate',
]);

export type RepeatEndEnum = keyof typeof RepeatEndEnum;

      /**
    * List of repeat types
    */


export const RepeatEnum = createStringEnum([
  'none',
  'hour',
  'day',
  'week',
  'month',
  'year',
]);

export type RepeatEnum = keyof typeof RepeatEnum;

  /**
 * report object
 * @export
 * @interface Report
 */
export interface Report {
    /**
     * 
     * @type {number}
     * @memberof Report
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    entity?: string;
    /**
     * Automation status
     * @type {AutomationStatus}
     * @memberof Report
     */
    status?: AutomationStatus;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    keyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    body?: string;
    /**
     * 
     * @type {number}
     * @memberof Report
     */
    backgroundId?: number;
    /**
     * sorting attribyte
     * @type {string}
     * @memberof Report
     */
    sortOn?: string;
    /**
     * image byte array
     * @type {string}
     * @memberof Report
     */
    preview?: string;
    /**
     * 
     * @type {Array<Binding>}
     * @memberof Report
     */
    variables?: Array<Binding>;
    /**
     * 
     * @type {Array<Binding>}
     * @memberof Report
     */
    options?: Array<Binding>;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    modifiedOn?: string;
    /**
     * Message template short description
     * @type {string}
     * @memberof Report
     */
    shortDescription?: string;
    /**
     * automation tags separated by commas
     * @type {string}
     * @memberof Report
     */
    automationTags?: string;
    /**
     * automation category
     * @type {string}
     * @memberof Report
     */
    category?: string;
}

  /**
 * Report PDF backgrounds
 * @export
 * @interface ReportOverlay
 */
export interface ReportOverlay {
    /**
     * 
     * @type {number}
     * @memberof ReportOverlay
     */
    id?: number;
    /**
     * the name of the pdf background
     * @type {string}
     * @memberof ReportOverlay
     */
    name?: string;
    /**
     * image byte array
     * @type {string}
     * @memberof ReportOverlay
     */
    preview?: string;
}

  /**
 * 
 * @export
 * @interface Room
 */
export interface Room {
    /**
     * 
     * @type {number}
     * @memberof Room
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Room
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Room
     */
    seatedCapacity?: number;
    /**
     * 
     * @type {number}
     * @memberof Room
     */
    siteId?: number;
    /**
     * 
     * @type {string}
     * @memberof Room
     */
    siteTimeZone?: string;
    /**
     * The kiosk url generates a HTML page showing showing the current days classes in this room
     * @type {string}
     * @memberof Room
     */
    kioskUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Room
     */
    directions?: string;
    /**
     * 
     * @type {string}
     * @memberof Room
     */
    facilities?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof Room
     */
    tags?: Array<number>;
    /**
     * 
     * @type {Array<Document>}
     * @memberof Room
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {Array<Holiday>}
     * @memberof Room
     */
    rules?: Array<Holiday>;
    /**
     * 
     * @type {string}
     * @memberof Room
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Room
     */
    modifiedOn?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Room
     */
    customFields?: { [key: string]: string; };
}

  /**
 * 
 * @export
 * @interface Sale
 */
export interface Sale {
    /**
     * 
     * @type {number}
     * @memberof Sale
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Sale
     */
    active?: boolean;
    /**
     * 
     * @type {SaleType}
     * @memberof Sale
     */
    type?: SaleType;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    expiryDate?: string;
    /**
     * 
     * @type {number}
     * @memberof Sale
     */
    entityFromId?: number;
    /**
     * 
     * @type {number}
     * @memberof Sale
     */
    entityToId?: number;
    /**
     * 
     * @type {number}
     * @memberof Sale
     */
    relationId?: number;
}

      /**
    * Status of the process
    */


export const SaleType = createStringEnum([
  'Product',
  'Membership',
  'Voucher',
  'Class',
  'Course',
  'Module',
  'Qualification',
]);

export type SaleType = keyof typeof SaleType;

  /**
 * 
 * @export
 * @interface Schedule
 */
export interface Schedule {
    /**
     * cron type
     * @type {ScheduleType}
     * @memberof Schedule
     */
    scheduleType?: ScheduleType;
    /**
     * custom cron value
     * @type {string}
     * @memberof Schedule
     */
    custom?: string;
}

      /**
    * list of crons
    */


export const ScheduleType = createStringEnum([
  'Daily morning (about 6am)',
  'Daily evening (about 8pm)',
  'Weekly (Monday about 7am)',
  'Hourly',
  'Custom',
]);

export type ScheduleType = keyof typeof ScheduleType;

  /**
 * script object
 * @export
 * @interface Script
 */
export interface Script {
    /**
     * unique identifier
     * @type {number}
     * @memberof Script
     */
    id?: number;
    /**
     * script unique identifier, all built-in script codes start with 'ish.oncourse'
     * @type {string}
     * @memberof Script
     */
    keyCode?: string;
    /**
     * Script entity class
     * @type {string}
     * @memberof Script
     */
    entity?: string;
    /**
     * Script name
     * @type {string}
     * @memberof Script
     */
    name?: string;
    /**
     * Script description
     * @type {string}
     * @memberof Script
     */
    description?: string;
    /**
     * Automation status
     * @type {AutomationStatus}
     * @memberof Script
     */
    status?: AutomationStatus;
    /**
     * Script trigger
     * @type {ScriptTrigger}
     * @memberof Script
     */
    trigger?: ScriptTrigger;
    /**
     * Script content
     * @type {string}
     * @memberof Script
     */
    content?: string;
    /**
     * output file type which create by script
     * @type {OutputType}
     * @memberof Script
     */
    outputType?: OutputType;
    /**
     * 
     * @type {Array<LastRun>}
     * @memberof Script
     */
    lastRun?: Array<LastRun>;
    /**
     * date and time when record was created
     * @type {string}
     * @memberof Script
     */
    createdOn?: string;
    /**
     * date and time when record was modified
     * @type {string}
     * @memberof Script
     */
    modifiedOn?: string;
    /**
     * Script variables list
     * @type {Array<Binding>}
     * @memberof Script
     */
    variables?: Array<Binding>;
    /**
     * Script options list
     * @type {Array<Binding>}
     * @memberof Script
     */
    options?: Array<Binding>;
    /**
     * Message template short description
     * @type {string}
     * @memberof Script
     */
    shortDescription?: string;
    /**
     * automation tags separated by commas
     * @type {string}
     * @memberof Script
     */
    automationTags?: string;
    /**
     * automation category
     * @type {string}
     * @memberof Script
     */
    category?: string;
}

  /**
 * 
 * @export
 * @interface ScriptTrigger
 */
export interface ScriptTrigger {
    /**
     * trigger type and name
     * @type {TriggerType}
     * @memberof ScriptTrigger
     */
    type?: TriggerType;
    /**
     * entity name
     * @type {string}
     * @memberof ScriptTrigger
     */
    entityName?: string;
    /**
     * entity attribute
     * @type {string}
     * @memberof ScriptTrigger
     */
    entityAttribute?: string;
    /**
     * id of parameter, which can be useful during script execution or check
     * @type {number}
     * @memberof ScriptTrigger
     */
    parameterId?: number;
    /**
     * cron
     * @type {Schedule}
     * @memberof ScriptTrigger
     */
    cron?: Schedule;
}

  /**
 * Dashboard search matched Entity items group
 * @export
 * @interface SearchGroup
 */
export interface SearchGroup {
    /**
     * item Entity name
     * @type {string}
     * @memberof SearchGroup
     */
    entity?: string;
    /**
     * 
     * @type {Array<SearchItem>}
     * @memberof SearchGroup
     */
    items?: Array<SearchItem>;
}

  /**
 * Dashboard search matched Entity item
 * @export
 * @interface SearchItem
 */
export interface SearchItem {
    /**
     * item id
     * @type {number}
     * @memberof SearchItem
     */
    id?: number;
    /**
     * matched Entity item name
     * @type {string}
     * @memberof SearchItem
     */
    name?: string;
}

  /**
 * 
 * @export
 * @interface SearchQuery
 */
export interface SearchQuery {
    /**
     * a seach query to filter results. Entities that match this query are returned
     * @type {string}
     * @memberof SearchQuery
     */
    search?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchQuery
     */
    filter?: string;
    /**
     * returned list size
     * @type {number}
     * @memberof SearchQuery
     */
    pageSize?: number;
    /**
     * selected list offset
     * @type {number}
     * @memberof SearchQuery
     */
    offset?: number;
    /**
     * 
     * @type {Array<TagGroup>}
     * @memberof SearchQuery
     */
    tagGroups?: Array<TagGroup>;
    /**
     * 
     * @type {Array<TagGroup>}
     * @memberof SearchQuery
     */
    uncheckedChecklists?: Array<TagGroup>;
    /**
     * If we need not standard table model for entity then this param can be specified
     * @type {string}
     * @memberof SearchQuery
     */
    customTableModel?: string;
}

  /**
 * searhe request object
 * @export
 * @interface SearchRequest
 */
export interface SearchRequest {
    /**
     * Search sessions for canceled classes
     * @type {boolean}
     * @memberof SearchRequest
     */
    canceled?: boolean;
    /**
     * AQL search expression
     * @type {string}
     * @memberof SearchRequest
     */
    search?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchRequest
     */
    filter?: string;
    /**
     * Date/time in UTC ISO-8601 format. Looking for sessions starting from certain point of time. Start of this day by default
     * @type {string}
     * @memberof SearchRequest
     */
    from?: string;
    /**
     * Date/time in UTC ISO-8601 format. Looking for sessions starting up till certain point of time.
     * @type {string}
     * @memberof SearchRequest
     */
    to?: string;
}

  /**
 * 
 * @export
 * @interface SendMessageRequest
 */
export interface SendMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof SendMessageRequest
     */
    entity?: string;
    /**
     * 
     * @type {number}
     * @memberof SendMessageRequest
     */
    templateId?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SendMessageRequest
     */
    variables?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof SendMessageRequest
     */
    fromAddress?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SendMessageRequest
     */
    sendToStudents?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SendMessageRequest
     */
    sendToSuppressStudents?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SendMessageRequest
     */
    sendToActiveStudents?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SendMessageRequest
     */
    sendToSuppressActiveStudents?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SendMessageRequest
     */
    sendToWithdrawnStudents?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SendMessageRequest
     */
    sendToSuppressWithdrawnStudents?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SendMessageRequest
     */
    sendToTutors?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SendMessageRequest
     */
    sendToSuppressTutors?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SendMessageRequest
     */
    sendToOtherContacts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SendMessageRequest
     */
    sendToSuppressOtherContacts?: boolean;
    /**
     * 
     * @type {SearchQuery}
     * @memberof SendMessageRequest
     */
    searchQuery?: SearchQuery;
}

  /**
 * timetable session object
 * @export
 * @interface Session
 */
export interface Session {
    /**
     * unique identifier of record in onCourse
     * @type {number}
     * @memberof Session
     */
    id?: number;
    /**
     * temporary unique identifier of record created on client
     * @type {string}
     * @memberof Session
     */
    temporaryId?: string;
    /**
     * Course name
     * @type {string}
     * @memberof Session
     */
    name?: string;
    /**
     * Class code
     * @type {string}
     * @memberof Session
     */
    code?: string;
    /**
     * Room name
     * @type {string}
     * @memberof Session
     */
    room?: string;
    /**
     * Site name
     * @type {string}
     * @memberof Session
     */
    site?: string;
    /**
     * Tutor's names
     * @type {Array<string>}
     * @memberof Session
     */
    tutors?: Array<string>;
    /**
     * 
     * @type {Array<TutorAttendance>}
     * @memberof Session
     */
    tutorAttendances?: Array<TutorAttendance>;
    /**
     * unique identifier of course class record in onCourse
     * @type {number}
     * @memberof Session
     */
    classId?: number;
    /**
     * need for clash validation only (validate new class sessions by course unavailable rule criteria)
     * @type {number}
     * @memberof Session
     */
    courseId?: number;
    /**
     * Room id
     * @type {number}
     * @memberof Session
     */
    roomId?: number;
    /**
     * Site id
     * @type {number}
     * @memberof Session
     */
    siteId?: number;
    /**
     * Site timezone
     * @type {string}
     * @memberof Session
     */
    siteTimezone?: string;
    /**
     * Session start date/time in UTC ISO-8601 format
     * @type {string}
     * @memberof Session
     */
    start?: string;
    /**
     * Session end date/time in UTC ISO-8601 format
     * @type {string}
     * @memberof Session
     */
    end?: string;
    /**
     * session public notes
     * @type {string}
     * @memberof Session
     */
    publicNotes?: string;
    /**
     * session private notes
     * @type {string}
     * @memberof Session
     */
    privateNotes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Session
     */
    hasPaylines?: boolean;
}

  /**
 * 
 * @export
 * @interface SessionStatus
 */
export interface SessionStatus {
    /**
     * The transaction has finished being processed.
     * @type {boolean}
     * @memberof SessionStatus
     */
    complete?: boolean;
    /**
     * The transaction was successfully authorised.
     * @type {boolean}
     * @memberof SessionStatus
     */
    authorised?: boolean;
    /**
     * Human readable response, for example the error reason.
     * @type {string}
     * @memberof SessionStatus
     */
    responseText?: string;
}

  /**
 * timetable session object
 * @export
 * @interface SessionWarning
 */
export interface SessionWarning {
    /**
     * unique identifier of session record
     * @type {number}
     * @memberof SessionWarning
     */
    sessionId?: number;
    /**
     * temporary unique identifier of record ddi not persisted yet
     * @type {string}
     * @memberof SessionWarning
     */
    temporaryId?: string;
    /**
     * 
     * @type {ClashType}
     * @memberof SessionWarning
     */
    type?: ClashType;
    /**
     * unique identifier of record in onCourse
     * @type {number}
     * @memberof SessionWarning
     */
    referenceId?: number;
    /**
     * 
     * @type {string}
     * @memberof SessionWarning
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionWarning
     */
    message?: string;
}

  /**
 * 
 * @export
 * @interface Site
 */
export interface Site {
    /**
     * 
     * @type {number}
     * @memberof Site
     */
    id?: number;
    /**
     * True if this is an Administration Centre
     * @type {boolean}
     * @memberof Site
     */
    isAdministrationCentre?: boolean;
    /**
     * Sites can be virtual for online learning. True if this this a virtual site
     * @type {boolean}
     * @memberof Site
     */
    isVirtual?: boolean;
    /**
     * True if the details of this site is shown on website
     * @type {boolean}
     * @memberof Site
     */
    isShownOnWeb?: boolean;
    /**
     * The kiosk url generates a HTML page showing showing the current days classes in this site
     * @type {string}
     * @memberof Site
     */
    kioskUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    suburb?: string;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    postcode?: string;
    /**
     * 
     * @type {Country}
     * @memberof Site
     */
    country?: Country;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    timezone?: string;
    /**
     * 
     * @type {number}
     * @memberof Site
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof Site
     */
    latitude?: number;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    drivingDirections?: string;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    publicTransportDirections?: string;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    specialInstructions?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof Site
     */
    tags?: Array<number>;
    /**
     * 
     * @type {Array<Room>}
     * @memberof Site
     */
    rooms?: Array<Room>;
    /**
     * 
     * @type {Array<Document>}
     * @memberof Site
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {Array<Holiday>}
     * @memberof Site
     */
    rules?: Array<Holiday>;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    modifiedOn?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Site
     */
    customFields?: { [key: string]: string; };
}

  /**
 * 
 * @export
 * @interface Sorting
 */
export interface Sorting {
    /**
     * Any sortable entity attribute
     * @type {string}
     * @memberof Sorting
     */
    attribute?: string;
    /**
     * is sorting direction ascending
     * @type {boolean}
     * @memberof Sorting
     */
    ascending?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Sorting
     */
    complexAttribute?: Array<string>;
}

  

export const Source = createStringEnum([
  'office',
  'web',
]);

export type Source = keyof typeof Source;

  /**
 * 
 * @export
 * @interface SpecialTag
 */
export interface SpecialTag {
    /**
     * 
     * @type {SpecialTagType}
     * @memberof SpecialTag
     */
    specialType?: SpecialTagType;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof SpecialTag
     */
    childTags?: Array<Tag>;
}

      /**
    * special tag types, tags with that can be created on client
    */


export const SpecialTagType = createStringEnum([
  'Class extended types',
  'Course extended types',
]);

export type SpecialTagType = keyof typeof SpecialTagType;

  /**
 * Dashboard statistics object
 * @export
 * @interface StatisticData
 */
export interface StatisticData {
    /**
     * Enrolments chart line data
     * @type {Array<number>}
     * @memberof StatisticData
     */
    enrolmentsChartLine?: Array<number>;
    /**
     * Revenue chart line data
     * @type {Array<number>}
     * @memberof StatisticData
     */
    revenueChartLine?: Array<number>;
    /**
     * Total students enrolmemnts
     * @type {number}
     * @memberof StatisticData
     */
    studentsCount?: number;
    /**
     * Total students enrolmemnts cost
     * @type {number}
     * @memberof StatisticData
     */
    moneyCount?: number;
    /**
     * 
     * @type {Array<StatisticItem>}
     * @memberof StatisticData
     */
    latestEnrolments?: Array<StatisticItem>;
    /**
     * 
     * @type {Array<StatisticItem>}
     * @memberof StatisticData
     */
    latestWaitingLists?: Array<StatisticItem>;
    /**
     * 
     * @type {number}
     * @memberof StatisticData
     */
    openedClasses?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticData
     */
    inDevelopmentClasses?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticData
     */
    cancelledClasses?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticData
     */
    completedClasses?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticData
     */
    commencedClasses?: number;
}

  /**
 * 
 * @export
 * @interface StatisticItem
 */
export interface StatisticItem {
    /**
     * 
     * @type {string}
     * @memberof StatisticItem
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof StatisticItem
     */
    info?: string;
    /**
     * 
     * @type {string}
     * @memberof StatisticItem
     */
    link?: string;
}

  /**
 * 
 * @export
 * @interface Student
 */
export interface Student {
    /**
     * 
     * @type {number}
     * @memberof Student
     */
    id?: number;
    /**
     * 
     * @type {Country}
     * @memberof Student
     */
    countryOfBirth?: Country;
    /**
     * 
     * @type {AvetmissStudentDisabilityType}
     * @memberof Student
     */
    disabilityType?: AvetmissStudentDisabilityType;
    /**
     * 
     * @type {AvetmissStudentLabourStatus}
     * @memberof Student
     */
    labourForceStatus?: AvetmissStudentLabourStatus;
    /**
     * 
     * @type {AvetmissStudentEnglishProficiency}
     * @memberof Student
     */
    englishProficiency?: AvetmissStudentEnglishProficiency;
    /**
     * 
     * @type {AvetmissStudentSchoolLevel}
     * @memberof Student
     */
    highestSchoolLevel?: AvetmissStudentSchoolLevel;
    /**
     * 
     * @type {AvetmissStudentIndigenousStatus}
     * @memberof Student
     */
    indigenousStatus?: AvetmissStudentIndigenousStatus;
    /**
     * 
     * @type {boolean}
     * @memberof Student
     */
    isOverseasClient?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Student
     */
    isStillAtSchool?: boolean;
    /**
     * 
     * @type {Language}
     * @memberof Student
     */
    language?: Language;
    /**
     * 
     * @type {AvetmissStudentPriorEducation}
     * @memberof Student
     */
    priorEducationCode?: AvetmissStudentPriorEducation;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    specialNeeds?: string;
    /**
     * 
     * @type {number}
     * @memberof Student
     */
    yearSchoolCompleted?: number;
    /**
     * 
     * @type {number}
     * @memberof Student
     */
    studentNumber?: number;
    /**
     * 
     * @type {Country}
     * @memberof Student
     */
    countryOfResidency?: Country;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    visaNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    visaType?: string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    visaExpiryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    passportNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    medicalInsurance?: string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    uniqueLearnerIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    usi?: string;
    /**
     * 
     * @type {UsiStatus}
     * @memberof Student
     */
    usiStatus?: UsiStatus;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    chessn?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Student
     */
    feeHelpEligible?: boolean;
    /**
     * 
     * @type {StudentCitizenship}
     * @memberof Student
     */
    citizenship?: StudentCitizenship;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    townOfBirth?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Student
     */
    specialNeedsAssistance?: boolean;
    /**
     * 
     * @type {ClientIndustryEmploymentType}
     * @memberof Student
     */
    clientIndustryEmployment?: ClientIndustryEmploymentType;
    /**
     * 
     * @type {ClientOccupationIdentifierType}
     * @memberof Student
     */
    clientOccupationIdentifier?: ClientOccupationIdentifierType;
    /**
     * 
     * @type {Array<string>}
     * @memberof Student
     */
    waitingLists?: Array<string>;
    /**
     * 
     * @type {Array<StudentConcession>}
     * @memberof Student
     */
    concessions?: Array<StudentConcession>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Student
     */
    customFields?: { [key: string]: string; };
}

  /**
 * 
 * @export
 * @interface StudentAttendance
 */
export interface StudentAttendance {
    /**
     * 
     * @type {number}
     * @memberof StudentAttendance
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof StudentAttendance
     */
    sessionId?: number;
    /**
     * 
     * @type {number}
     * @memberof StudentAttendance
     */
    contactId?: number;
    /**
     * 
     * @type {string}
     * @memberof StudentAttendance
     */
    contactName?: string;
    /**
     * 
     * @type {AttendanceType}
     * @memberof StudentAttendance
     */
    attendanceType?: AttendanceType;
    /**
     * 
     * @type {string}
     * @memberof StudentAttendance
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentAttendance
     */
    attendedFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof StudentAttendance
     */
    attendedUntil?: string;
}

      /**
    * A set of values for describing student's citizenship.
    */


export const StudentCitizenship = createStringEnum([
  'Australian citizen',
  'New Zealand citizen',
  'Students/Applicants with permanent visa',
  'Student/Applicant has a temporary entry permit',
  'Not one of the above categories',
  'Students/Applicants with permanent humanitarian visa',
  'No information',
  'Students/Applicants with pacific engagement visa',
]);

export type StudentCitizenship = keyof typeof StudentCitizenship;

  /**
 * 
 * @export
 * @interface StudentConcession
 */
export interface StudentConcession {
    /**
     * student concession identifier
     * @type {number}
     * @memberof StudentConcession
     */
    id?: number;
    /**
     * concession number
     * @type {string}
     * @memberof StudentConcession
     */
    number?: string;
    /**
     * type of student concession
     * @type {ConcessionType}
     * @memberof StudentConcession
     */
    type?: ConcessionType;
    /**
     * date and time when concession expires
     * @type {string}
     * @memberof StudentConcession
     */
    expiresOn?: string;
    /**
     * date and time when record was created
     * @type {string}
     * @memberof StudentConcession
     */
    created?: string;
    /**
     * date and time when record was modified
     * @type {string}
     * @memberof StudentConcession
     */
    modified?: string;
}

  /**
 * 
 * @export
 * @interface SurveyItem
 */
export interface SurveyItem {
    /**
     * 
     * @type {number}
     * @memberof SurveyItem
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof SurveyItem
     */
    studentContactId?: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyItem
     */
    studentName?: string;
    /**
     * 
     * @type {number}
     * @memberof SurveyItem
     */
    netPromoterScore?: number;
    /**
     * 
     * @type {number}
     * @memberof SurveyItem
     */
    venueScore?: number;
    /**
     * 
     * @type {number}
     * @memberof SurveyItem
     */
    courseScore?: number;
    /**
     * 
     * @type {number}
     * @memberof SurveyItem
     */
    tutorScore?: number;
    /**
     * 
     * @type {SurveyVisibility}
     * @memberof SurveyItem
     */
    visibility?: SurveyVisibility;
    /**
     * 
     * @type {string}
     * @memberof SurveyItem
     */
    testimonial?: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyItem
     */
    comment?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SurveyItem
     */
    customFields?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof SurveyItem
     */
    siteId?: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyItem
     */
    siteName?: string;
    /**
     * 
     * @type {number}
     * @memberof SurveyItem
     */
    roomId?: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyItem
     */
    roomName?: string;
    /**
     * 
     * @type {number}
     * @memberof SurveyItem
     */
    classId?: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyItem
     */
    className?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SurveyItem
     */
    tutors?: { [key: string]: string; };
}

  

export const SurveyVisibility = createStringEnum([
  'Waiting review',
  'Public testimonial',
  'Not testimonial',
  'Hidden by student',
]);

export type SurveyVisibility = keyof typeof SurveyVisibility;

  /**
 * 
 * @export
 * @interface SystemPreference
 */
export interface SystemPreference {
    /**
     * Unique ID of preference
     * @type {string}
     * @memberof SystemPreference
     */
    uniqueKey?: string;
    /**
     * Unigue Key of preference
     * @type {string}
     * @memberof SystemPreference
     */
    valueString?: string;
    /**
     * date and time when record was created
     * @type {string}
     * @memberof SystemPreference
     */
    created?: string;
    /**
     * date and time when record was modified
     * @type {string}
     * @memberof SystemPreference
     */
    modified?: string;
}

  /**
 * 
 * @export
 * @interface TableModel
 */
export interface TableModel {
    /**
     * 
     * @type {Array<Sorting>}
     * @memberof TableModel
     */
    sortings?: Array<Sorting>;
    /**
     * 
     * @type {Array<Column>}
     * @memberof TableModel
     */
    columns?: Array<Column>;
    /**
     * type of table layout
     * @type {LayoutType}
     * @memberof TableModel
     */
    layout?: LayoutType;
    /**
     * width of filter column in view in px
     * @type {number}
     * @memberof TableModel
     */
    filterColumnWidth?: number;
    /**
     * order of tags
     * @type {Array<number>}
     * @memberof TableModel
     */
    tagsOrder?: Array<number>;
}

  /**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * Unique identifier for user
     * @type {number}
     * @memberof Tag
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    name?: string;
    /**
     * 
     * @type {TagStatus}
     * @memberof Tag
     */
    status?: TagStatus;
    /**
     * 
     * @type {TagType}
     * @memberof Tag
     */
    type?: TagType;
    /**
     * 
     * @type {boolean}
     * @memberof Tag
     */
    system?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    urlPath?: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    color?: string;
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    weight?: number;
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    taggedRecordsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    childrenCount?: number;
    /**
     * date and time when record was created
     * @type {string}
     * @memberof Tag
     */
    created?: string;
    /**
     * date and time when record was modified
     * @type {string}
     * @memberof Tag
     */
    modified?: string;
    /**
     * 
     * @type {Array<TagRequirement>}
     * @memberof Tag
     */
    requirements?: Array<TagRequirement>;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Tag
     */
    childTags?: Array<Tag>;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    shortWebDescription?: string;
}

  /**
 * 
 * @export
 * @interface TagGroup
 */
export interface TagGroup {
    /**
     * 
     * @type {Array<number>}
     * @memberof TagGroup
     */
    tagIds?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof TagGroup
     */
    entity?: string;
    /**
     * 
     * @type {string}
     * @memberof TagGroup
     */
    path?: string;
}

  /**
 * 
 * @export
 * @interface TagRequirement
 */
export interface TagRequirement {
    /**
     * Unique identifier
     * @type {number}
     * @memberof TagRequirement
     */
    id?: number;
    /**
     * type
     * @type {TagRequirementType}
     * @memberof TagRequirement
     */
    type?: TagRequirementType;
    /**
     * 
     * @type {boolean}
     * @memberof TagRequirement
     */
    mandatory?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TagRequirement
     */
    limitToOneTag?: boolean;
    /**
     * True if tag shows on website
     * @type {boolean}
     * @memberof TagRequirement
     */
    system?: boolean;
    /**
     * aql display rule of checklists, related to entity of this type
     * @type {string}
     * @memberof TagRequirement
     */
    displayRule?: string;
}

      /**
    * list of types for tag requirements
    */


export const TagRequirementType = createStringEnum([
  'Application',
  'Assessment',
  'Contact',
  'Course',
  'Document',
  'Enrolment',
  'Invoice',
  'Lead',
  'Payslip',
  'Room',
  'Site',
  'Student',
  'Tutor',
  'WaitingList',
  'CourseClass',
  'Article',
  'Voucher',
  'Membership',
  'ArticleProduct',
  'VoucherProduct',
  'MembershipProduct',
  'Faculty',
]);

export type TagRequirementType = keyof typeof TagRequirementType;

      /**
    * tag types
    */


export const TagStatus = createStringEnum([
  'Private',
  'Show on website',
]);

export type TagStatus = keyof typeof TagStatus;

      /**
    * tag types
    */


export const TagType = createStringEnum([
  'Tag',
  'Checklist',
]);

export type TagType = keyof typeof TagType;

  /**
 * 
 * @export
 * @interface Tax
 */
export interface Tax {
    /**
     * Unique Tax identifier
     * @type {number}
     * @memberof Tax
     */
    id?: number;
    /**
     * Tax code
     * @type {string}
     * @memberof Tax
     */
    code?: string;
    /**
     * Is tax can be edited
     * @type {boolean}
     * @memberof Tax
     */
    editable?: boolean;
    /**
     * Is internal tax
     * @type {boolean}
     * @memberof Tax
     */
    systemType?: boolean;
    /**
     * Is GST tax type
     * @type {boolean}
     * @memberof Tax
     */
    gst?: boolean;
    /**
     * Tax rate
     * @type {number}
     * @memberof Tax
     */
    rate?: number;
    /**
     * Payable Account identifier
     * @type {number}
     * @memberof Tax
     */
    payableAccountId?: number;
    /**
     * Receivable Account identifier
     * @type {number}
     * @memberof Tax
     */
    receivableAccountId?: number;
    /**
     * Tax description
     * @type {string}
     * @memberof Tax
     */
    description?: string;
    /**
     * date and time when record was created
     * @type {string}
     * @memberof Tax
     */
    created?: string;
    /**
     * date and time when record was modified
     * @type {string}
     * @memberof Tax
     */
    modified?: string;
}

      /**
    * List of color themes
    */


export const Theme = createStringEnum([
  'Dark Theme',
  'Light Theme',
]);

export type Theme = keyof typeof Theme;

  /**
 * 
 * @export
 * @interface TrainingPlan
 */
export interface TrainingPlan {
    /**
     * 
     * @type {number}
     * @memberof TrainingPlan
     */
    moduleId?: number;
    /**
     * 
     * @type {string}
     * @memberof TrainingPlan
     */
    moduleName?: string;
    /**
     * 
     * @type {string}
     * @memberof TrainingPlan
     */
    moduleTitle?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof TrainingPlan
     */
    sessionIds?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TrainingPlan
     */
    temporarySessionIds?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof TrainingPlan
     */
    assessmentIds?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TrainingPlan
     */
    temporaryAssessmentIds?: Array<string>;
}

  /**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    fromAccount?: number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    toAccount?: number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    transactionDate?: string;
}

      /**
    * List of repeat types
    */


export const TriggerType = createStringEnum([
  'On demand',
  'Schedule',
  'On create',
  'On edit',
  'On create and edit',
  'On delete',
  'Enrolment successful',
  'Enrolment cancelled',
  'Class cancelled',
  'Class published on web',
  'Payslip approved',
  'Payslip paid',
  'Checklist task checked',
  'Checklist completed',
  'Tag added',
  'Tag removed',
]);

export type TriggerType = keyof typeof TriggerType;

  /**
 * 
 * @export
 * @interface Tutor
 */
export interface Tutor {
    /**
     * 
     * @type {number}
     * @memberof Tutor
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Tutor
     */
    dateFinished?: string;
    /**
     * 
     * @type {string}
     * @memberof Tutor
     */
    dateStarted?: string;
    /**
     * 
     * @type {string}
     * @memberof Tutor
     */
    familyNameLegal?: string;
    /**
     * 
     * @type {string}
     * @memberof Tutor
     */
    givenNameLegal?: string;
    /**
     * 
     * @type {string}
     * @memberof Tutor
     */
    payrollRef?: string;
    /**
     * 
     * @type {string}
     * @memberof Tutor
     */
    resume?: string;
    /**
     * 
     * @type {string}
     * @memberof Tutor
     */
    wwChildrenCheckedOn?: string;
    /**
     * 
     * @type {string}
     * @memberof Tutor
     */
    wwChildrenExpiry?: string;
    /**
     * 
     * @type {string}
     * @memberof Tutor
     */
    wwChildrenRef?: string;
    /**
     * 
     * @type {WorkingWithChildrenStatus}
     * @memberof Tutor
     */
    wwChildrenStatus?: WorkingWithChildrenStatus;
    /**
     * 
     * @type {number}
     * @memberof Tutor
     */
    currentClassesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Tutor
     */
    futureClasseCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Tutor
     */
    selfPacedclassesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Tutor
     */
    unscheduledClasseCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Tutor
     */
    passedClasseCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Tutor
     */
    cancelledClassesCount?: number;
    /**
     * 
     * @type {PayslipPayType}
     * @memberof Tutor
     */
    defaultPayType?: PayslipPayType;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Tutor
     */
    customFields?: { [key: string]: string; };
}

  /**
 * 
 * @export
 * @interface TutorAttendance
 */
export interface TutorAttendance {
    /**
     * 
     * @type {number}
     * @memberof TutorAttendance
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof TutorAttendance
     */
    courseClassTutorId?: number;
    /**
     * Not saved course class tutor's id
     * @type {string}
     * @memberof TutorAttendance
     */
    temporaryTutorId?: string;
    /**
     * 
     * @type {string}
     * @memberof TutorAttendance
     */
    contactName?: string;
    /**
     * 
     * @type {TutorAttendanceType}
     * @memberof TutorAttendance
     */
    attendanceType?: TutorAttendanceType;
    /**
     * 
     * @type {string}
     * @memberof TutorAttendance
     */
    note?: string;
    /**
     * actual payable duration in minutes
     * @type {number}
     * @memberof TutorAttendance
     */
    actualPayableDurationMinutes?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TutorAttendance
     */
    hasPayslip?: boolean;
    /**
     * tutor roster start date/time in UTC ISO-8601 format
     * @type {string}
     * @memberof TutorAttendance
     */
    start?: string;
    /**
     * tutor roster end date/time in UTC ISO-8601 format
     * @type {string}
     * @memberof TutorAttendance
     */
    end?: string;
    /**
     * Tutor's contact id, need for clash validation only (validate new class sessions tutor criteria)
     * @type {number}
     * @memberof TutorAttendance
     */
    contactId?: number;
    /**
     * Related payslip ids
     * @type {Array<number>}
     * @memberof TutorAttendance
     */
    payslipIds?: Array<number>;
}

  

export const TutorAttendanceType = createStringEnum([
  'Confirmed for payroll',
  'Rejected for payroll',
  'Not confirmed for payroll',
]);

export type TutorAttendanceType = keyof typeof TutorAttendanceType;

  /**
 * 
 * @export
 * @interface TutorRolePayRate
 */
export interface TutorRolePayRate {
    /**
     * 
     * @type {number}
     * @memberof TutorRolePayRate
     */
    id?: number;
    /**
     * 
     * @type {ClassCostRepetitionType}
     * @memberof TutorRolePayRate
     */
    type?: ClassCostRepetitionType;
    /**
     * 
     * @type {string}
     * @memberof TutorRolePayRate
     */
    validFrom?: string;
    /**
     * 
     * @type {number}
     * @memberof TutorRolePayRate
     */
    rate?: number;
    /**
     * 
     * @type {number}
     * @memberof TutorRolePayRate
     */
    oncostRate?: number;
    /**
     * 
     * @type {string}
     * @memberof TutorRolePayRate
     */
    notes?: string;
}

  /**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * Unique identifier for user
     * @type {number}
     * @memberof User
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    active?: boolean;
    /**
     * login name used to log in to onCourse
     * @type {string}
     * @memberof User
     */
    login?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName?: string;
    /**
     * Unique identifier for site
     * @type {number}
     * @memberof User
     */
    administrationCentre?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastLoggedIn?: string;
    /**
     * true if the user is an administrator
     * @type {boolean}
     * @memberof User
     */
    admin?: boolean;
    /**
     * Unique identifier for acl role
     * @type {number}
     * @memberof User
     */
    role?: number;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    accessEditor?: boolean;
    /**
     * true if two-factor authentication is enabled for this user
     * @type {boolean}
     * @memberof User
     */
    tfaEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    passwordLastChanged?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    passwordUpdateRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    inviteAgain?: boolean;
    /**
     * date and time when record was created
     * @type {string}
     * @memberof User
     */
    created?: string;
    /**
     * date and time when record was modified
     * @type {string}
     * @memberof User
     */
    modified?: string;
}

  /**
 * Preference object
 * @export
 * @interface UserPreference
 */
export interface UserPreference {
    /**
     * Unique preference key
     * @type {PreferenceEnum}
     * @memberof UserPreference
     */
    key?: PreferenceEnum;
    /**
     * preference value
     * @type {string}
     * @memberof UserPreference
     */
    value?: string;
}

  /**
 * 
 * @export
 * @interface UserRole
 */
export interface UserRole {
    /**
     * Unique holiday identifier
     * @type {number}
     * @memberof UserRole
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    name?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UserRole
     */
    rights?: { [key: string]: string; };
    /**
     * date and time when record was created
     * @type {string}
     * @memberof UserRole
     */
    created?: string;
    /**
     * date and time when record was modified
     * @type {string}
     * @memberof UserRole
     */
    modified?: string;
}

  /**
 * 
 * @export
 * @interface UsiRequest
 */
export interface UsiRequest {
    /**
     * First name of registered in usi service student
     * @type {string}
     * @memberof UsiRequest
     */
    firstName?: string;
    /**
     * Last name of registered in usi service student
     * @type {string}
     * @memberof UsiRequest
     */
    lastName?: string;
    /**
     * Birth date of registered in usi service student
     * @type {string}
     * @memberof UsiRequest
     */
    birthDate?: string;
    /**
     * Student usi number to verify
     * @type {string}
     * @memberof UsiRequest
     */
    usiCode?: string;
}

      /**
    * Set of USI statuses.
    */


export const UsiStatus = createStringEnum([
  'Not supplied',
  'Not verified',
  'Verified',
  'Exemption',
  'International',
]);

export type UsiStatus = keyof typeof UsiStatus;

  /**
 * 
 * @export
 * @interface UsiVerificationResult
 */
export interface UsiVerificationResult {
    /**
     * 
     * @type {string}
     * @memberof UsiVerificationResult
     */
    errorMessage?: string;
    /**
     * 
     * @type {UsiVerificationStatus}
     * @memberof UsiVerificationResult
     */
    verifyStatus?: UsiVerificationStatus;
}

      /**
    * Set of USI statuses.
    */


export const UsiVerificationStatus = createStringEnum([
  'Valid',
  'Invalid',
  'Service unavailable',
  'Disabled',
  'Invalid format',
]);

export type UsiVerificationStatus = keyof typeof UsiVerificationStatus;

  /**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * Unique record identifier
     * @type {string}
     * @memberof ValidationError
     */
    id?: string;
    /**
     * Property name
     * @type {string}
     * @memberof ValidationError
     */
    propertyName?: string;
    /**
     * Error message
     * @type {string}
     * @memberof ValidationError
     */
    errorMessage?: string;
}

  /**
 * 
 * @export
 * @interface VoucherCorporatePass
 */
export interface VoucherCorporatePass {
    /**
     * 
     * @type {number}
     * @memberof VoucherCorporatePass
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VoucherCorporatePass
     */
    contactFullName?: string;
}

  /**
 * 
 * @export
 * @interface VoucherProduct
 */
export interface VoucherProduct {
    /**
     * 
     * @type {number}
     * @memberof VoucherProduct
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VoucherProduct
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherProduct
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof VoucherProduct
     */
    feeExTax?: number;
    /**
     * 
     * @type {number}
     * @memberof VoucherProduct
     */
    liabilityAccountId?: number;
    /**
     * 
     * @type {number}
     * @memberof VoucherProduct
     */
    underpaymentAccountId?: number;
    /**
     * 
     * @type {number}
     * @memberof VoucherProduct
     */
    expiryDays?: number;
    /**
     * 
     * @type {number}
     * @memberof VoucherProduct
     */
    value?: number;
    /**
     * 
     * @type {number}
     * @memberof VoucherProduct
     */
    maxCoursesRedemption?: number;
    /**
     * 
     * @type {Array<VoucherProductCourse>}
     * @memberof VoucherProduct
     */
    courses?: Array<VoucherProductCourse>;
    /**
     * 
     * @type {string}
     * @memberof VoucherProduct
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof VoucherProduct
     */
    dataCollectionRuleId?: number;
    /**
     * 
     * @type {ProductStatus}
     * @memberof VoucherProduct
     */
    status?: ProductStatus;
    /**
     * 
     * @type {Array<VoucherCorporatePass>}
     * @memberof VoucherProduct
     */
    corporatePasses?: Array<VoucherCorporatePass>;
    /**
     * 
     * @type {number}
     * @memberof VoucherProduct
     */
    soldVouchersCount?: number;
    /**
     * 
     * @type {Array<Sale>}
     * @memberof VoucherProduct
     */
    relatedSellables?: Array<Sale>;
    /**
     * 
     * @type {string}
     * @memberof VoucherProduct
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherProduct
     */
    modifiedOn?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof VoucherProduct
     */
    tags?: Array<number>;
    /**
     * 
     * @type {Array<Document>}
     * @memberof VoucherProduct
     */
    documents?: Array<Document>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof VoucherProduct
     */
    customFields?: { [key: string]: string; };
}

  /**
 * 
 * @export
 * @interface VoucherProductCourse
 */
export interface VoucherProductCourse {
    /**
     * 
     * @type {number}
     * @memberof VoucherProductCourse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VoucherProductCourse
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherProductCourse
     */
    name?: string;
}

  /**
 * 
 * @export
 * @interface WagesToProcess
 */
export interface WagesToProcess {
    /**
     * 
     * @type {number}
     * @memberof WagesToProcess
     */
    totalWagesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof WagesToProcess
     */
    unprocessedWagesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof WagesToProcess
     */
    unconfirmedWagesCount?: number;
    /**
     * 
     * @type {string}
     * @memberof WagesToProcess
     */
    unconfirmedClassesIds?: string;
}

  /**
 * 
 * @export
 * @interface WaitingList
 */
export interface WaitingList {
    /**
     * 
     * @type {number}
     * @memberof WaitingList
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WaitingList
     */
    privateNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof WaitingList
     */
    studentNotes?: string;
    /**
     * 
     * @type {number}
     * @memberof WaitingList
     */
    studentCount?: number;
    /**
     * 
     * @type {number}
     * @memberof WaitingList
     */
    contactId?: number;
    /**
     * 
     * @type {string}
     * @memberof WaitingList
     */
    studentName?: string;
    /**
     * 
     * @type {number}
     * @memberof WaitingList
     */
    courseId?: number;
    /**
     * 
     * @type {string}
     * @memberof WaitingList
     */
    courseName?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof WaitingList
     */
    tags?: Array<number>;
    /**
     * 
     * @type {Array<Site>}
     * @memberof WaitingList
     */
    sites?: Array<Site>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof WaitingList
     */
    customFields?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof WaitingList
     */
    createdOn?: string;
    /**
     * 
     * @type {string}
     * @memberof WaitingList
     */
    modifiedOn?: string;
}

      /**
    * List of values describing working with children status.
    */


export const WorkingWithChildrenStatus = createStringEnum([
  'Not checked',
  'Application in progress',
  'Cleared',
  'Barred',
  'Interim barred',
]);

export type WorkingWithChildrenStatus = keyof typeof WorkingWithChildrenStatus;





export class AccessApi {
  constructor(private http: any) {
  }


  checkPermissions(permissionRequest: PermissionRequest): Promise<PermissionResponse> {
      return this.http.POST(`/v1/access`, permissionRequest, { headers: {  }, params: { }, responseType: '' });
  }

}


export class AccountApi {
  constructor(private http: any) {
  }


  create(account: Account): Promise<any> {
      return this.http.POST(`/v1/list/entity/account`, account, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<Account> {
      return this.http.GET(`/v1/list/entity/account/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  getDepositAccounts(): Promise<Array<Account>> {
      return this.http.GET(`/v1/list/entity/account/depositAccounts`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/account/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, account: Account): Promise<any> {
      return this.http.PUT(`/v1/list/entity/account/${id}`, account, { headers: {  }, params: { }, responseType: '' });
  }

}


export class ApplicationApi {
  constructor(private http: any) {
  }


  create(application: Application): Promise<any> {
      return this.http.POST(`/v1/list/entity/application`, application, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<Application> {
      return this.http.GET(`/v1/list/entity/application/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/application/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, application: Application): Promise<any> {
      return this.http.PUT(`/v1/list/entity/application/${id}`, application, { headers: {  }, params: { }, responseType: '' });
  }

}


export class ArticleProductApi {
  constructor(private http: any) {
  }


  create(article: ArticleProduct): Promise<any> {
      return this.http.POST(`/v1/list/entity/articleProduct`, article, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<ArticleProduct> {
      return this.http.GET(`/v1/list/entity/articleProduct/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, article: ArticleProduct): Promise<any> {
      return this.http.PUT(`/v1/list/entity/articleProduct/${id}`, article, { headers: {  }, params: { }, responseType: '' });
  }

}


export class AssessmentApi {
  constructor(private http: any) {
  }


  create(assessment: Assessment): Promise<any> {
      return this.http.POST(`/v1/list/entity/assessment`, assessment, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<Assessment> {
      return this.http.GET(`/v1/list/entity/assessment/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/assessment/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, assessment: Assessment): Promise<any> {
      return this.http.PUT(`/v1/list/entity/assessment/${id}`, assessment, { headers: {  }, params: { }, responseType: '' });
  }

}


export class AssessmentClassApi {
  constructor(private http: any) {
  }


  create(assessmentClass: AssessmentClass): Promise<any> {
      return this.http.POST(`/v1/list/entity/courseClass/assessment`, assessmentClass, { headers: {  }, params: { }, responseType: '' });
  }
  getClassAssessments(classId: number): Promise<Array<AssessmentClass>> {
      return this.http.GET(`/v1/list/entity/courseClass/assessment/${classId}`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/courseClass/assessment/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, assessmentClass: AssessmentClass): Promise<any> {
      return this.http.PUT(`/v1/list/entity/courseClass/assessment/${id}`, assessmentClass, { headers: {  }, params: { }, responseType: '' });
  }

}


export class AssessmentSubmissionApi {
  constructor(private http: any) {
  }


  get(id: number): Promise<AssessmentSubmission> {
      return this.http.GET(`/v1/list/entity/assessmentSubmission/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/assessmentSubmission/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, assessmentSubmission: AssessmentSubmission): Promise<any> {
      return this.http.PUT(`/v1/list/entity/assessmentSubmission/${id}`, assessmentSubmission, { headers: {  }, params: { }, responseType: '' });
  }

}


export class AttendanceApi {
  constructor(private http: any) {
  }


  get(courseClassId: number): Promise<Array<StudentAttendance>> {
      return this.http.GET(`/v1/list/entity/courseClass/attendance/student/${courseClassId}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(courseClassId: number, studentAttendance: Array<StudentAttendance>): Promise<any> {
      return this.http.POST(`/v1/list/entity/courseClass/attendance/student/${courseClassId}`, studentAttendance, { headers: {  }, params: { }, responseType: '' });
  }

}


export class AuditApi {
  constructor(private http: any) {
  }


  get(id: number): Promise<Audit> {
      return this.http.GET(`/v1/list/entity/audit/${id}`, { headers: {  }, params: { }, responseType: '' });
  }

}


export class AuthenticationApi {
  constructor(private http: any) {
  }


  getSsoLink(ssoType: string): Promise<string> {
      return this.http.GET(`/v1/login/sso/link/${ssoType}`, { headers: {  }, params: { }, responseType: '' });
  }
  getUser(): Promise<User> {
      return this.http.GET(`/v1/login`, { headers: {  }, params: { }, responseType: '' });
  }
  login(details: LoginRequest): Promise<LoginResponse> {
      return this.http.PUT(`/v1/login`, details, { headers: {  }, params: { }, responseType: '' });
  }
  loginSso(ssoType: string, code: string): Promise<LoginResponse> {
      return this.http.GET(`/v1/login/sso/${ssoType}`, { headers: {  }, params: { code, }, responseType: '' });
  }
  logout(): Promise<string> {
      return this.http.PUT(`/v1/logout`, {}, { headers: {  }, params: { }, responseType: '' });
  }

}


export class AvetmissExportApi {
  constructor(private http: any) {
  }


  exportAvetmiss8(requestParameters: AvetmissExportRequest): Promise<string> {
      return this.http.PUT(`/v1/export/avetmiss8`, requestParameters, { headers: {  }, params: { }, responseType: '' });
  }
  findExportOutcomes(settings: AvetmissExportSettings): Promise<string> {
      return this.http.PUT(`/v1/export/avetmiss8/outcomes`, settings, { headers: {  }, params: { }, responseType: '' });
  }
  getExport(processId: string): Promise<any> {
      return this.http.GET(`/v1/export/avetmiss8/${processId}`, { headers: {  }, params: { }, responseType: 'blob' });
  }
  getExportOutcomes(processId: string): Promise<Array<AvetmissExportOutcome>> {
      return this.http.GET(`/v1/export/avetmiss8/outcomes/${processId}`, { headers: {  }, params: { }, responseType: '' });
  }

}


export class BankingApi {
  constructor(private http: any) {
  }


  create(banking: Banking): Promise<any> {
      return this.http.POST(`/v1/list/entity/banking`, banking, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<Banking> {
      return this.http.GET(`/v1/list/entity/banking/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  getDepositPayments(accountId: number, adminCenterId: number): Promise<Array<Payment>> {
      return this.http.GET(`/v1/list/entity/banking/depositPayments/${accountId}/${adminCenterId}`, { headers: {  }, params: { }, responseType: '' });
  }
  reconcile(ids: Array<number>): Promise<any> {
      return this.http.POST(`/v1/list/entity/banking/reconcile`, ids, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/banking/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, banking: Banking): Promise<any> {
      return this.http.PUT(`/v1/list/entity/banking/${id}`, banking, { headers: {  }, params: { }, responseType: '' });
  }

}


export class CertificateApi {
  constructor(private http: any) {
  }


  create(certificate: Certificate): Promise<any> {
      return this.http.POST(`/v1/list/entity/certificate`, certificate, { headers: {  }, params: { }, responseType: '' });
  }
  createForEnrolments(createRequest: CertificateCreateForEnrolmentsRequest): Promise<Array<number>> {
      return this.http.POST(`/v1/list/entity/certificate/enrolment`, createRequest, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<Certificate> {
      return this.http.GET(`/v1/list/entity/certificate/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/certificate/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  revoke(revokeRequest: CertificateRevokeRequest): Promise<any> {
      return this.http.POST(`/v1/list/entity/certificate/revoke`, revokeRequest, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, certificate: Certificate): Promise<any> {
      return this.http.PUT(`/v1/list/entity/certificate/${id}`, certificate, { headers: {  }, params: { }, responseType: '' });
  }
  validateForPrint(validationRequest: CertificateValidationRequest): Promise<string> {
      return this.http.POST(`/v1/list/entity/certificate/validation`, validationRequest, { headers: {  }, params: { }, responseType: '' });
  }

}


export class CheckoutApi {
  constructor(private http: any) {
  }


  getCartDataIds(checkoutId: number): Promise<CartIds> {
      return this.http.GET(`/v1/checkout/cartIds/${checkoutId}`, { headers: {  }, params: { }, responseType: '' });
  }
  getContactDiscounts(contactId: number, classId: number, courseIds: string, productIds: string, classIds: string, promoIds: string, membershipIds: string, purchaseTotal: number, payerId: number): Promise<Array<CourseClassDiscount>> {
      return this.http.GET(`/v1/checkout/discount`, { headers: {  }, params: { contactId, courseIds, productIds, classId, classIds, promoIds, membershipIds, purchaseTotal, payerId, }, responseType: '' });
  }
  getSaleRelations(courseIds: string, productIds: string, contactId: number): Promise<Array<CheckoutSaleRelation>> {
      return this.http.GET(`/v1/checkout/saleRelations`, { headers: {  }, params: { courseIds, productIds, contactId, }, responseType: '' });
  }
  status(sessionId: string): Promise<SessionStatus> {
      return this.http.GET(`/v1/checkout/status/${sessionId}`, { headers: {  }, params: { }, responseType: '' });
  }
  submit(checkoutModel: CheckoutModel, xValidateOnly: boolean, xPaymentSessionId: string, xOrigin: string): Promise<CheckoutResponse> {
      return this.http.POST(`/v1/checkout`, checkoutModel, { headers: { xValidateOnly, xPaymentSessionId, xOrigin,  }, params: { }, responseType: '' });
  }

}


export class ClassCostApi {
  constructor(private http: any) {
  }


  _delete(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/courseClass/budget/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  create(classCost: ClassCost): Promise<any> {
      return this.http.POST(`/v1/list/entity/courseClass/budget`, classCost, { headers: {  }, params: { }, responseType: '' });
  }
  get(classId: number): Promise<Array<ClassCost>> {
      return this.http.GET(`/v1/list/entity/courseClass/budget/${classId}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, classCost: ClassCost): Promise<any> {
      return this.http.PUT(`/v1/list/entity/courseClass/budget/${id}`, classCost, { headers: {  }, params: { }, responseType: '' });
  }

}


export class ConcessionApi {
  constructor(private http: any) {
  }


  get(): Promise<Array<ConcessionType>> {
      return this.http.GET(`/v1/preference/concession/type`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: string): Promise<any> {
      return this.http.DELETE(`/v1/preference/concession/type/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(concessionTypes: Array<ConcessionType>): Promise<any> {
      return this.http.POST(`/v1/preference/concession/type`, concessionTypes, { headers: {  }, params: { }, responseType: '' });
  }

}


export class ContactApi {
  constructor(private http: any) {
  }


  createContact(contact: Contact): Promise<Array<number>> {
      return this.http.POST(`/v1/list/entity/contact`, contact, { headers: {  }, params: { }, responseType: '' });
  }
  get(): Promise<Array<ContactRelationType>> {
      return this.http.GET(`/v1/preference/contact/relation/type`, { headers: {  }, params: { }, responseType: '' });
  }
  getContact(id: number): Promise<Contact> {
      return this.http.GET(`/v1/list/entity/contact/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: string): Promise<any> {
      return this.http.DELETE(`/v1/preference/contact/relation/type/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  removeContact(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/contact/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(contactRelationTypes: Array<ContactRelationType>): Promise<any> {
      return this.http.POST(`/v1/preference/contact/relation/type`, contactRelationTypes, { headers: {  }, params: { }, responseType: '' });
  }
  updateContact(id: number, contact: Contact): Promise<any> {
      return this.http.PUT(`/v1/list/entity/contact/${id}`, contact, { headers: {  }, params: { }, responseType: '' });
  }
  verifyUsi(usiRequest: UsiRequest): Promise<UsiVerificationResult> {
      return this.http.POST(`/v1/list/entity/contact/usi`, usiRequest, { headers: {  }, params: { }, responseType: '' });
  }

}


export class ContactInsightApi {
  constructor(private http: any) {
  }


  getInsight(id: number): Promise<ContactInsight> {
      return this.http.GET(`/v1/list/entity/contact/insight/${id}`, { headers: {  }, params: { }, responseType: '' });
  }

}


export class ContactMergeApi {
  constructor(private http: any) {
  }


  getMergeData(contactA: number, contactB: number): Promise<MergeData> {
      return this.http.GET(`/v1/list/entity/contact/merge`, { headers: {  }, params: { contactA, contactB, }, responseType: '' });
  }
  merge(mergeRequest: MergeRequest): Promise<number> {
      return this.http.POST(`/v1/list/entity/contact/merge`, mergeRequest, { headers: {  }, params: { }, responseType: '' });
  }

}


export class ControlApi {
  constructor(private http: any) {
  }


  getStatus(processId: string): Promise<ProcessResult> {
      return this.http.GET(`/v1/control/${processId}`, { headers: {  }, params: { }, responseType: '' });
  }
  interrupt(processId: string): Promise<any> {
      return this.http.DELETE(`/v1/control/${processId}`, { headers: {  }, params: { }, responseType: '' });
  }

}


export class CorporatePassApi {
  constructor(private http: any) {
  }


  create(corporatePass: CorporatePass): Promise<any> {
      return this.http.POST(`/v1/list/entity/corporatepass`, corporatePass, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<CorporatePass> {
      return this.http.GET(`/v1/list/entity/corporatepass/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/corporatepass/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, corporatePass: CorporatePass): Promise<any> {
      return this.http.PUT(`/v1/list/entity/corporatepass/${id}`, corporatePass, { headers: {  }, params: { }, responseType: '' });
  }

}


export class CourseApi {
  constructor(private http: any) {
  }


  create(course: Course): Promise<any> {
      return this.http.POST(`/v1/list/entity/course`, course, { headers: {  }, params: { }, responseType: '' });
  }
  duplicateCourse(courseIds: Array<number>): Promise<any> {
      return this.http.POST(`/v1/list/entity/course/duplicate`, courseIds, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<Course> {
      return this.http.GET(`/v1/list/entity/course/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  getSellables(courseIds: Array<number>): Promise<Array<Sale>> {
      return this.http.POST(`/v1/list/entity/course/sellables`, courseIds, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/course/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, course: Course): Promise<any> {
      return this.http.PUT(`/v1/list/entity/course/${id}`, course, { headers: {  }, params: { }, responseType: '' });
  }

}


export class CourseClassApi {
  constructor(private http: any) {
  }


  cancelClass(cancelCourseClass: CancelCourseClass): Promise<any> {
      return this.http.POST(`/v1/list/entity/courseClass/cancel`, cancelCourseClass, { headers: {  }, params: { }, responseType: '' });
  }
  create(courseClass: CourseClass): Promise<number> {
      return this.http.POST(`/v1/list/entity/courseClass`, courseClass, { headers: {  }, params: { }, responseType: '' });
  }
  duplicateClass(courseClassDuplicate: CourseClassDuplicate): Promise<Array<number>> {
      return this.http.POST(`/v1/list/entity/courseClass/duplicate`, courseClassDuplicate, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<CourseClass> {
      return this.http.GET(`/v1/list/entity/courseClass/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  getTrainingPlan(id: number): Promise<Array<TrainingPlan>> {
      return this.http.GET(`/v1/list/entity/courseClass/trainingPlan/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/courseClass/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, courseClass: CourseClass): Promise<any> {
      return this.http.PUT(`/v1/list/entity/courseClass/${id}`, courseClass, { headers: {  }, params: { }, responseType: '' });
  }
  updateTrainingPlan(id: number, trainingPlans: Array<TrainingPlan>): Promise<any> {
      return this.http.POST(`/v1/list/entity/courseClass/trainingPlan/${id}`, trainingPlans, { headers: {  }, params: { }, responseType: '' });
  }

}


export class CourseClassTutorApi {
  constructor(private http: any) {
  }


  _delete(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/courseClass/tutor/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  create(tutor: CourseClassTutor): Promise<number> {
      return this.http.POST(`/v1/list/entity/courseClass/tutor`, tutor, { headers: {  }, params: { }, responseType: '' });
  }
  get(classId: number): Promise<Array<CourseClassTutor>> {
      return this.http.GET(`/v1/list/entity/courseClass/tutor/${classId}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, tutor: CourseClassTutor): Promise<any> {
      return this.http.PUT(`/v1/list/entity/courseClass/tutor/${id}`, tutor, { headers: {  }, params: { }, responseType: '' });
  }

}


export class CustomFieldApi {
  constructor(private http: any) {
  }


  get(): Promise<Array<CustomFieldType>> {
      return this.http.GET(`/v1/preference/field/type`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: string): Promise<any> {
      return this.http.DELETE(`/v1/preference/field/type/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(customFieldType: Array<CustomFieldType>): Promise<any> {
      return this.http.POST(`/v1/preference/field/type`, customFieldType, { headers: {  }, params: { }, responseType: '' });
  }

}


export class DashboardApi {
  constructor(private http: any) {
  }


  getSearchResults(search: string): Promise<Array<SearchGroup>> {
      return this.http.GET(`/v1/dashboard/search`, { headers: {  }, params: { search, }, responseType: '' });
  }
  getStatistic(): Promise<StatisticData> {
      return this.http.GET(`/v1/dashboard/statistic`, { headers: {  }, params: { }, responseType: '' });
  }

}


export class DataCollectionApi {
  constructor(private http: any) {
  }


  createForm(form: DataCollectionForm): Promise<any> {
      return this.http.POST(`/v1/datacollection/form`, form, { headers: {  }, params: { }, responseType: '' });
  }
  createRule(form: DataCollectionRule): Promise<any> {
      return this.http.POST(`/v1/datacollection/rule`, form, { headers: {  }, params: { }, responseType: '' });
  }
  getFieldTypes(formType: string): Promise<Array<FieldType>> {
      return this.http.GET(`/v1/datacollection/field/type`, { headers: {  }, params: { formType, }, responseType: '' });
  }
  getForms(): Promise<Array<DataCollectionForm>> {
      return this.http.GET(`/v1/datacollection/form`, { headers: {  }, params: { }, responseType: '' });
  }
  getRules(): Promise<Array<DataCollectionRule>> {
      return this.http.GET(`/v1/datacollection/rule`, { headers: {  }, params: { }, responseType: '' });
  }
  removeForm(id: string): Promise<any> {
      return this.http.DELETE(`/v1/datacollection/form/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  removeRule(id: string): Promise<any> {
      return this.http.DELETE(`/v1/datacollection/rule/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  updateForm(id: string, form: DataCollectionForm): Promise<any> {
      return this.http.PUT(`/v1/datacollection/form/${id}`, form, { headers: {  }, params: { }, responseType: '' });
  }
  updateRule(id: string, rule: DataCollectionRule): Promise<any> {
      return this.http.PUT(`/v1/datacollection/rule/${id}`, rule, { headers: {  }, params: { }, responseType: '' });
  }

}


export class DiscountApi {
  constructor(private http: any) {
  }


  create(discount: Discount): Promise<any> {
      return this.http.POST(`/v1/list/entity/discount`, discount, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<Discount> {
      return this.http.GET(`/v1/list/entity/discount/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/discount/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, discount: Discount): Promise<any> {
      return this.http.PUT(`/v1/list/entity/discount/${id}`, discount, { headers: {  }, params: { }, responseType: '' });
  }

}


export class DocumentApi {
  constructor(private http: any) {
  }


  create(name: string, description: string, shared: boolean, access: string, fileName: string, content: any, tags: string): Promise<Document> {
      return this.http.POST(`/v1/list/entity/document`, content, { headers: {  }, params: { name, description, shared, access, tags, fileName, }, responseType: '' });
  }
  get(id: number): Promise<Document> {
      return this.http.GET(`/v1/list/entity/document/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/document/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  search(content: string): Promise<Document> {
      return this.http.POST(`/v1/list/entity/document/search`, content, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, document: Document): Promise<any> {
      return this.http.PUT(`/v1/list/entity/document/${id}`, document, { headers: {  }, params: { }, responseType: '' });
  }

}


export class EmailTemplateApi {
  constructor(private http: any) {
  }


  create(emailTemplate: EmailTemplate): Promise<any> {
      return this.http.POST(`/v1/list/entity/emailTemplate`, emailTemplate, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<EmailTemplate> {
      return this.http.GET(`/v1/list/entity/emailTemplate/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  getConfigs(id: number): Promise<string> {
      return this.http.GET(`/v1/list/entity/emailTemplate/config/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  getTemplatesWithKeyCode(entityName: string): Promise<Array<EmailTemplate>> {
      return this.http.GET(`/v1/list/entity/emailTemplate/template`, { headers: {  }, params: { entityName, }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/emailTemplate/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, emailTemplate: EmailTemplate): Promise<any> {
      return this.http.PUT(`/v1/list/entity/emailTemplate/${id}`, emailTemplate, { headers: {  }, params: { }, responseType: '' });
  }
  updateConfigs(id: number, emailTemplateConfigs: AutomationConfigs): Promise<any> {
      return this.http.PUT(`/v1/list/entity/emailTemplate/config/${id}`, emailTemplateConfigs, { headers: {  }, params: { }, responseType: '' });
  }
  updateInternal(emailTemplate: EmailTemplate): Promise<any> {
      return this.http.PATCH(`/v1/list/entity/emailTemplate`, emailTemplate, { headers: {  }, params: { }, responseType: '' });
  }

}


export class EnrolmentApi {
  constructor(private http: any) {
  }


  cancel(cancelCourseClass: CancelEnrolment): Promise<any> {
      return this.http.POST(`/v1/list/entity/enrolment/cancel`, cancelCourseClass, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<Enrolment> {
      return this.http.GET(`/v1/list/entity/enrolment/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, enrolment: Enrolment): Promise<any> {
      return this.http.PUT(`/v1/list/entity/enrolment/${id}`, enrolment, { headers: {  }, params: { }, responseType: '' });
  }

}


export class EntityApi {
  constructor(private http: any) {
  }


  bulkChange(entity: string, diff: Diff): Promise<any> {
      return this.http.PATCH(`/v1/list/plain/bulkChange`, diff, { headers: {  }, params: { entity, }, responseType: '' });
  }
  bulkDelete(entity: string, diff: Diff): Promise<any> {
      return this.http.POST(`/v1/list/plain/bulkDelete`, diff, { headers: {  }, params: { entity, }, responseType: '' });
  }
  get(entity: string, search: string, pageSize: number, offset: number): Promise<DataResponse> {
      return this.http.GET(`/v1/list`, { headers: {  }, params: { entity, search, pageSize, offset, }, responseType: '' });
  }
  getAll(entity: string, search: SearchQuery): Promise<DataResponse> {
      return this.http.POST(`/v1/list`, search, { headers: {  }, params: { entity, }, responseType: '' });
  }
  getPlain(entity: string, search: string, pageSize: number, offset: number, columns: string, sortings: string, ascending: boolean): Promise<DataResponse> {
      return this.http.GET(`/v1/list/plain`, { headers: {  }, params: { entity, search, pageSize, offset, columns, sortings, ascending, }, responseType: '' });
  }
  updateTableModel(tableModelIdentifier: string, tableModel: TableModel): Promise<any> {
      return this.http.PUT(`/v1/list/column`, tableModel, { headers: {  }, params: { tableModelIdentifier, }, responseType: '' });
  }

}


export class EntityRelationTypeApi {
  constructor(private http: any) {
  }


  get(): Promise<Array<EntityRelationType>> {
      return this.http.GET(`/v1/preference/entity/relation/type`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: string): Promise<any> {
      return this.http.DELETE(`/v1/preference/entity/relation/type/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(relationTypes: Array<EntityRelationType>): Promise<any> {
      return this.http.POST(`/v1/preference/entity/relation/type`, relationTypes, { headers: {  }, params: { }, responseType: '' });
  }

}


export class ExportApi {
  constructor(private http: any) {
  }


  execute(entityName: string, exportRequest: ExportRequest): Promise<string> {
      return this.http.POST(`/v1/list/export`, exportRequest, { headers: {  }, params: { entityName, }, responseType: '' });
  }
  get(entityName: string, processId: string): Promise<string> {
      return this.http.GET(`/v1/list/export/${processId}`, { headers: {  }, params: { entityName, }, responseType: '' });
  }

}


export class ExportTemplateApi {
  constructor(private http: any) {
  }


  create(exportTemplate: ExportTemplate): Promise<any> {
      return this.http.POST(`/v1/list/entity/exportTemplate`, exportTemplate, { headers: {  }, params: { }, responseType: '' });
  }
  deletePreview(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/exportTemplate/preview/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  exportOnDisk(id: number): Promise<string> {
      return this.http.POST(`/v1/list/entity/exportTemplate/export/${id}`, {}, { headers: {  }, params: { }, responseType: 'text/plain' });
  }
  get(id: number): Promise<ExportTemplate> {
      return this.http.GET(`/v1/list/entity/exportTemplate/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  getConfigs(id: number): Promise<string> {
      return this.http.GET(`/v1/list/entity/exportTemplate/config/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  getPreview(id: number, compressed: boolean): Promise<string> {
      return this.http.GET(`/v1/list/entity/exportTemplate/preview/${id}`, { headers: {  }, params: { compressed, }, responseType: 'blob' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/exportTemplate/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  templates(entityName: string): Promise<Array<ExportTemplate>> {
      return this.http.GET(`/v1/list/export/template`, { headers: {  }, params: { entityName, }, responseType: '' });
  }
  update(id: number, exportTemplate: ExportTemplate): Promise<any> {
      return this.http.PUT(`/v1/list/entity/exportTemplate/${id}`, exportTemplate, { headers: {  }, params: { }, responseType: '' });
  }
  updateConfigs(id: number, scriptConfigs: AutomationConfigs): Promise<any> {
      return this.http.PUT(`/v1/list/entity/exportTemplate/config/${id}`, scriptConfigs, { headers: {  }, params: { }, responseType: '' });
  }
  updateInternal(exportTemplate: ExportTemplate): Promise<any> {
      return this.http.PATCH(`/v1/list/entity/exportTemplate`, exportTemplate, { headers: {  }, params: { }, responseType: '' });
  }

}


export class FacultyApi {
  constructor(private http: any) {
  }


  create(faculty: Faculty): Promise<number> {
      return this.http.POST(`/v1/list/entity/faculty`, faculty, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<Faculty> {
      return this.http.GET(`/v1/list/entity/faculty/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/faculty/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, course: Faculty): Promise<any> {
      return this.http.PUT(`/v1/list/entity/faculty/${id}`, course, { headers: {  }, params: { }, responseType: '' });
  }

}


export class FilterApi {
  constructor(private http: any) {
  }


  _delete(entity: string, id: number): Promise<any> {
      return this.http.DELETE(`/v1/filter/${id}`, { headers: {  }, params: { entity, }, responseType: '' });
  }
  get(entity: string): Promise<Array<Filter>> {
      return this.http.GET(`/v1/filter`, { headers: {  }, params: { entity, }, responseType: '' });
  }
  save(entity: string, filter: Filter): Promise<any> {
      return this.http.POST(`/v1/filter`, filter, { headers: {  }, params: { entity, }, responseType: '' });
  }

}


export class FinalisePeriodApi {
  constructor(private http: any) {
  }


  getInfo(lockDate: string): Promise<FinalisePeriodInfo> {
      return this.http.GET(`/v1/summaryextracts/finaliseperiod`, { headers: {  }, params: { lockDate, }, responseType: '' });
  }
  updateLockDate(lockDate: string): Promise<any> {
      return this.http.POST(`/v1/summaryextracts/finaliseperiod/${lockDate}`, {}, { headers: {  }, params: { }, responseType: '' });
  }

}


export class FundingContractApi {
  constructor(private http: any) {
  }


  get(): Promise<Array<FundingSource>> {
      return this.http.GET(`/v1/preference/fundingcontract`, { headers: {  }, params: { }, responseType: '' });
  }
  patch(fundingContracts: Array<FundingSource>): Promise<any> {
      return this.http.PATCH(`/v1/preference/fundingcontract`, fundingContracts, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/preference/fundingcontract/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(fundingContracts: Array<FundingSource>): Promise<any> {
      return this.http.POST(`/v1/preference/fundingcontract`, fundingContracts, { headers: {  }, params: { }, responseType: '' });
  }

}


export class FundingUploadApi {
  constructor(private http: any) {
  }


  get(search: string): Promise<Array<FundingUpload>> {
      return this.http.GET(`/v1/export/avetmiss8/uploads`, { headers: {  }, params: { search, }, responseType: '' });
  }
  update(upload: FundingUpload): Promise<any> {
      return this.http.PUT(`/v1/export/avetmiss8/uploads`, upload, { headers: {  }, params: { }, responseType: '' });
  }

}


export class GradingApi {
  constructor(private http: any) {
  }


  get(): Promise<Array<GradingType>> {
      return this.http.GET(`/v1/preference/grading`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/preference/grading/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(gradingTypes: Array<GradingType>): Promise<any> {
      return this.http.POST(`/v1/preference/grading`, gradingTypes, { headers: {  }, params: { }, responseType: '' });
  }

}


export class HolidayApi {
  constructor(private http: any) {
  }


  get(): Promise<Array<Holiday>> {
      return this.http.GET(`/v1/holiday`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: string): Promise<any> {
      return this.http.DELETE(`/v1/holiday/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(holiday: Array<Holiday>): Promise<any> {
      return this.http.POST(`/v1/holiday`, holiday, { headers: {  }, params: { }, responseType: '' });
  }

}


export class ImportApi {
  constructor(private http: any) {
  }


  create(importDTO: ImportModel): Promise<any> {
      return this.http.POST(`/v1/list/entity/import`, importDTO, { headers: {  }, params: { }, responseType: '' });
  }
  execute(request: string, files: Array<any>): Promise<string> {
      return this.http.POST(`/v1/list/entity/import/execution`, {}, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<ImportModel> {
      return this.http.GET(`/v1/list/entity/import/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  getConfigs(id: number): Promise<string> {
      return this.http.GET(`/v1/list/entity/import/config/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/import/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, importDTO: ImportModel): Promise<any> {
      return this.http.PUT(`/v1/list/entity/import/${id}`, importDTO, { headers: {  }, params: { }, responseType: '' });
  }
  updateConfigs(id: number, importConfigs: AutomationConfigs): Promise<any> {
      return this.http.PUT(`/v1/list/entity/import/config/${id}`, importConfigs, { headers: {  }, params: { }, responseType: '' });
  }
  updateInternal(importDTO: ImportModel): Promise<any> {
      return this.http.PATCH(`/v1/list/entity/import`, importDTO, { headers: {  }, params: { }, responseType: '' });
  }

}


export class IntegrationApi {
  constructor(private http: any) {
  }


  create(integration: Integration): Promise<any> {
      return this.http.POST(`/v1/integration`, integration, { headers: {  }, params: { }, responseType: '' });
  }
  get(): Promise<Array<Integration>> {
      return this.http.GET(`/v1/integration`, { headers: {  }, params: { }, responseType: '' });
  }
  getSsoPluginTypes(): Promise<Array<number>> {
      return this.http.GET(`/v1/integration/ssoTypes`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: string): Promise<any> {
      return this.http.DELETE(`/v1/integration/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: string, integration: Integration): Promise<any> {
      return this.http.PUT(`/v1/integration/${id}`, integration, { headers: {  }, params: { }, responseType: '' });
  }

}


export class InvoiceApi {
  constructor(private http: any) {
  }


  contraInvoice(id: number, invoicesToPay: Array<number>): Promise<any> {
      return this.http.POST(`/v1/list/entity/invoice/contra/${id}`, invoicesToPay, { headers: {  }, params: { }, responseType: '' });
  }
  create(invoice: Invoice): Promise<any> {
      return this.http.POST(`/v1/list/entity/invoice`, invoice, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<Invoice> {
      return this.http.GET(`/v1/list/entity/invoice/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/invoice/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  search(search: string): Promise<Array<Invoice>> {
      return this.http.GET(`/v1/list/entity/invoice/search`, { headers: {  }, params: { search, }, responseType: '' });
  }
  update(id: number, invoice: Invoice): Promise<any> {
      return this.http.PUT(`/v1/list/entity/invoice/${id}`, invoice, { headers: {  }, params: { }, responseType: '' });
  }

}


export class LeadApi {
  constructor(private http: any) {
  }


  create(lead: Lead): Promise<any> {
      return this.http.POST(`/v1/list/entity/lead`, lead, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<Lead> {
      return this.http.GET(`/v1/list/entity/lead/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/lead/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, lead: Lead): Promise<any> {
      return this.http.PUT(`/v1/list/entity/lead/${id}`, lead, { headers: {  }, params: { }, responseType: '' });
  }

}


export class LogsApi {
  constructor(private http: any) {
  }


  getLogs(datesInterval: DatesInterval): Promise<LogFile> {
      return this.http.POST(`/v1/logs`, datesInterval, { headers: {  }, params: { }, responseType: '' });
  }

}


export class MembershipProductApi {
  constructor(private http: any) {
  }


  create(membershipProduct: MembershipProduct): Promise<any> {
      return this.http.POST(`/v1/list/entity/membershipProduct`, membershipProduct, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<MembershipProduct> {
      return this.http.GET(`/v1/list/entity/membershipProduct/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, membershipProduct: MembershipProduct): Promise<any> {
      return this.http.PUT(`/v1/list/entity/membershipProduct/${id}`, membershipProduct, { headers: {  }, params: { }, responseType: '' });
  }

}


export class MessageApi {
  constructor(private http: any) {
  }


  get(id: number): Promise<Message> {
      return this.http.GET(`/v1/list/entity/message/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  getRecipients(entity: string, messageType: string, search: SearchQuery, templateId: number): Promise<Recipients> {
      return this.http.POST(`/v1/list/option/message/recipients`, search, { headers: {  }, params: { entity, messageType, templateId, }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/message/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  sendMessage(recipientsCount: number, request: SendMessageRequest, messageType: string): Promise<string> {
      return this.http.POST(`/v1/list/option/message`, request, { headers: {  }, params: { messageType, recipientsCount, }, responseType: '' });
  }

}


export class ModuleApi {
  constructor(private http: any) {
  }


  create(module: Module): Promise<any> {
      return this.http.POST(`/v1/list/entity/module`, module, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<any> {
      return this.http.GET(`/v1/list/entity/module/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/module/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, module: Module): Promise<any> {
      return this.http.PUT(`/v1/list/entity/module/${id}`, module, { headers: {  }, params: { }, responseType: '' });
  }

}


export class NoteApi {
  constructor(private http: any) {
  }


  create(entityName: string, entityId: number, note: Note): Promise<any> {
      return this.http.POST(`/v1/list/entity/note`, note, { headers: {  }, params: { entityName, entityId, }, responseType: '' });
  }
  get(entityName: string, entityId: number): Promise<Array<Note>> {
      return this.http.GET(`/v1/list/entity/note`, { headers: {  }, params: { entityName, entityId, }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/note/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, note: Note): Promise<any> {
      return this.http.PUT(`/v1/list/entity/note/${id}`, note, { headers: {  }, params: { }, responseType: '' });
  }

}


export class OutcomeApi {
  constructor(private http: any) {
  }


  create(outcome: Outcome): Promise<any> {
      return this.http.POST(`/v1/list/entity/outcome`, outcome, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<Outcome> {
      return this.http.GET(`/v1/list/entity/outcome/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/outcome/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, outcome: Outcome): Promise<any> {
      return this.http.PUT(`/v1/list/entity/outcome/${id}`, outcome, { headers: {  }, params: { }, responseType: '' });
  }

}


export class PaymentApi {
  constructor(private http: any) {
  }


  get(): Promise<Array<PaymentMethod>> {
      return this.http.GET(`/v1/preference/payment/type`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: string): Promise<any> {
      return this.http.DELETE(`/v1/preference/payment/type/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(paymentTypes: Array<PaymentMethod>): Promise<any> {
      return this.http.POST(`/v1/preference/payment/type`, paymentTypes, { headers: {  }, params: { }, responseType: '' });
  }

}


export class PaymentInApi {
  constructor(private http: any) {
  }


  get(id: number): Promise<PaymentIn> {
      return this.http.GET(`/v1/list/entity/paymentIn/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  reverse(id: number): Promise<any> {
      return this.http.POST(`/v1/list/entity/paymentIn/reverse/${id}`, {}, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, bankingParam: BankingParam): Promise<any> {
      return this.http.PUT(`/v1/list/entity/paymentIn/${id}`, bankingParam, { headers: {  }, params: { }, responseType: '' });
  }

}


export class PaymentOutApi {
  constructor(private http: any) {
  }


  create(payment: PaymentOut): Promise<any> {
      return this.http.POST(`/v1/list/entity/paymentOut`, payment, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<PaymentOut> {
      return this.http.GET(`/v1/list/entity/paymentOut/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(payment: PaymentOut): Promise<any> {
      return this.http.PUT(`/v1/list/entity/paymentOut`, payment, { headers: {  }, params: { }, responseType: '' });
  }

}


export class PayrollApi {
  constructor(private http: any) {
  }


  execute(entity: string, bulkConfirmTutorWages: boolean, payrollRequest: PayrollRequest): Promise<string> {
      return this.http.POST(`/v1/list/option/payroll`, payrollRequest, { headers: {  }, params: { entity, bulkConfirmTutorWages, }, responseType: '' });
  }
  prepare(entity: string, payrollRequest: PayrollRequest): Promise<WagesToProcess> {
      return this.http.PUT(`/v1/list/option/payroll`, payrollRequest, { headers: {  }, params: { entity, }, responseType: '' });
  }

}


export class PayslipApi {
  constructor(private http: any) {
  }


  create(payslip: Payslip): Promise<any> {
      return this.http.POST(`/v1/list/entity/payslip`, payslip, { headers: {  }, params: { }, responseType: '' });
  }
  execute(request: PayslipRequest): Promise<any> {
      return this.http.POST(`/v1/list/entity/payslip/marking`, request, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<any> {
      return this.http.GET(`/v1/list/entity/payslip/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/payslip/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, payslip: Payslip): Promise<any> {
      return this.http.PUT(`/v1/list/entity/payslip/${id}`, payslip, { headers: {  }, params: { }, responseType: '' });
  }

}


export class PdfApi {
  constructor(private http: any) {
  }


  execute(entityName: string, printRequest: PrintRequest): Promise<string> {
      return this.http.POST(`/v1/list/export/pdf`, printRequest, { headers: {  }, params: { entityName, }, responseType: '' });
  }
  get(entityName: string, processId: string): Promise<string> {
      return this.http.GET(`/v1/list/export/pdf/${processId}`, { headers: {  }, params: { entityName, }, responseType: 'blob' });
  }

}


export class PdfTemplateApi {
  constructor(private http: any) {
  }


  create(report: Report): Promise<any> {
      return this.http.POST(`/v1/list/entity/report`, report, { headers: {  }, params: { }, responseType: '' });
  }
  deletePreview(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/report/preview/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  get(entityName: string): Promise<Array<Report>> {
      return this.http.GET(`/v1/list/export/pdf/template`, { headers: {  }, params: { entityName, }, responseType: '' });
  }
  getById(id: number): Promise<Report> {
      return this.http.GET(`/v1/list/entity/report/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  getConfigs(id: number): Promise<string> {
      return this.http.GET(`/v1/list/entity/report/config/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  getPreview(id: number, compressed: boolean): Promise<string> {
      return this.http.GET(`/v1/list/entity/report/preview/${id}`, { headers: {  }, params: { compressed, }, responseType: 'blob' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/report/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, report: Report): Promise<any> {
      return this.http.PUT(`/v1/list/entity/report/${id}`, report, { headers: {  }, params: { }, responseType: '' });
  }
  updateConfigs(id: number, scriptConfigs: AutomationConfigs): Promise<any> {
      return this.http.PUT(`/v1/list/entity/report/config/${id}`, scriptConfigs, { headers: {  }, params: { }, responseType: '' });
  }
  updateInternal(report: Report): Promise<any> {
      return this.http.PATCH(`/v1/list/entity/report`, report, { headers: {  }, params: { }, responseType: '' });
  }

}


export class PreferenceApi {
  constructor(private http: any) {
  }


  checkConnection(host: string, port: string, isSsl: string, baseDn: string, user: string): Promise<boolean> {
      return this.http.GET(`/v1/preference/ldap`, { headers: {  }, params: { host, port, isSsl, baseDn, user, }, responseType: '' });
  }
  get(search: string): Promise<Array<SystemPreference>> {
      return this.http.GET(`/v1/preference`, { headers: {  }, params: { search, }, responseType: '' });
  }
  getColumnSettings(): Promise<ColumnWidth> {
      return this.http.GET(`/v1/preference/column`, { headers: {  }, params: { }, responseType: '' });
  }
  getCountries(): Promise<Array<Country>> {
      return this.http.GET(`/v1/preference/country`, { headers: {  }, params: { }, responseType: '' });
  }
  getCurrency(): Promise<Currency> {
      return this.http.GET(`/v1/preference/currency`, { headers: {  }, params: { }, responseType: '' });
  }
  getEnum(name: string): Promise<Array<EnumItem>> {
      return this.http.GET(`/v1/preference/enum/${name}`, { headers: {  }, params: { }, responseType: '' });
  }
  getLanguages(): Promise<Array<Language>> {
      return this.http.GET(`/v1/preference/language`, { headers: {  }, params: { }, responseType: '' });
  }
  getLockedDate(): Promise<LockedDate> {
      return this.http.GET(`/v1/preference/lockedDate`, { headers: {  }, params: { }, responseType: '' });
  }
  getTimezones(): Promise<Array<string>> {
      return this.http.GET(`/v1/preference/timezone`, { headers: {  }, params: { }, responseType: '' });
  }
  messageQueued(type: string): Promise<number> {
      return this.http.GET(`/v1/preference/messagequeued`, { headers: {  }, params: { type, }, responseType: '' });
  }
  update(preferences: Array<SystemPreference>): Promise<any> {
      return this.http.POST(`/v1/preference`, preferences, { headers: {  }, params: { }, responseType: '' });
  }
  updateColumnSettings(settings: ColumnWidth): Promise<any> {
      return this.http.POST(`/v1/preference/column`, settings, { headers: {  }, params: { }, responseType: '' });
  }

}


export class PriorLearningApi {
  constructor(private http: any) {
  }


  create(priorLearning: PriorLearning): Promise<any> {
      return this.http.POST(`/v1/list/entity/priorLearning`, priorLearning, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<PriorLearning> {
      return this.http.GET(`/v1/list/entity/priorLearning/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/priorLearning/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, priorLearning: PriorLearning): Promise<any> {
      return this.http.PUT(`/v1/list/entity/priorLearning/${id}`, priorLearning, { headers: {  }, params: { }, responseType: '' });
  }

}


export class ProductItemApi {
  constructor(private http: any) {
  }


  cancel(productItemCancel: ProductItemCancel): Promise<any> {
      return this.http.POST(`/v1/list/entity/sales`, productItemCancel, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<ProductItem> {
      return this.http.GET(`/v1/list/entity/sales/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, productItem: ProductItem): Promise<any> {
      return this.http.PUT(`/v1/list/entity/sales/${id}`, productItem, { headers: {  }, params: { }, responseType: '' });
  }

}


export class QualificationApi {
  constructor(private http: any) {
  }


  create(qualification: Qualification): Promise<any> {
      return this.http.POST(`/v1/list/entity/qualification`, qualification, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<any> {
      return this.http.GET(`/v1/list/entity/qualification/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/qualification/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, qualification: Qualification): Promise<any> {
      return this.http.PUT(`/v1/list/entity/qualification/${id}`, qualification, { headers: {  }, params: { }, responseType: '' });
  }

}


export class ReportOverlayApi {
  constructor(private http: any) {
  }


  create(fileName: string, overlay: any): Promise<any> {
      return this.http.POST(`/v1/list/entity/reportOverlay`, overlay, { headers: {  }, params: { fileName, }, responseType: '' });
  }
  get(id: number): Promise<ReportOverlay> {
      return this.http.GET(`/v1/list/entity/reportOverlay/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  getOriginal(id: number): Promise<Array<string>> {
      return this.http.GET(`/v1/list/entity/reportOverlay/original/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/reportOverlay/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(fileName: string, id: number, overlay: any): Promise<any> {
      return this.http.PUT(`/v1/list/entity/reportOverlay/${id}`, overlay, { headers: {  }, params: { fileName, }, responseType: '' });
  }

}


export class RoomApi {
  constructor(private http: any) {
  }


  create(room: Room): Promise<any> {
      return this.http.POST(`/v1/list/entity/room`, room, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<Room> {
      return this.http.GET(`/v1/list/entity/room/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/room/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, room: Room): Promise<any> {
      return this.http.PUT(`/v1/list/entity/room/${id}`, room, { headers: {  }, params: { }, responseType: '' });
  }

}


export class RoomValidationApi {
  constructor(private http: any) {
  }


  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/room/validation/${id}`, { headers: {  }, params: { }, responseType: '' });
  }

}


export class ScriptApi {
  constructor(private http: any) {
  }


  _delete(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/script/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  create(script: Script): Promise<any> {
      return this.http.POST(`/v1/list/entity/script`, script, { headers: {  }, params: { }, responseType: '' });
  }
  execute(request: ExecuteScriptRequest): Promise<string> {
      return this.http.POST(`/v1/list/entity/script/execute`, request, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<Script> {
      return this.http.GET(`/v1/list/entity/script/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  getConfigs(id: number): Promise<string> {
      return this.http.GET(`/v1/list/entity/script/config/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  getPdf(processId: string): Promise<string> {
      return this.http.GET(`/v1/list/entity/script/execute/pdf/${processId}`, { headers: {  }, params: { }, responseType: 'blob' });
  }
  getResult(processId: string): Promise<string> {
      return this.http.GET(`/v1/list/entity/script/execute/${processId}`, { headers: {  }, params: { }, responseType: '' });
  }
  patch(id: number, script: Script): Promise<any> {
      return this.http.PATCH(`/v1/list/entity/script/${id}`, script, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, script: Script): Promise<any> {
      return this.http.PUT(`/v1/list/entity/script/${id}`, script, { headers: {  }, params: { }, responseType: '' });
  }
  updateConfigs(id: number, scriptConfigs: AutomationConfigs): Promise<any> {
      return this.http.PUT(`/v1/list/entity/script/config/${id}`, scriptConfigs, { headers: {  }, params: { }, responseType: '' });
  }

}


export class SessionApi {
  constructor(private http: any) {
  }


  get(classId: number): Promise<Array<Session>> {
      return this.http.GET(`/v1/list/entity/courseClass/timetable/${classId}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(classId: number, sessions: Array<Session>): Promise<Array<SessionWarning>> {
      return this.http.POST(`/v1/list/entity/courseClass/timetable/${classId}`, sessions, { headers: {  }, params: { }, responseType: '' });
  }

}


export class SiteApi {
  constructor(private http: any) {
  }


  create(site: Site): Promise<any> {
      return this.http.POST(`/v1/list/entity/site`, site, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<Site> {
      return this.http.GET(`/v1/list/entity/site/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/site/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, site: Site): Promise<any> {
      return this.http.PUT(`/v1/list/entity/site/${id}`, site, { headers: {  }, params: { }, responseType: '' });
  }

}


export class SurveyApi {
  constructor(private http: any) {
  }


  get(id: number): Promise<SurveyItem> {
      return this.http.GET(`/v1/list/entity/survey/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, surveyItem: SurveyItem): Promise<any> {
      return this.http.PUT(`/v1/list/entity/survey/${id}`, surveyItem, { headers: {  }, params: { }, responseType: '' });
  }

}


export class TagApi {
  constructor(private http: any) {
  }


  create(tag: Tag): Promise<any> {
      return this.http.POST(`/v1/tag`, tag, { headers: {  }, params: { }, responseType: '' });
  }
  get(entityName: string): Promise<Array<Tag>> {
      return this.http.GET(`/v1/tag/list`, { headers: {  }, params: { entityName, }, responseType: '' });
  }
  getChecklists(entityName: string, id: number): Promise<Array<Tag>> {
      return this.http.GET(`/v1/tag/checklist`, { headers: {  }, params: { entityName, id, }, responseType: '' });
  }
  getSpecialTags(entityName: string): Promise<SpecialTag> {
      return this.http.GET(`/v1/tag/special`, { headers: {  }, params: { entityName, }, responseType: '' });
  }
  getTag(id: number): Promise<Tag> {
      return this.http.GET(`/v1/tag/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/tag/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, tag: Tag): Promise<any> {
      return this.http.PUT(`/v1/tag/${id}`, tag, { headers: {  }, params: { }, responseType: '' });
  }
  updateSpecial(specialTag: SpecialTag): Promise<any> {
      return this.http.POST(`/v1/tag/special`, specialTag, { headers: {  }, params: { }, responseType: '' });
  }

}


export class TaxApi {
  constructor(private http: any) {
  }


  get(): Promise<Array<Tax>> {
      return this.http.GET(`/v1/preference/tax`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: string): Promise<any> {
      return this.http.DELETE(`/v1/preference/tax/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(taxes: Array<Tax>): Promise<any> {
      return this.http.POST(`/v1/preference/tax`, taxes, { headers: {  }, params: { }, responseType: '' });
  }

}


export class TimetableApi {
  constructor(private http: any) {
  }


  find(request: SearchRequest): Promise<Array<Session>> {
      return this.http.POST(`/v1/timetable/session`, request, { headers: {  }, params: { }, responseType: '' });
  }
  get(ids: string): Promise<Array<Session>> {
      return this.http.GET(`/v1/timetable/session`, { headers: {  }, params: { ids, }, responseType: '' });
  }
  getDates(month: number, year: number, request: SearchRequest): Promise<Array<number>> {
      return this.http.POST(`/v1/timetable/calendar/${month}/${year}`, request, { headers: {  }, params: { }, responseType: '' });
  }
  getForClasses(ids: string): Promise<Array<Session>> {
      return this.http.GET(`/v1/timetable/courseClass`, { headers: {  }, params: { ids, }, responseType: '' });
  }
  getSessionsTags(sessionIds: string): Promise<Array<{ [key: string]: string; }>> {
      return this.http.GET(`/v1/timetable/session/tag`, { headers: {  }, params: { sessionIds, }, responseType: '' });
  }

}


export class TokenApi {
  constructor(private http: any) {
  }


  _delete(tokenId: number): Promise<any> {
      return this.http.DELETE(`/v1/token/${tokenId}`, { headers: {  }, params: { }, responseType: '' });
  }
  create(tokens: Array<ApiToken>): Promise<any> {
      return this.http.POST(`/v1/token`, tokens, { headers: {  }, params: { }, responseType: '' });
  }
  get(): Promise<Array<ApiToken>> {
      return this.http.GET(`/v1/token`, { headers: {  }, params: { }, responseType: '' });
  }

}


export class TransactionApi {
  constructor(private http: any) {
  }


  create(transaction: Transaction): Promise<any> {
      return this.http.POST(`/v1/list/entity/accountTransaction`, transaction, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<Transaction> {
      return this.http.GET(`/v1/list/entity/accountTransaction/${id}`, { headers: {  }, params: { }, responseType: '' });
  }

}


export class TutorRoleApi {
  constructor(private http: any) {
  }


  create(tutorRole: DefinedTutorRole): Promise<any> {
      return this.http.POST(`/v1/list/entity/definedTutorRole`, tutorRole, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<DefinedTutorRole> {
      return this.http.GET(`/v1/list/entity/definedTutorRole/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/list/entity/definedTutorRole/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, tutorRole: DefinedTutorRole): Promise<any> {
      return this.http.PUT(`/v1/list/entity/definedTutorRole/${id}`, tutorRole, { headers: {  }, params: { }, responseType: '' });
  }

}


export class UserApi {
  constructor(private http: any) {
  }


  checkPassword(password: string): Promise<PasswordComplexity> {
      return this.http.PUT(`/v1/user/checkPassword/${password}`, {}, { headers: {  }, params: { }, responseType: '' });
  }
  createPassword(invitationToken: string, password: string): Promise<any> {
      return this.http.POST(`/v1/invite/${invitationToken}`, {}, { headers: {  }, params: { password, }, responseType: '' });
  }
  disableTFA(id: number): Promise<any> {
      return this.http.PUT(`/v1/user/disableTFA/${id}`, {}, { headers: {  }, params: { }, responseType: '' });
  }
  get(): Promise<Array<User>> {
      return this.http.GET(`/v1/user`, { headers: {  }, params: { }, responseType: '' });
  }
  getActive(): Promise<Array<User>> {
      return this.http.GET(`/v1/user/active`, { headers: {  }, params: { }, responseType: '' });
  }
  getUserEmailByInvitation(invitationToken: string): Promise<string> {
      return this.http.GET(`/v1/invite/${invitationToken}`, { headers: {  }, params: { }, responseType: '' });
  }
  requireComplexPass(): Promise<boolean> {
      return this.http.GET(`/v1/user/requireComplexPass`, { headers: {  }, params: { }, responseType: '' });
  }
  resetPassword(id: number): Promise<any> {
      return this.http.PUT(`/v1/user/resetPassword/${id}`, {}, { headers: {  }, params: { }, responseType: '' });
  }
  update(user: User): Promise<any> {
      return this.http.POST(`/v1/user`, user, { headers: {  }, params: { }, responseType: '' });
  }
  updatePassword(password: string): Promise<any> {
      return this.http.PUT(`/v1/user/updatePassword/${password}`, {}, { headers: {  }, params: { }, responseType: '' });
  }

}


export class UserPreferenceApi {
  constructor(private http: any) {
  }


  get(keys: string): Promise<{ [key: string]: string; }> {
      return this.http.GET(`/v1/user/preference`, { headers: {  }, params: { keys, }, responseType: '' });
  }
  set(preference: UserPreference): Promise<any> {
      return this.http.PUT(`/v1/user/preference`, preference, { headers: {  }, params: { }, responseType: '' });
  }

}


export class UserRoleApi {
  constructor(private http: any) {
  }


  get(): Promise<Array<UserRole>> {
      return this.http.GET(`/v1/role`, { headers: {  }, params: { }, responseType: '' });
  }
  remove(id: number): Promise<any> {
      return this.http.DELETE(`/v1/role/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(userRole: UserRole): Promise<any> {
      return this.http.POST(`/v1/role`, userRole, { headers: {  }, params: { }, responseType: '' });
  }

}


export class VoucherProductApi {
  constructor(private http: any) {
  }


  create(voucherProduct: VoucherProduct): Promise<any> {
      return this.http.POST(`/v1/list/entity/voucherProduct`, voucherProduct, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<VoucherProduct> {
      return this.http.GET(`/v1/list/entity/voucherProduct/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, voucherProduct: VoucherProduct): Promise<any> {
      return this.http.PUT(`/v1/list/entity/voucherProduct/${id}`, voucherProduct, { headers: {  }, params: { }, responseType: '' });
  }

}


export class WaitingListApi {
  constructor(private http: any) {
  }


  create(waitingList: WaitingList): Promise<any> {
      return this.http.POST(`/v1/list/entity/waitingList`, waitingList, { headers: {  }, params: { }, responseType: '' });
  }
  get(id: number): Promise<WaitingList> {
      return this.http.GET(`/v1/list/entity/waitingList/${id}`, { headers: {  }, params: { }, responseType: '' });
  }
  update(id: number, waitingList: WaitingList): Promise<any> {
      return this.http.PUT(`/v1/list/entity/waitingList/${id}`, waitingList, { headers: {  }, params: { }, responseType: '' });
  }

}


